/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
import { put, call } from "redux-saga/effects";
import axios from "axios";
import {
  baseURL,
  GET_REJECT_RULES_FETCH,
  GET_REJECT_VLC_DATA_FAILURE,
  GET_REJECT_VLC_DATA_SUCCESS,
} from "../../constants";

export function* fetchRejectRules(params) {
  try {
    const fetchData = yield call(
      axios.get,
      `${baseURL}/${GET_REJECT_RULES_FETCH}/${params.studyId}`,
      {}
    );
    const tableDataFormatted = fetchData?.data?.data?.tableDataFormatted || [];

    let totalDatasetCount = 0;
    tableDataFormatted?.forEach((dataflow) =>
      dataflow?.dataset?.forEach(
        (item) => item.datasetid && totalDatasetCount++
      )
    );

    yield put({
      type: GET_REJECT_VLC_DATA_SUCCESS,
      tableData: fetchData?.data?.data?.tableData || [],
      tableDataFormatted,
      totalDatasetCount,
    });
  } catch (e) {
    yield put({ type: GET_REJECT_VLC_DATA_FAILURE, message: e.message });
  }
}
