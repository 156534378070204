/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "lodash";
import { baseURL, DATAKINDAPI } from "../../../constants";
import {
  isSftp,
  isAmazonS3 as _isAmazonS3,
  getUserId,
  verifyBDCSupportedExtensions,
  getExtension,
  dateTypeForJDBC,
  parseBool,
  stringToBoolean,
} from "../../../utils";
import { saveBDCDraftDataPackage } from "../../../services/ApiServices";
import { decrypt, encrypt } from "../../../utils/encryptionHelper";
import { MessageContext } from "../../../components/Providers/MessageProvider";
import { checkAlphaNumericFileName } from "../../../components/FormComponents/validators";
import {
  getDataFlowDetail,
  setDraftEditMode,
} from "../../../store/actions/DataFlowAction";
import {
  addDataPackage,
  getPackagesList,
  selectDataPackage,
} from "../../../store/actions/DataPackageAction";
import dataPackageService from "../../../services/dataPackageService";
import { initialState } from "./initialState";
import {
  fetchLocation,
  fetchPreviewSQL,
  fetchSQLTables,
  fetchTableColumns,
} from "./api";
import { BASIC_DATA_CONNECTOR_DA } from "../DataPackagesHelper";

export const useSimpleFileAdaptor = () => {
  const { showSuccessMessage, showErrorMessage } = useContext(MessageContext);
  const [showForm, setShowForm] = useState(false);

  const [bdcPackageDetails, setBdcPackageDetails] = useState(null);
  const [state, _setState] = useState(initialState);
  const {
    // local state
    location,
    // form fields
    fileType,
    delimiter,
    mnemonicPrefix,
    clinicalDataType,
    containsHeaderRow,
    fileNamingConvention,
    filePassword,
    sftpPath,
    subFolderName,
    processing,
    createCopy,
    active,
    datasetMnemonic,
    customsqlYesNo,
    customsql,
    table,
    filter,
    offsetColumn,
    offsetColumnDatatype,
  } = state;
  const loc = useLocation();

  const setState = (value) => {
    _setState((s) => ({ ...s, ...value }));
  };

  const packageData = useSelector((s) => s.dataPackage);
  const { selectedPackage } = packageData;
  const {
    dataFlowdetail,
    isDraftEditMode,
    isPublishedFlow,
    versionFreezed,
    testLock,
    prodLock,
  } = useSelector((s) => s.dataFlow);

  const dispatch = useDispatch();
  const history = useHistory();
  const isSFTP = isSftp(dataFlowdetail?.loctyp);
  const isAmazonS3 = _isAmazonS3(dataFlowdetail?.loctyp);
  const isReplace = state?.processing === "Replace";
  const isAppend = state?.processing === "Append";
  const isCustomSql = !isSFTP && state?.customsqlYesNo === "Yes";
  const isTable = !isSFTP && state?.customsqlYesNo === "No";
  const isSynced = testLock || prodLock;
  const isSyncedOrPublished = isSynced || dataFlowdetail?.publishedVersion;
  // const isSyncedOrPublished = isPublishedFlow || isSynced;

  const isPackage2 = (fileName) =>
    isSFTP && ["zip", "7z", "xpt", "rar"].includes(getExtension(fileName));
  const isPackage = isPackage2(fileNamingConvention);
  const isFile = !isPackage;

  const getTables = async (locConfig = location, noError = false) => {
    if (!locConfig || isSFTP) return null;
    try {
      setState({
        isTablesFetching: true,
        tables: null,
      });
      const response = await fetchSQLTables(locConfig);
      setState({
        isTablesFetching: false,
        tables: response.data.data.map((x) => ({
          text: x.tableName,
          value: x.tableName,
        })),
      });
      return response;
    } catch (error) {
      setState({
        isTablesFetching: false,
        tables: null,
      });
      if (!noError) {
        showErrorMessage(
          error?.response?.data?.message ||
            "Table list cannot be loaded due to location connection failure"
        );
      }
    }
    return null;
  };

  const [isTestingConnection, setIsTestingConnection] = useState(false);

  const getLocation = async () => {
    setIsTestingConnection(true);
    try {
      setState({ location: null });
      const response = await fetchLocation(dataFlowdetail.srclocid);
      const checkConnection = await getTables(response.data.data, true);
      setState({ location: response.data.data });
      if (!checkConnection) {
        showErrorMessage(
          "Location connection failure. Please verify the location"
        );
      }
    } catch (error) {
      console.log(">>> Error fetching location", error);
      setState({ location: null });
    }
    setIsTestingConnection(false);
  };

  const getTableColumns = async () => {
    try {
      setState({
        isColumnsFetching: true,
        columns: null,
        offsetColumns: null,
      });
      const response = await fetchTableColumns({
        ...location,
        tableName: table,
      });
      if (response) {
        const allCols = response.data.data.map((e) => {
          e.dataType = dateTypeForJDBC(e.datatype);
          e.primaryKey = parseBool(e.primaryKey || "false");
          e.required = parseBool(e.required || "false");
          e.unique = parseBool(e.unique || "false");
          return e;
        });
        const offsetColumns = allCols
          .filter((x) =>
            ["numeric", "date"].includes(x.dataType?.toLowerCase())
          )
          .map((e) => ({
            text: e.columnName,
            value: e.columnName,
          }));

        setState({
          columns: allCols,
          offsetColumns,
          isColumnsFetching: false,
        });
      }
    } catch (error) {
      setState({
        isColumnsFetching: false,
        columns: null,
        offsetColumns: null,
      });
    }
  };

  useEffect(() => {
    if (dataFlowdetail.srclocid && !isSFTP && !state.location) {
      getLocation();
    }
  }, [dataFlowdetail.srclocid, state.location, isSFTP]);

  useEffect(() => {
    if (table && !isSFTP && customsqlYesNo === "No" && location)
      getTableColumns();
  }, [isSFTP, table, customsqlYesNo, location]);

  useEffect(() => {
    if (isTable && !state.tables) getTables();
  }, [isSFTP, isTable, location, state.tables]);

  const getPreviewSql = async () => {
    if (!location || isSFTP) return [];

    if (!location) {
      showErrorMessage("Location config is wrong. Please verify the location");
      return false;
    }

    if (!state.customsql) {
      showErrorMessage("Custom SQL cannot be empty");
      return false;
    }
    if (state.customsql?.includes("*")) {
      showErrorMessage("Custom SQL Query cannot contain *");
      return false;
    }

    setState({
      csqlError: null,
      csqlFetched: false,
      csqlResult: null,
      isCsqlFetching: true,
    });
    try {
      const response = await fetchPreviewSQL({
        ...location,
        customQuery: "Yes",
        columnCount: null,
        columnDefinition: null,
        tableName: null,
        customSql: state.customsql,
      });
      setState({
        csqlResult: response?.data?.data?.rows,
        csqlFetched: true,
        csqlError: null,
        isCsqlFetching: false,
      });
      return true;
    } catch (error) {
      setState({
        csqlError: error,
        csqlFetched: false,
        csqlResult: null,
        isCsqlFetching: false,
      });
      showErrorMessage(
        "Unabel to validate the Custom SQL. Try changing the SQL"
      );
      return false;
    }
  };

  const fetchClinicalDataType = async (param) => {
    try {
      const response = await axios.get(
        `${baseURL}/${DATAKINDAPI}/list?${param}`
      );
      setState({
        clinicalDataTypes: response?.data?.data?.records?.map((x) => ({
          text: x.label,
          value: x.value,
        })),
      });
    } catch (error) {
      console.log(">>> Error fetching Clinical Data Types", error);
    }
  };

  const checkRequired = (field, errors) => {
    if (!state[field]) {
      errors[field] = `Required field.`;
      return false;
    }
    return true;
  };

  const verifyPackageNameToFileType = (type, fileName, field, errors) => {
    let result;
    switch (type) {
      case "Excel":
        result = ["xls", "xlsx", "zip", "rar", "7z"].includes(
          getExtension(fileName)
        );
        if (!result) {
          errors[
            field
          ] = `Incorrect File Format provided for the selected Ingested File Type.`;
        }
        break;
      case "Delimited":
        result = ["csv", "txt", "zip", "rar", "7z"].includes(
          getExtension(fileName)
        );
        if (!result) {
          errors[
            field
          ] = `Incorrect File Format provided for the selected Ingested File Type.`;
        }
        break;
      case "SAS":
        result = ["sas7bdat", "xpt", "zip", "7z"].includes(
          getExtension(fileName)
        );
        if (!result) {
          errors[
            field
          ] = `Incorrect File Format provided for the selected Ingested File Type.`;
        }
        break;
      default:
        result = true;
    }

    return result;
  };

  const validate = () => {
    const errors = { ...initialState.errors };
    if (isSFTP) {
      const reqSFTP = [
        "fileType",
        "mnemonicPrefix",
        "clinicalDataType",
        "containsHeaderRow",
        "fileNamingConvention",
        ...(!isAmazonS3 ? ["sftpPath"] : []),
        "processing",
      ];
      reqSFTP.forEach((x) => checkRequired(x, errors));
      if (fileType === "Delimited") checkRequired("delimiter", errors);
      if (fileNamingConvention) {
        if (checkAlphaNumericFileName(fileNamingConvention, null, isAmazonS3))
          errors.fileNamingConvention =
            "File Naming Convention is not in a proper format";

        if (!verifyBDCSupportedExtensions(fileNamingConvention))
          errors.fileNamingConvention = "File name extension is not supported";

        verifyPackageNameToFileType(
          fileType,
          fileNamingConvention,
          "fileNamingConvention",
          errors
        );
      }
      if (bdcPackageDetails) {
        if (
          isPackage2(bdcPackageDetails?.package_extn_attr?.name) &&
          !isPackage2(state.fileNamingConvention)
        ) {
          // package to file
          errors.fileNamingConvention =
            "File name extension from Package to File is not allowed.";
        } else if (
          isPackage2(state.fileNamingConvention) &&
          !isPackage2(bdcPackageDetails?.package_extn_attr?.name)
        ) {
          // file to package
          errors.fileNamingConvention =
            "File name extension from File to Package is not allowed.";
        }
      }
    } else {
      const reqJDBC = [
        "mnemonicPrefix",
        "datasetMnemonic",
        "customsqlYesNo",
        "clinicalDataType",
        ...(isTable ? ["table"] : ["customsql"]),
        ...(isAppend ? ["offsetColumn"] : []),
        "processing",
      ];
      reqJDBC.forEach((x) => checkRequired(x, errors));
      if (isCustomSql && customsql?.includes("*"))
        errors.customsql = "Custom SQL Query cannot contain *";

      // if (isCustomSql && !state.csqlFetched)
      //   errors.customsql = `Hit "Preview SQL to verify the Custom SQL`;
    }

    setState({ errors });
    // console.log(
    //   ">>> va",
    //   errors,
    //   Object.values(errors).every((x) => x === null)
    // );
    return Object.values(errors).every((x) => x === null);
  };

  const onSaveSuccess = async (datapackageid) => {
    if (datapackageid) {
      const singlePackageData =
        await dataPackageService.getDataPackageDetailsById(
          dataFlowdetail.dataflowid,
          datapackageid
        );
      if (singlePackageData?.data?.[0]) {
        dispatch(getPackagesList(dataFlowdetail.dataflowid, "", true, true));
        if (dataFlowdetail.dataflowid) {
          dispatch(getDataFlowDetail(dataFlowdetail.dataflowid, false));
        }
        history.push("/dashboard/data-packages");
      }
      dispatch(selectDataPackage(singlePackageData?.data?.[0]));
    }

    dispatch(setDraftEditMode(false));
  };

  const onSave = async () => {
    setState({ isSavedClickedOnce: true });
    if (!validate()) return;
    const body = {
      dataflowid: dataFlowdetail.dataflowid,
      mnemonicPrefix,
      datakindid: clinicalDataType,
      incremental: processing === "Replace" ? "N" : "Y",
      createCopy,
      active: active ? 1 : 0,
      userId: getUserId(),
      ...(isSFTP
        ? {
            headerrow: containsHeaderRow,
            delimiter,
            fileType,
            fileName: fileNamingConvention,
            filePwd: encrypt(filePassword),
            sftpPath,
            subFolderName,
            datasetMnemonic: null,
            customsql_yn: null,
            customsql: null,
            tbl_nm: null,
            filter: null,
            offsetColumn: null,
            offsetColumnDatatype: null,
            columns: null,
          }
        : {
            headerrow: null,
            delimiter: null,
            fileType: null,
            fileName: null,
            filePwd: null,
            sftpPath: null,
            subFolderName: null,
            datasetMnemonic,
            customsql_yn: customsqlYesNo,
            customsql,
            tbl_nm: table,
            filter,
            ...(isAppend
              ? {
                  offsetColumn,
                  offsetColumnDatatype: isTable
                    ? state?.columns?.find((x) => x.columnName === offsetColumn)
                        ?.dataType || ""
                    : offsetColumnDatatype,
                }
              : {
                  offsetColumn: null,
                  offsetColumnDatatype: null,
                }),
            columns: isTable ? state?.columns?.map((x) => x.columnName) : [],
          }),
      versionFreezed,
      datapackageid:
        !isEmpty(selectedPackage) && selectedPackage?.datapackageid
          ? selectedPackage.datapackageid
          : null,
    };
    setState({ isLoading: true });

    try {
      const response = await saveBDCDraftDataPackage(body);

      if (response.error) {
        console.log(">>> error saving", response.error, body);
        showErrorMessage(response.error);
      } else {
        // move data package id = response.data.datapackageid
        onSaveSuccess(response.data.datapackageid);
        showSuccessMessage(`Saved Successfully`);
        // setState({ ...initialState }); // temporary clear the values
      }
    } catch (error) {
      showErrorMessage(error);
    }
    setState({ isLoading: false });
  };

  const resetState = () => setState({ ...initialState });

  const resetForm = () =>
    setState({
      ...state.clinicalDataTypes,
      ...initialState,
    });

  useEffect(() => {
    const doFetch = async () => {
      setState({ isLoading: true });
      await fetchClinicalDataType();
      setState({ isLoading: false });
    };

    if (!state.clinicalDataTypes) doFetch();
  }, [state.clinicalDataTypes]);

  useEffect(() => {
    if (dataFlowdetail?.type === BASIC_DATA_CONNECTOR_DA) {
      setShowForm(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // need to review
  useEffect(() => {
    if (history.location?.state?.from === "create-dataflow") {
      dispatch(setDraftEditMode(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!isPublishedFlow || !isDraftEditMode) {
  //     setEditMode(false);
  //   } else {
  //     setEditMode(true);
  //   }
  // }, [isPublishedFlow, isDraftEditMode]);

  // need to improve
  useEffect(() => {
    if (
      (!packageData.openAddPackage && !isEmpty(selectedPackage)) ||
      loc?.state?.SelectedDataSetProperties
    ) {
      const filteredPackage = packageData?.packagesList?.filter(
        (pkg) =>
          pkg.datapackageid === selectedPackage?.datapackageid ||
          loc?.state?.SelectedDataSetProperties?.datapackageid
      );
      if (filteredPackage && filteredPackage.length > 0) {
        setBdcPackageDetails(filteredPackage[0]);
      }
      setShowForm(true);
    } else {
      resetForm();
    }
  }, [
    selectedPackage,
    packageData.openAddPackage,
    packageData.packagesList,
    loc?.state?.SelectedDataSetProperties,
  ]);

  useEffect(() => {
    if (packageData.openAddPackage) {
      setShowForm(true);
      dispatch(setDraftEditMode(true));
      setBdcPackageDetails(null);
      resetForm();
    }
    return () => {
      resetForm();
    };
  }, [packageData.openAddPackage]);

  useEffect(() => {
    if (bdcPackageDetails) {
      const {
        type: _fileType,
        delimiter: _delimiter,
        mnemonicPrefix: _mnemonicPrefix,
        datakindid: _clinicalDataType,
        headerrow: _headerrow,
        name: _fileNamingConvention,
        filePwd: _filePwd,
        path: _sftpPath,
        subFolderName: _folderName,
        incremental: _incremental,
        createCopy: _createCopy,
        datasetMnemonic: _datasetMnemonic,
        customsql: _customsql,
        customsql_yn: _customsqlYesNo,
        tableName: _tableName,
        filter: _filter,
        active: _active,
        offsetColumn: _offsetColumn,
        offsetColumnDatatype: _offsetColumnDatatype,
      } = bdcPackageDetails?.package_extn_attr;

      setState({
        fileType: _fileType,
        delimiter: _delimiter,
        mnemonicPrefix: _mnemonicPrefix,
        clinicalDataType: _clinicalDataType,
        containsHeaderRow: stringToBoolean(_headerrow) ? "Y" : "N",
        fileNamingConvention: _fileNamingConvention,
        filePassword: decrypt(_filePwd),
        sftpPath: _sftpPath,
        subFolderName: _folderName || "",
        processing: stringToBoolean(_incremental) ? "Append" : "Replace",
        createCopy: _createCopy,
        datasetMnemonic: _datasetMnemonic,
        customsql: _customsql,
        customsqlYesNo: _customsqlYesNo,
        table: _tableName,
        filter: _filter,
        active: _active,
        offsetColumn: _offsetColumn,
        offsetColumnDatatype: _offsetColumnDatatype,
      });
    }
  }, [bdcPackageDetails]);

  const handleAddedSuccess = (
    message,
    isRedirect = true,
    closeModal = false
  ) => {
    if (message) showSuccessMessage(message);
    // const dataflowID =
    //   dfId ||
    //   packageData.selectedPackage?.dataflowid ||
    //   dataFlowdetail?.dataflowid;
    if (isRedirect && isEmpty(selectedPackage)) {
      dispatch(addDataPackage(false));
      resetForm();
      // setAddedPackage(false);
      // resetForm();
      setShowForm(false);
      history.push(
        `/dashboard/dataflow-management/${dataFlowdetail.dataflowid}`
      );
    }
    // if (!isRedirect && closeModal) {
    //   setAddedPackage(false);
    // }
  };

  const onEdit = () => {
    dispatch(setDraftEditMode(true));
  };

  const onCancel = () => {
    // setState({ ...initialState });
    if (isEmpty(selectedPackage)) {
      resetForm();
      dispatch(setDraftEditMode(false));
      handleAddedSuccess();
    } else if (!isEmpty(selectedPackage)) {
      // resetForm();
      // setBdcPackageDetails(null);
      setBdcPackageDetails({ ...selectedPackage });
      setState({ errors: initialState.errors });
      dispatch(setDraftEditMode(false));
      // setShowForm(false);
    } else {
      resetForm();
      dispatch(setDraftEditMode(false));
      handleAddedSuccess();
    }
  };

  return {
    state,
    validate: () => {
      // console.log(">>> val1", state.isSavedClickedOnce);
      if (state.isSavedClickedOnce) validate();
    },
    getPreviewSql,
    onSave,
    setState,
    resetState,
    fetchClinicalDataType,
    isPackage,
    isFile,
    isDraftEditMode,
    passwordFetchLoading: packageData?.pageLoaders?.passwordFetchLoading,
    isSFTP,
    isAmazonS3,
    isReplace,
    isAppend,
    isCustomSql,
    isTable,
    dataFlowdetail,
    onCancel,
    editMode: !isPublishedFlow && isDraftEditMode,
    showForm,
    isProcessing:
      state.isTablesFetching ||
      state.isCsqlFetching ||
      state.isColumnsFetching ||
      state.isLoading ||
      isTestingConnection,
    selectedPackage,
    isPublished: isPublishedFlow,
    isSynced,
    isSyncedOrPublished,
    onEdit,
    setShowForm,
  };
};

export default useSimpleFileAdaptor;
