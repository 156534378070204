/* eslint-disable consistent-return */
import { produce } from "immer";
import {
  GET_REJECT_VLC_DATA,
  GET_REJECT_VLC_DATA_FAILURE,
  GET_REJECT_VLC_DATA_SUCCESS,
  RESET_MANAGE_VLC_STORE,
  SET_IS_RULES_CONFIRMED,
  SET_MANAGE_VLC_CURRENT_STEP,
  SET_REFRESH_CONTENT,
  SET_SELECTED_DATASET_IDS,
  SET_SELECTED_RULE_IDS,
  UPDATE_REJECT_VLC_MODAL_STATUS,
} from "../../constants";

export const initialState = {
  currentStep: 1,
  rejectRuleData: [],
  selectRulesTableData: [],
  confirmRulesData: [],
  totalDatasetCount: 0,
  selectedDatasetId: [],
  selectedRulesId: [],
  targetRoute: null,
  loading: false,
  btnLoading: false,
  pageLoading: false,
  isRuleConfirmed: false,
  error: "",
  redirectToTab: false,
  refreshContent: false,
  modals: {
    cancelModal: false,
  },
};

const ManageVLCReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case SET_MANAGE_VLC_CURRENT_STEP:
        newState.currentStep = action.step;
        break;

      case GET_REJECT_VLC_DATA:
        newState.pageLoading = true;
        newState.error = "";
        break;

      case GET_REJECT_VLC_DATA_SUCCESS:
        newState.pageLoading = false;
        newState.rejectRuleData = action.tableData;
        newState.selectRulesTableData = action.tableDataFormatted;
        newState.totalDatasetCount = action.totalDatasetCount;
        break;

      case GET_REJECT_VLC_DATA_FAILURE:
        newState.pageLoading = false;
        newState.error = action.message;
        break;

      case UPDATE_REJECT_VLC_MODAL_STATUS:
        if (action.modal) {
          newState.modals = {
            ...state?.modals,
            [action.modal]: action?.state || false,
          };
          newState.redirectToTab = action.redirectToTab || false;
          newState.targetRoute = action.targetRoute || null;
        }
        break;

      case SET_SELECTED_DATASET_IDS:
        newState.selectedDatasetId = action.datasetIds;
        break;

      case SET_SELECTED_RULE_IDS:
        newState.confirmRulesData = action.ruleData;
        break;

      case SET_IS_RULES_CONFIRMED:
        newState.isRuleConfirmed = action.isRuleConfirmed;
        break;

      case SET_REFRESH_CONTENT:
        newState.refreshContent = action.refresh;
        break;

      case RESET_MANAGE_VLC_STORE:
        return initialState;

      default:
        break;
    }
  });

export default ManageVLCReducer;
