/* eslint-disable import/prefer-default-export */
import { put, call } from "redux-saga/effects";
import axios from "axios";
import {
  baseURL,
  GETSTUDYDF,
  STUDYAPI,
  CDIHOMEAPI,
  GET_STUDY_PERMISSION,
  GET_DATA_FLOW_LIST_SUCCESS,
  GET_DATA_FLOW_LIST_FAILURE,
  GET_DATASET_INGESTION_SUMMARY_SUCCESS,
  GET_DATASET_INGESTION_SUMMARY_FAILURE,
  GET_ALL_INGESTION_SUMMARY_SUCCESS,
  GET_ALL_INGESTION_SUMMARY_FAILURE,
  STUDYLIST,
  GET_STUDIES_LIST_SUCCESS,
  GET_STUDIES_LIST_FAILURE,
  PINNEDSTUDY,
  GET_PINNED_LIST_SUCCESS,
  GET_PINNED_LIST_FAILURE,
  GET_SPECIFIC_INGESTION_DATASETS_SUCCESS,
  GET_SPECIFIC_INGESTION_DATASETS_FAILURE,
  GET_DATA_FLOW_LIST_INTERVAL_SUCCESS,
  GET_DATA_FLOW_LIST_INTERVAL_FAILURE,
} from "../../constants";
import {
  BASIC_DATA_CONNECTOR,
  BASIC_DATA_CONNECTOR_VALUE,
  STANDARDIZE_METADATA,
  STANDARDIZE_METADATA_VALUE,
} from "../../pages/Dashboard/MonitorTab/helper";

export function* fetchFlowData(payload) {
  try {
    const fetchDBData = yield call(axios.post, `${baseURL}/${GETSTUDYDF}`, {
      protocolId: payload.protocolId,
      protocolnumberstandard: payload.protocolnumberstandard,
      userId: payload.userId,
    });

    const permissionData = yield call(
      axios.post,
      `${baseURL}/${GET_STUDY_PERMISSION}`,
      {
        protocolnumberstandard: payload.protocolnumberstandard,
        userId: payload.userId,
      }
    );

    yield put({
      type: GET_DATA_FLOW_LIST_SUCCESS,
      flowData: fetchDBData.data.data,
      readPermission: permissionData.data.data.permission,
    });
  } catch (e) {
    yield put({ type: GET_DATA_FLOW_LIST_FAILURE, message: e.message });
  }
}

export function* fetchFlowDataInterval(payload) {
  try {
    const fetchDBData = yield call(axios.post, `${baseURL}/${GETSTUDYDF}`, {
      protocolId: payload.protocolId,
      protocolnumberstandard: payload.protocolnumberstandard,
      userId: payload.userId,
    });

    yield put({
      type: GET_DATA_FLOW_LIST_INTERVAL_SUCCESS,
      flowData: fetchDBData.data.data,
    });
  } catch (e) {
    yield put({
      type: GET_DATA_FLOW_LIST_INTERVAL_FAILURE,
      message: e.message,
    });
  }
}

export function* fetchDatasetIngestionSummaryData(payload) {
  try {
    let active = 0;
    let testFlag = "";
    if (payload.active) {
      active = 1;
    }
    if (payload.testFlag === "1" || payload.testFlag === "0") {
      testFlag = payload.testFlag;
    }
    if (payload.pipelineType === BASIC_DATA_CONNECTOR_VALUE) {
      payload.pipelineType = BASIC_DATA_CONNECTOR;
    }
    if (payload.pipelineType === STANDARDIZE_METADATA_VALUE) {
      payload.pipelineType = STANDARDIZE_METADATA;
    }
    const fetchDBData = yield call(
      axios.get,
      `${baseURL}/${STUDYAPI}/datasetIngestionDetail/${payload.protocolId}?active=${active}&testFlag=${testFlag}&processStatus=${payload.processStatus}&pipelineType=${payload.pipelineType}&userId=${payload.userId}`, // NOSONAR
      {}
    );

    yield put({
      type: GET_DATASET_INGESTION_SUMMARY_SUCCESS,
      ingestionData: fetchDBData.data.data,
    });
  } catch (e) {
    yield put({
      type: GET_DATASET_INGESTION_SUMMARY_FAILURE,
      message: e.message,
    });
  }
}

export function* fetchAllIngestionSummaryData(payload) {
  try {
    let active = 0;
    let testFlag = "";
    if (payload.active) {
      active = 1;
    }
    if (payload.testFlag === "1" || payload.testFlag === "0") {
      testFlag = payload.testFlag;
    }
    if (payload.pipelineType === BASIC_DATA_CONNECTOR_VALUE) {
      payload.pipelineType = BASIC_DATA_CONNECTOR;
    }
    if (payload.pipelineType === STANDARDIZE_METADATA_VALUE) {
      payload.pipelineType = STANDARDIZE_METADATA;
    }
    const fetchDBData = yield call(
      axios.get,
      `${baseURL}/${CDIHOMEAPI}/datasetIngestionDetail/${payload.UserID}?testFlag=${testFlag}&active=${active}&processStatus=${payload.processStatus}&limit=${payload.limit}&pipelineType=${payload.pipelineType}&noOfDays=${payload.noOfDays}`, // NOSONAR
      {}
    );

    yield put({
      type: GET_ALL_INGESTION_SUMMARY_SUCCESS,
      ingestnData: fetchDBData.data.data,
    });
  } catch (e) {
    yield put({
      type: GET_ALL_INGESTION_SUMMARY_FAILURE,
      message: e.message,
    });
  }
}

export function* fetchSpecificIngestionDataSets(payload) {
  try {
    let active = 0;
    let testFlag = "";
    if (payload.active) {
      active = 1;
    }
    if (payload.testFlag === "1" || payload.testFlag === "0") {
      testFlag = payload.testFlag;
    }
    if (payload.pipelineType === BASIC_DATA_CONNECTOR_VALUE) {
      payload.pipelineType = BASIC_DATA_CONNECTOR;
    }
    const fetchDBData = yield call(
      axios.get,
      `${baseURL}/${CDIHOMEAPI}/ingestionMonitorDataSets/${payload.UserID}?testFlag=${testFlag}&active=${active}&processStatus=${payload.processStatus}&limit=${payload.limit}&pipelineType=${payload.pipelineType}&noOfDays=${payload.noOfDays}`, // NOSONAR
      {}
    );

    yield put({
      type: GET_SPECIFIC_INGESTION_DATASETS_SUCCESS,
      ingestionData: fetchDBData.data.data,
    });
  } catch (e) {
    yield put({
      type: GET_SPECIFIC_INGESTION_DATASETS_FAILURE,
      message: e.message,
    });
  }
}

export function* fetchUserStudiesData(payload) {
  try {
    const fetchDBData = yield call(
      axios.get,
      `${baseURL}/${STUDYLIST}/${payload.userId}`
    );

    yield put({
      type: GET_STUDIES_LIST_SUCCESS,
      userStudies: fetchDBData.data.data,
    });
  } catch (e) {
    yield put({ type: GET_STUDIES_LIST_FAILURE, message: e.message });
  }
}

export function* fetchPinnedStudies(payload) {
  try {
    const fetchDBData = yield call(
      axios.get,
      `${baseURL}/${PINNEDSTUDY}/${payload.userId}`
    );

    yield put({
      type: GET_PINNED_LIST_SUCCESS,
      userPinnedStudies: fetchDBData.data.data,
    });
  } catch (e) {
    yield put({ type: GET_PINNED_LIST_FAILURE, message: e.message });
  }
}
