// libraries
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Typography from "apollo-react/components/Typography";
import { isEmpty } from "lodash";
// apollo components
import Box from "apollo-react/components/Box";
import Loader from "apollo-react/components/Loader";
import Paper from "apollo-react/components/Paper";
import StatusExclamation from "apollo-react-icons/StatusExclamation";
import Modal from "apollo-react/components/Modal";
import Button from "apollo-react/components/Button";
import StatusCheck from "apollo-react-icons/StatusCheck";
// styles
import "../DataPackages.scss";
import "./SimpleFileAdapter.scss";
import {
  CDIDropDown,
  CDIPassword,
  CDIRadioGroup,
  CDITextBox,
  CDICheckBox,
  EditSaveCancelButtonGroup,
  CDISwitch,
  CDIAutoComplete,
} from "../../../components/FormComponents/CommonComponents";
// helpers
import {
  fileTypesBasic,
  delimiters,
  offsetColumnDataTypes,
} from "../../../utils";
import {
  CONTAIN_HEADER_ROW_OPTIONS,
  PROCESSING_OPTIONS,
} from "../DataPackagesHelper";
// eslint-disable-next-line import/no-named-as-default
import useSimpleFileAdaptor from "./useSimpleFileAdaptor";
import PreviewColumns from "../../../components/Dataset/PreviewColumns";

const ModalDialog = ({ show, onClose, title, message, buttons }) => (
  <Modal
    open={show}
    variant="warning"
    onClose={onClose}
    title={
      // eslint-disable-next-line react/jsx-wrap-multilines
      <div className="statusModal">
        <StatusExclamation />
        <span className="title-text">{title}</span>
      </div>
    }
    message={message}
    buttonProps={buttons}
    id="neutral"
  />
);
const SimpleFileAdapter = () => {
  // store
  const {
    onSave,
    onCancel,
    state: {
      // local state
      clinicalDataTypes,
      tables,
      offsetColumns,
      csqlResult,
      csqlFetched,
      isColumnsFetching,
      isCsqlFetching,
      isTablesFetching,
      // form fields
      fileType,
      delimiter,
      mnemonicPrefix,
      clinicalDataType,
      containsHeaderRow,
      fileNamingConvention,
      filePassword,
      sftpPath,
      subFolderName,
      processing,
      createCopy,
      active,
      datasetMnemonic,
      customsqlYesNo,
      customsql,
      offsetColumnDatatype,
      offsetColumn,
      table,
      filter,
      isTestingConnection,
      errors,
    },
    isDraftEditMode,
    isSFTP,
    setState,
    isAmazonS3,
    isPackage,
    resetState,
    isReplace,
    selectedPackage,
    editMode,
    isSynced,
    isPublished,
    isSyncedOrPublished,
    onEdit,
    validate,
    isAppend,
    isCustomSql,
    isTable,
    getTables,
    getPreviewSql,
    isProcessing,

    showForm,
  } = useSimpleFileAdaptor();

  const [openModal, setopenModal] = useState(false);
  const [csqlConfirmDialog, setCsqlConfirmDialog] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const { compressionType, fileTypes } = useSelector(
    (state) => state.dashboard
  );

  const handleRadioBtn = (e) => {
    setState({ processing: e.target.value, createCopy: false });
    if (e.target.value === PROCESSING_OPTIONS[1].value) {
      setopenModal(true);
    }
  };

  const handlePreviewSQL = async () => {
    const response = await getPreviewSql();
    if (response) setShowPreview(true);
  };

  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clinicalDataType,
    customsqlYesNo,
    fileType,
    delimiter,
    table,
    offsetColumn,
  ]);
  console.log(">>>>> process", showForm, isProcessing);

  if (!showForm && isProcessing) return <Loader />;

  if (!showForm) return null;

  return (
    <>
      <Box
        style={{
          padding: "24px",
          backgroundColor: "#f6f7fb",
        }}
      >
        <Paper className="add-package-box">
          <div className="simple-adapter-root">
            {/* Loader */}
            {(isProcessing || isTestingConnection) && <Loader />}
            <div className="data-package-root">
              <div className="like-fixedbar">
                <Typography variant="title1" gutterBottom>
                  {`Input ${isSFTP ? "File" : "Table"} Configuration`}
                </Typography>
                <div className="ds-status">
                  <CDISwitch
                    label="Configuration&nbsp;Active"
                    name="active"
                    value={active}
                    className="MuiSwitch"
                    size="small"
                    labelPlacement="start"
                    onChange={(value) => setState({ active: value })}
                  />
                </div>
              </div>
              {isSFTP && (
                <Typography variant="subtitle1" gutterBottom color="#595959">
                  Supports Packaged or an individual file. Refer to the User
                  Manual for more details.
                </Typography>
              )}

              {/* Form */}
              <div className="input-setting-body">
                <CDITextBox
                  editMode={editMode}
                  errors={errors}
                  name="mnemonicPrefix"
                  label="Mnemonic Prefix"
                  maxLength={10}
                  value={mnemonicPrefix}
                  onChange={(e) => {
                    setState({ mnemonicPrefix: e.target.value });
                  }}
                  onBlur={validate}
                  tooltip={[
                    "Helps in storing and uniquely identifying datasets with same name from different sources.",
                    "Will be added as a prefix for final dataset mnemonic.",
                    "Must be unique for study and also for the dataflow.",
                  ]}
                  disabled={
                    !isEmpty(selectedPackage) &&
                    isDraftEditMode &&
                    isSyncedOrPublished
                  } // sample
                />

                <CDIAutoComplete
                  editMode={editMode}
                  errors={errors}
                  name="clinicalDataType"
                  label="Clinical Data Type"
                  size="small"
                  value={clinicalDataType}
                  onBlur={validate}
                  onChange={(e) => setState({ clinicalDataType: e.value })}
                  // disabled={isSyncedOrPublished || (isODM && dataflowId)}
                  disabled={
                    !isEmpty(selectedPackage) &&
                    isDraftEditMode &&
                    isSyncedOrPublished
                  }
                  options={clinicalDataTypes}
                />

                <CDIDropDown
                  editMode={editMode}
                  errors={errors}
                  name="fileType"
                  label="Ingestion File Type"
                  value={fileType}
                  onChange={(e) => setState({ fileType: e.target.value })}
                  onBlur={validate}
                  disabled={!isEmpty(selectedPackage) && isSynced} // sample
                  options={Object.values(compressionType?.bdcfiletypes).map(
                    (x) => ({ text: x, value: x })
                  )}
                  visible={isSFTP}
                  tooltip="Ingestion file formats - If File format is Package, then only the selected type of files will be ingested."
                />

                <CDIDropDown
                  visible={isSFTP && fileType === "Delimited"}
                  editMode={editMode}
                  errors={errors}
                  name="delimiter"
                  label="Delimiter"
                  value={delimiter}
                  options={Object.values(fileTypes?.delimiter).map((x) => ({
                    text: x,
                    value: x,
                  }))}
                  onBlur={validate}
                  onChange={(e) => setState({ delimiter: e.target.value })}
                  disabled={!isEmpty(selectedPackage) && isSynced} // sample
                />

                <CDITextBox
                  name="datasetMnemonic"
                  editMode={editMode}
                  errors={errors}
                  onBlur={validate}
                  label="Dataset Mnemonic"
                  maxLength={10}
                  value={datasetMnemonic}
                  onChange={(e) =>
                    setState({ datasetMnemonic: e.target.value })
                  }
                  disabled={!isEmpty(selectedPackage) && isSyncedOrPublished} // sample
                  visible={!isSFTP}
                />

                <CDIRadioGroup
                  name="customsqlYesNo"
                  // style={{ marginBottom: "24px" }}
                  editMode={editMode}
                  label="Custom SQL Query"
                  onBlur={validate}
                  errors={errors}
                  value={customsqlYesNo}
                  onChange={(e) => setState({ customsqlYesNo: e.target.value })}
                  options={[
                    { text: "Yes", value: "Yes" },
                    { text: "No", value: "No" },
                  ]}
                  // renderText={dataflowTypeLabel}
                  visible={!isSFTP}
                />
                <div className="previeSqlContainer">
                  <div className="previewSql">
                    <CDITextBox
                      className="previewSqlTextArea"
                      name="customsql"
                      label="Custom SQL Query"
                      editMode={editMode}
                      minHeight={56}
                      minWidth={540}
                      maxWidth={540}
                      sizeAdjustable
                      onBlur={validate}
                      errors={errors}
                      maxLength={65535}
                      value={customsql}
                      onChange={(e) =>
                        setState({
                          csqlFetched: customsql === e.target.value,
                          csqlResult: null,
                          customsql: e.target.value,
                        })
                      }
                      visible={isCustomSql}
                    />
                    <div>
                      {editMode && isCustomSql && (
                        <Button
                          disabled={isCsqlFetching}
                          variant="secondary"
                          className="previewSqlButton"
                          onClick={handlePreviewSQL}
                        >
                          Preview SQL
                        </Button>
                      )}
                      {/* {editMode && csqlFetched && csqlResult && (
                      <StatusCheck
                      style={{ color: "#00c221", margin: "10px" }}
                      />
                    )} */}
                    </div>
                  </div>
                </div>
                <CDIAutoComplete
                  // style={{ marginTop: "24px" }}
                  label="Table Name"
                  name="table"
                  size="small"
                  onBlur={validate}
                  isLoading={isTablesFetching}
                  editMode={editMode}
                  errors={errors}
                  value={table}
                  onChange={(e) => setState({ table: e.value })}
                  options={tables}
                  visible={isTable}
                />
                <CDITextBox
                  name="filter"
                  label="Filter Condition (optional)"
                  editMode={editMode}
                  errors={errors}
                  onBlur={validate}
                  value={filter}
                  onChange={(e) => setState({ filter: e.target.value })}
                  visible={isTable}
                />

                <CDITextBox
                  visible={isSFTP}
                  editMode={editMode}
                  errors={errors}
                  name="fileNamingConvention"
                  label="Input File Naming Convention"
                  value={fileNamingConvention}
                  maxLength={999}
                  onChange={(e) =>
                    setState({ fileNamingConvention: e.target.value })
                  }
                  onBlur={validate}
                  tooltip="Supports packaged or an individual file. 
                  The following file formats are supported: 
                  Individual File - csv, txt, xls, xlsx, sas7bdat
                  Package -  xpt, zip, 7z, rar"
                />

                <CDIRadioGroup
                  visible={isSFTP}
                  editMode={editMode}
                  errors={errors}
                  name="containsHeaderRow"
                  label="Contains Header Row"
                  value={containsHeaderRow}
                  onChange={(e) =>
                    setState({ containsHeaderRow: e.target.value })
                  }
                  onBlur={validate}
                  options={CONTAIN_HEADER_ROW_OPTIONS}
                />
                {isSFTP && (
                  <span className="footer-message-common-component">
                    The first row will be the header row
                  </span>
                )}

                <CDIPassword
                  maxLength={999}
                  editMode={editMode}
                  errors={errors}
                  name="filePassword"
                  label="File Password (optional)"
                  value={filePassword}
                  onChange={(e) => setState({ filePassword: e.target.value })}
                  visible={isSFTP}
                />

                <CDITextBox
                  visible={isSFTP && !isAmazonS3}
                  maxLength={999}
                  errors={errors}
                  editMode={editMode}
                  name="sftpPath"
                  label="sFTP Folder Path"
                  value={sftpPath}
                  onChange={(e) => setState({ sftpPath: e.target.value })}
                  onBlur={validate}
                />

                <CDITextBox
                  maxLength={999}
                  visible={!isAmazonS3 && isPackage}
                  errors={errors}
                  editMode={editMode}
                  name="subFolderName"
                  label="Package Subfolder Name (optional)"
                  value={subFolderName}
                  onChange={(e) => setState({ subFolderName: e.target.value })}
                  onBlur={validate}
                />

                <CDIRadioGroup
                  editMode={editMode}
                  name="processing"
                  label="Processing"
                  errors={errors}
                  value={processing}
                  onChange={handleRadioBtn}
                  onBlur={validate}
                  disabled={!isEmpty(selectedPackage) && isSynced}
                  options={PROCESSING_OPTIONS}
                />

                <CDITextBox
                  maxLength={999}
                  errors={errors}
                  editMode={editMode}
                  name="offsetColumn"
                  onBlur={validate}
                  label="Offset Column"
                  value={offsetColumn}
                  onChange={(e) => setState({ offsetColumn: e.target.value })}
                  visible={isAppend && isCustomSql}
                  disabled={!isEmpty(selectedPackage) && isSynced}
                  footer="Should be an integer, date or a timestamp. Should not be a string or character."
                />

                <CDIDropDown
                  errors={errors}
                  editMode={editMode}
                  name="offsetColumnDatatype"
                  onBlur={validate}
                  label="Offset Column Datatype"
                  value={offsetColumnDatatype}
                  disabled={!isEmpty(selectedPackage) && isSynced}
                  options={offsetColumnDataTypes.map((x) => ({
                    text: x,
                    value: x,
                  }))}
                  onChange={(e) =>
                    setState({ offsetColumnDatatype: e.target.value })
                  }
                  visible={isAppend && isCustomSql}
                />

                <CDIAutoComplete
                  maxLength={999}
                  errors={errors}
                  editMode={editMode}
                  size="small"
                  name="offsetColumn"
                  onBlur={validate}
                  label="Offset Column"
                  value={offsetColumn}
                  options={offsetColumns}
                  onChange={(e) => setState({ offsetColumn: e.value })}
                  visible={isAppend && isTable}
                  footer="Should be an integer, date or a timestamp. Should not be a string or character."
                  disabled={!isEmpty(selectedPackage) && isSynced}
                />

                <CDICheckBox
                  name="createCopy"
                  visible={isReplace}
                  onBlur={validate}
                  editMode={editMode}
                  errors={errors}
                  label="Create copy before replacing"
                  tooltip="Backup/copy of previous ingestion will be saved. Previous copy will be overwritten on new ingestion"
                  value={createCopy}
                  onChange={(e) => setState({ createCopy: e.target.checked })}
                  disabled={
                    !isEmpty(selectedPackage) && isSynced ? "disabled" : ""
                  }
                  // style={{ marginTop: isTable && !isAppend ? "-24px" : "" }}
                />
              </div>

              {!isPublished && (
                <EditSaveCancelButtonGroup
                  isEditMode={editMode}
                  onEdit={onEdit}
                  onSave={() => {
                    if (
                      isCustomSql &&
                      !csqlFetched &&
                      csqlConfirmDialog !== true
                    ) {
                      setCsqlConfirmDialog(true);
                    } else onSave();
                  }}
                  onCancel={onCancel}
                  isLoading={isProcessing}
                />
              )}
            </div>
            <ModalDialog
              show={csqlConfirmDialog}
              title="Preview SQL"
              message="Please hit previewSql to proceed"
              buttons={[
                { label: "Ok", onClick: () => setCsqlConfirmDialog(false) },
              ]}
              onClose={() => setCsqlConfirmDialog(false)}
            />
            <ModalDialog
              show={openModal}
              title="Confirm selection"
              message="Append option is suitable when input contains only new records. All records in the file will be appended in the current table. If selected incorrectly, the performance will be impacted and will introduce duplicate records."
              buttons={[
                {
                  label: "Replace",
                  onClick: () => {
                    setState({ processing: PROCESSING_OPTIONS[0].value });
                    setopenModal(false);
                  },
                },
                {
                  variant: "primary",
                  label: "Append",
                  onClick: () => {
                    setState({ processing: PROCESSING_OPTIONS[1].value });
                    setopenModal(false);
                  },
                },
              ]}
              onClose={() => setopenModal(false)}
            />
            {showPreview && (
              <PreviewColumns
                previewSQL={csqlResult}
                onClose={() => setShowPreview(false)}
              />
            )}
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default SimpleFileAdapter;
