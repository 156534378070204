import {
  DASHBOARD_DATA,
  PAGEHEADER_UPDATE,
  SELECTED_DATAFLOW,
  GET_DATA_FLOW_LIST,
  GET_DATASET_INGESTION_SUMMARY,
  GET_ALL_INGESTION_SUMMARY,
  GET_SPECIFIC_INGESTION_DATASETS,
  GET_STUDIES_LIST,
  GET_PINNED_LIST,
  UPDATE_HEADER_COUNT,
  SET_MONITOR_TITLE,
  SET_ALL_MONITOR_TITLE,
  SET_MONITOR_SHOW_SUMMARY,
  UPDATE_DF_STATUS,
  // UPDATE_PREVIOUS_STATE_ACTIVE_ONLY_BTN,
  UPDATE_PREVIOUS_SEGMENT_CONTROL,
  UPDATE_DASHBOARD_MODAL_STATUS,
  UPDATE_DASHBOARD_MODAL_DATAFLOW,
  UPDATE_DASHBOARD_DATA_TOGGLE,
  GET_DATA_FLOW_LIST_INTERVAL,
  UPDATE_PREVIOUS_PIPELINE_TYPE,
  TABULAR_PIPELINE,
  CLEAR_SELECTED_DATAFLOW,
  SET_EXPANDED,
} from "../../constants";

export const setExpanded = (expanded) => {
  return {
    type: SET_EXPANDED,
    expanded,
  };
};

export const getStudyboardData = (protocolId) => {
  return {
    type: DASHBOARD_DATA,
    protocolId,
  };
};

export const getStudiesData = (userId) => {
  return {
    type: GET_STUDIES_LIST,
    userId,
  };
};

export const getPinnedData = (userId) => {
  return {
    type: GET_PINNED_LIST,
    userId,
  };
};

export const updateSelectedStudy = (study) => {
  return {
    type: PAGEHEADER_UPDATE,
    study,
  };
};

export const getFlowDetailsOfStudy = (
  protocolId,
  protocolnumberstandard,
  userId
) => {
  return {
    type: GET_DATA_FLOW_LIST,
    protocolId,
    protocolnumberstandard,
    userId,
  };
};

export const getFlowDetailsOfStudyInterval = (
  protocolId,
  protocolnumberstandard,
  userId
) => {
  return {
    type: GET_DATA_FLOW_LIST_INTERVAL,
    protocolId,
    protocolnumberstandard,
    userId,
  };
};

export const getDatasetIngestionOfStudy = (
  protocolId,
  testFlag = "",
  active = "",
  userId = "",
  processStatus = "",
  pipelineType = TABULAR_PIPELINE
) => {
  return {
    type: GET_DATASET_INGESTION_SUMMARY,
    protocolId,
    testFlag,
    active,
    userId,
    processStatus,
    pipelineType,
  };
};

export const getAllIngestionOfStudy = (
  UserID,
  testFlag = "",
  active = "",
  processStatus = "",
  limit = "",
  noOfDays = "",
  pipelineType = TABULAR_PIPELINE
) => {
  return {
    type: GET_ALL_INGESTION_SUMMARY,
    UserID,
    testFlag,
    active,
    processStatus,
    limit,
    noOfDays,
    pipelineType,
  };
};

export const getSpecificIngestionDataSets = (
  UserID,
  processStatus = "",
  active = "",
  testFlag = "",
  noOfDays = "",
  limit = "",
  pipelineType = TABULAR_PIPELINE
) => {
  return {
    type: GET_SPECIFIC_INGESTION_DATASETS,
    UserID,
    testFlag,
    active,
    processStatus,
    limit,
    noOfDays,
    pipelineType,
  };
};

export const SelectedDataflow = (dataflow) => {
  return {
    type: SELECTED_DATAFLOW,
    dataflow,
  };
};

export const ClearSelectedDataflow = (dataflow) => {
  return {
    type: CLEAR_SELECTED_DATAFLOW,
    dataflow,
  };
};

export const updateHeaderCount = ({
  dsCount,
  dfCount,
  ActiveDfCount,
  InActiveDfCount,
  ActiveDsCount,
  InActiveDsCount,
}) => {
  return {
    type: UPDATE_HEADER_COUNT,
    ActiveDfCount,
    InActiveDfCount,
    ActiveDsCount,
    InActiveDsCount,
    dfCount,
    dsCount,
  };
};

export const setMonitorTitle = (title) => {
  return {
    type: SET_MONITOR_TITLE,
    title,
  };
};

export const setAllMonitorTitle = (title) => {
  return {
    type: SET_ALL_MONITOR_TITLE,
    title,
  };
};

export const setMonitorShowSummary = (showSummary) => {
  return {
    type: SET_MONITOR_SHOW_SUMMARY,
    showSummary,
  };
};

export const updateDFStatus = (dfId, newStatus) => {
  return {
    type: UPDATE_DF_STATUS,
    dfId,
    newStatus,
  };
};

export const updatePreviousStateSegmentControlTab = (newStatus) => {
  return {
    type: UPDATE_PREVIOUS_SEGMENT_CONTROL,
    newStatus,
  };
};

export const updatePreviousStatePipelineType = (newStatus) => {
  return {
    type: UPDATE_PREVIOUS_PIPELINE_TYPE,
    newStatus,
  };
};

export const updateDashboardModal = (modal, state) => {
  return {
    type: UPDATE_DASHBOARD_MODAL_STATUS,
    modal,
    state,
  };
};

export const updateDashboardModalDataflow = (details) => {
  return {
    type: UPDATE_DASHBOARD_MODAL_DATAFLOW,
    details,
  };
};

export const updateDashboardUpdateToggle = () => {
  return {
    type: UPDATE_DASHBOARD_DATA_TOGGLE,
  };
};
