/* eslint-disable no-nested-ternary */
import { extractHostname, generatedBName, isSftp } from "../../utils";
import { encrypt } from "../../utils/encryptionHelper";

// constants for location - data types
const TABULAR_DT = "Tabular";
const TABULAR_RAVE_DT_VALUE = "TabularRaveSOD";
const TABULAR_VEEVA_DT_VALUE = "TabularVeeva";
const BDC_DT_VALUE = "BDC";
const UNC_DT_VALUE = "UNC";
const CLINICAL_ODM_DT_VALUE = "ClinicalOneODM";
const INFORM_ODM_DT_VALUE = "InformODM";
const RAVE_ODM_DT_VALUE = "RaveODM";
const SIGNANT_HEALTH_JSON_DT_VALUE = "SignantHealthJSON";

const TABULAR_DATASTRUCTURES = [
  TABULAR_DT,
  TABULAR_RAVE_DT_VALUE,
  TABULAR_VEEVA_DT_VALUE,
  BDC_DT_VALUE,
];

const ODM_DATASTRUCTURES = [
  CLINICAL_ODM_DT_VALUE,
  INFORM_ODM_DT_VALUE,
  RAVE_ODM_DT_VALUE,
];

// check if data structure is Tabular
export const checkForTabularAdapter = (adapter) => {
  if (TABULAR_DATASTRUCTURES?.includes(adapter)) return true;
  return false;
};

// check if data structure is SignantHealthJSON
export const checkForSignantHealthJSON = (dataStructure) => {
  if (dataStructure === SIGNANT_HEALTH_JSON_DT_VALUE) return true;
  return false;
};

// check if data structure is ODM
export const checkForODMAdapter = (adapter) => {
  if (
    adapter === CLINICAL_ODM_DT_VALUE ||
    adapter === INFORM_ODM_DT_VALUE ||
    adapter === RAVE_ODM_DT_VALUE
  )
    return true;
  return false;
};

// check if data structure is Rave ODM
export const checkForRaveAdapter = (adapter) => {
  if (adapter === RAVE_ODM_DT_VALUE) return true;
  return false;
};

// get object key from lov object of data structure passed
export const getLocationConfigKey = (dataStructureProp, locationLOV) => {
  let lovObjectKey = null;
  // loop through config and get adapter
  Object.keys(locationLOV).forEach((key) => {
    if (
      locationLOV[key]?.value?.toString?.()?.toLowerCase?.() ===
      dataStructureProp?.toString?.()?.toLowerCase?.()
    ) {
      lovObjectKey = key;
    }
  });
  return lovObjectKey;
};

// get versions from LOV
export const getVersions = (dataStructureProp, locationLOV) => {
  try {
    const versions = [];
    const lovKey = getLocationConfigKey(dataStructureProp, locationLOV);
    locationLOV?.[lovKey]?.adapterversion?.forEach(
      (item) => item && versions.push({ label: item, value: item })
    );
    return versions;
  } catch (e) {
    return [];
  }
};

// get ODM Auth type from LOV
export const getAuthenticationTypes = (dataStructureProp, locationLOV) => {
  try {
    const authTypes = [];
    const lovKey = getLocationConfigKey(dataStructureProp, locationLOV);
    locationLOV?.[lovKey]?.authenticationtype?.forEach(
      (item) => item && authTypes.push({ label: item, value: item })
    );
    return authTypes;
  } catch (e) {
    return [];
  }
};

// check if version fields needs to be validated
export const shouldValidateODMVersion = (key) => {
  if (key === CLINICAL_ODM_DT_VALUE) return false;
  return true;
};

// check if auth type is basic
export const checkBasicAuthType = (authType) => {
  if (["basic auth"]?.includes(authType?.toString?.()?.toLowerCase?.()))
    return true;
  return false;
};

// check if auth type is subscription key
export const checkSubscriptionKeyAuthType = (authType) => {
  if (["subscription key"]?.includes(authType?.toString?.()?.toLowerCase?.()))
    return true;
  return false;
};

// show/hide auth type fields(Username, Password) based on auth type
export const showAuthTypeField = (authType) => {
  if (!authType) return false;
  return checkBasicAuthType(authType);
};

export const DEFAULT_LOCATION_FIELDS = {
  locationName: true,
  active: true,
  externalSystemName: true,
  dataStructure: true,
  locationType: true,
  ownerGroup: true,
  sharedGroup: true,
  encryptionType: false,
  ipServer: true,
  url: false,
  version: false,
  authType: false,
  port: false,
  dbName: false,
  whse: false,
  schem: false,
  userName: true,
  password: true,
  connURL: true,
  testConnectionBtn: true,
};

export const setLocationFields = (
  showFields,
  setShowFields,
  dataStructure,
  locType,
  isJDBC = false,
  authType
) => {
  // case 1: tabular locations
  if (TABULAR_DATASTRUCTURES?.includes(dataStructure)) {
    // SFTP, FTPS, S3 location types
    if (!isJDBC) {
      setShowFields({
        locationName: true,
        active: true,
        externalSystemName: true,
        dataStructure: true,
        locationType: true,
        ownerGroup: true,
        sharedGroup: true,
        encryptionType: locType === "FTPS" ? true : false,
        ipServer: true,
        url: false,
        version: false,
        authType: false,
        port: false,
        dbName: false,
        whse: false,
        schem: false,
        userName: locType === UNC_DT_VALUE ? false : true,
        password: locType === UNC_DT_VALUE ? false : true,
        connURL: true,
        testConnectionBtn: true,
      });
    }
    // JDBC Flows
    if (isJDBC) {
      setShowFields({
        locationName: true,
        active: true,
        externalSystemName: true,
        dataStructure: true,
        locationType: true,
        ownerGroup: true,
        sharedGroup: true,
        encryptionType: false,
        ipServer: true,
        url: false,
        version: false,
        authType: false,
        port: true,
        dbName: true,
        whse: locType === "Azure – Snowflake" ? true : false,
        schem: locType === "Azure – Snowflake" ? true : false,
        userName: true,
        password: true,
        connURL: true,
        testConnectionBtn: true,
      });
    }
  }

  // case 2: ODM Adapter
  else if (ODM_DATASTRUCTURES?.includes(dataStructure)) {
    setShowFields({
      locationName: true,
      active: true,
      externalSystemName: true,
      dataStructure: true,
      locationType: false,
      ownerGroup: true,
      sharedGroup: true,
      encryptionType: false,
      ipServer: false,
      url: true,
      version: dataStructure === CLINICAL_ODM_DT_VALUE ? false : true,
      authType: true,
      port: false,
      dbName: false,
      whse: false,
      schem: false,
      userName: showAuthTypeField(authType),
      password: showAuthTypeField(authType),
      connURL: false,
      testConnectionBtn: false,
    });
  } // case 3: Signant Health JSON
  else if (checkForSignantHealthJSON(dataStructure)) {
    setShowFields({
      ...DEFAULT_LOCATION_FIELDS,
      ipServer: false,
      locationType: true,
      url: true,
      version: true,
      authType: true,
      subscriptionKey: checkSubscriptionKeyAuthType(authType),
      userName: showAuthTypeField(authType),
      password: showAuthTypeField(authType),
      connURL: false,
      testConnectionBtn: false,
    });
  }
};

// clear redux form fields on data structure changes
export const clearLocationFields = (
  dispatch,
  change,
  dataStructureProp,
  authType,
  locType
) => {
  const isODMAdapter = checkForODMAdapter(dataStructureProp);
  const isTabular = checkForTabularAdapter(dataStructureProp);

  // if tabular adapter, clear odm fields
  if (isTabular) {
    dispatch(change("AddLocationForm", "version", ""));
    dispatch(change("AddLocationForm", "authType", ""));
    dispatch(change("AddLocationForm", "url", ""));
  }

  // clear subscription key, authType and locationType when data structure is changed
  if (!checkForSignantHealthJSON(dataStructureProp) && !isODMAdapter) {
    dispatch(change("AddLocationForm", "subscriptionKey", ""));
    if (!checkBasicAuthType(authType)) {
      dispatch(change("AddLocationForm", "authType", ""));
    }
    if (locType === "API") {
      dispatch(change("AddLocationForm", "locationType", ""));
    }
  }

  // clear version and authType when clinical one odm is changed
  if (
    !shouldValidateODMVersion(dataStructureProp) &&
    !checkBasicAuthType(authType)
  ) {
    dispatch(change("AddLocationForm", "version", ""));
    dispatch(change("AddLocationForm", "authType", ""));
  }

  // clear user name & password field when auth type is changed
  if (
    (isODMAdapter || checkForSignantHealthJSON(dataStructureProp)) &&
    !checkBasicAuthType(authType)
  ) {
    dispatch(change("AddLocationForm", "userName", ""));
    dispatch(change("AddLocationForm", "password", ""));
  }
};

// generate body used in location
export const generateLocationBody = ({ values, sharedGroupName }) => {
  // encrypt password before sending
  let encryptPassword = "";
  if (values.password) {
    encryptPassword = encrypt(values.password);
  }

  const numberActive = values.active === 0 ? false : true;
  const active =
    typeof values.active === "boolean" ? values.active : numberActive;
  const encryptionTypeReq =
    values?.locationType === "FTPS" ? values?.encryptionType : null;

  let reqBody = {
    ...values,
    sharedGroupName,
    password: encryptPassword,
    encryptionType: encryptionTypeReq,
    active,
    systemName: "CDI",
  };

  // ODM/SignanthealthJSON Request Body
  if (
    checkForODMAdapter(values?.dataStructure) ||
    checkForSignantHealthJSON(values?.dataStructure)
  ) {
    reqBody = {
      locationID: values?.locationID || null,
      locationName: values?.locationName || null,
      externalSystemName: values?.externalSystemName || null,
      dataStructure: values?.dataStructure || null,
      [checkForODMAdapter(values?.dataStructure) ? "odmVersion" : "version"]:
        values?.version || null,
      ipServer: values?.url || null,
      authType: values?.authType || null,
      userName: checkBasicAuthType(values?.authType)
        ? values?.userName || null
        : null,
      password: checkBasicAuthType(values?.authType) ? encryptPassword : null,
      subscriptionKey: checkSubscriptionKeyAuthType(values?.authType)
        ? values.subscriptionKey || null
        : null,
      encryptionType: encryptionTypeReq,
      active,
      systemName: "CDI",
      ownerGroupName: values.ownerGroupName,
      sharedGroupName,
    };

    // Only add locationType if it's SignantHealthJSON
    if (checkForSignantHealthJSON(values?.dataStructure)) {
      reqBody.locationType = values?.locationType || null;
    }
  }

  return reqBody;
};

// generate body used in test connection
export const generateTestConnectionBody = ({
  dbName,
  ipServer,
  locationPassword,
  locationPPK,
  locationType,
  password,
  port,
  userName,
  whse,
  connectionURL = "",
}) => {
  let reqBody = {
    username: userName || "",
    password:
      password === "Yes" || typeof password === "object"
        ? locationPassword
        : password || "",
    endPoint: "/checkconnection/sftp",
  };

  if (isSftp(locationType) && (password === "No" || !password) && locationPPK) {
    reqBody = {
      username: userName || "",
      ppk: locationPPK,
      endPoint: "/checkconnection/sftp",
    };
  }

  if (locationType) {
    if (locationType === UNC_DT_VALUE) {
      reqBody = {
        ...reqBody,
        connectionURL,
        endPoint: UNC_DT_VALUE,
        // host: ipServer || "",
      };
    } else if (!isSftp(locationType)) {
      // let dbPort;
      // if (locationType.includes("Dynamic Port")) {
      //   dbPort = 1433;
      // } else {
      //   dbPort = port ? port : "";
      // }
      /* Don't remove this commented code is using for portless story CDAS-11093 */

      reqBody = {
        ...reqBody,
        endPoint: "/checkconnection/jdbc",
        host: ipServer || "",
        databaseName: dbName || "",
        userId: "",
        database: generatedBName(locationType),
        port: port ? port : "",
        connectionURL,
      };
    } else if (locationType === "Amazon S3") {
      reqBody = {
        endPoint: "AmazonS3",
        accessKeyId: userName || "",
        secretAccessKey: password || "",
        bucket: ipServer || "",
        region: whse || "",
      };
    } else {
      reqBody = {
        ...reqBody,
        connectionURL,
        host: extractHostname(ipServer) || "",
      };
    }
  }

  return reqBody;
};
