// libraries
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
// apollo components
import Button from "apollo-react/components/Button";
// helpers
import {
  Categories,
  Features,
  useStudyPermission,
} from "../../Common/usePermission";
import { checkForExternalSystem } from "../../../utils";
import { createDataflowDraft } from "../../../services/ApiServices";
import { MessageContext } from "../../Providers/MessageProvider";
import {
  setFromCreateDraft,
  triggerDataFlowDetailsPull,
} from "../../../store/actions/DataFlowAction";
// styles
import "./DraftButton.scss";

const DraftButton = () => {
  // redux state
  const history = useHistory();
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);

  // local state
  const [btnLoading, setBtnLoading] = useState(false);

  // store
  const dataFlowStore = useSelector((state) => state.dataFlow);
  const dashboard = useSelector((state) => state.dashboard);
  const { dataFlowdetail: dataFlowDetails, isPublishedFlow } = dataFlowStore;
  const { prot_id: protId } = dashboard?.selectedCard;

  // permissions
  const {
    canCreate: canCreateDataFlowLocal,
    canUpdate: canUpdateDataFlowLocal,
  } = useStudyPermission(
    Categories.CONFIGURATION,
    Features.DATA_FLOW_CONFIGURATION,
    protId
  );

  // external df permission
  const { canEnabled: isDFOverride } = useStudyPermission(
    Categories.CONFIGURATION,
    Features.DF_OVERRIDE,
    protId
  );

  // get permissions
  const isExternalSystem =
    checkForExternalSystem(dataFlowDetails?.externalsystemname) || false;
  const canCreate = isExternalSystem
    ? canCreateDataFlowLocal && isDFOverride
    : canCreateDataFlowLocal;
  const canUpdate = isExternalSystem
    ? canUpdateDataFlowLocal && isDFOverride
    : canUpdateDataFlowLocal;

  // move from publish to draft data flow
  const handleEditDraft = async () => {
    if (dataFlowDetails?.dataflowid) {
      // clear store and set published flow to false
      await dispatch(triggerDataFlowDetailsPull());

      // redirect to first page
      setTimeout(() => {
        history.push(
          `/dashboard/dataflow-management/${dataFlowDetails?.dataflowid}`
        );
      }, 50);
    }
  };

  // create draft and move to draft page
  const handleCreateDraft = async () => {
    setBtnLoading(true);

    // check data flow id and call create draft API
    if (dataFlowDetails?.dataflowid) {
      const createDraftStatus = await createDataflowDraft(
        dataFlowDetails?.dataflowid
      );
      if (createDraftStatus?.status) {
        dispatch(setFromCreateDraft(true));
        setTimeout(() => {
          messageContext.showSuccessMessage(
            "Dataflow draft created successfully"
          );
        }, 5000);
        // redirect to edit draft page
        await handleEditDraft();
      } else {
        messageContext.showErrorMessage("Something went wrong");
      }
    }

    setBtnLoading(false);
  };

  // return if data flow is not published / does not have draft key
  if (!isPublishedFlow || dataFlowDetails?.hasDraft === undefined) {
    return null;
  }

  // EDIT Draft
  if (dataFlowDetails?.hasDraft) {
    return (
      <>
        <Button
          variant="secondary"
          size="small"
          disabled={!canUpdate}
          onClick={handleEditDraft}
        >
          Edit draft
        </Button>
      </>
    );
  }

  // CREATE Draft
  return (
    <>
      <Button
        variant="secondary"
        size="small"
        disabled={!canCreate || btnLoading}
        onClick={handleCreateDraft}
      >
        {btnLoading && (
          <>
            <CircularProgress className="modal-loader-draft" size="14px" />
            &nbsp;
          </>
        )}
        Create draft
      </Button>
    </>
  );
};

export default DraftButton;
