// libraries
import React from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import withStyles from "@mui/styles/withStyles";
// apollo components
import { neutral8, orange, red } from "apollo-react/colors";
import Hero from "apollo-react/components/Hero";
import Peek from "apollo-react/components/Peek";
import QuarantineIcon from "apollo-react-icons/EyeHidden";
import StatusDotOutlineIcon from "apollo-react-icons/StatusDotOutline";
import StatusExclamationIcon from "apollo-react-icons/StatusExclamation";
import Typography from "apollo-react/components/Typography";

// components
import { checkForODMAdapter } from "../../../components/Common/LocationModalHelper";
import { ReactComponent as DatasetsIcon } from "../../../components/Icons/dataset.svg";
import { ReactComponent as FailureIcon } from "../../../components/Icons/Failed.svg";
import { ReactComponent as StaleIcon } from "../../../components/Icons/Stale-grey.svg";
import InfoCard from "./InfoCard";

/* eslint-disable import/prefer-default-export */
const queryParams = {
  JOB_STATUS_FAILED: "jsf",
  JOB_STATUS_IN_QUEUE: "jsq",
  EXCEEDS_PER_CHANGE: "epc",
  STALE: "stale",
  QUARANTINE: "quarantine",
  LATENCY_WARNING: "lw",
  REFRESH_ALERTS: "ra",
  CONTROL: "control",
  DATASET_WITH_ISSUES: "DATASETWITHISSUES",
};

const queryParamsFull = {
  JOB_STATUS_FAILED: "FAILED",
  JOB_STATUS_IN_QUEUE: "QUEUED",
  EXCEEDS_PER_CHANGE: "EXCEEDSPERCENTAGE",
  STALE: "STALE",
  QUARANTINE: "QUARANTINED",
  LATENCY_WARNING: "DATALATENCYWARNINGS",
  REFRESH_ALERTS: "ra",
  CONTROL: "control",
  DATASET_WITH_ISSUES: "DATASETWITHISSUES",
};

// constants for pipeline - data types
const TABULAR_VALUE = "Tabular";
const ODM_VALUE = "ODM";
const BASIC_DATA_CONNECTOR_VALUE = "Basic Data Connector";
const BASIC_DATA_CONNECTOR = "BDC";
const TABULAR_VEEVA = "TabularVeeva";
const STANDARDIZE_METADATA = "StandardizeMetadata";
const STANDARDIZE_METADATA_VALUE = "Standardize Metadata";

// pipeline type array
const DEFAULT_PIPELINE_DATA_TYPE = [
  {
    value: TABULAR_VALUE,
    label: TABULAR_VALUE,
  },

  {
    value: ODM_VALUE,
    label: ODM_VALUE,
  },
  {
    value: BASIC_DATA_CONNECTOR_VALUE,
    label: BASIC_DATA_CONNECTOR_VALUE,
  },
  {
    value: STANDARDIZE_METADATA_VALUE,
    label: STANDARDIZE_METADATA_VALUE,
  },
];

const TABULAR_ODM_PIPELINE_DATA_TYPE = [
  {
    value: TABULAR_VALUE,
    label: TABULAR_VALUE,
  },

  {
    value: ODM_VALUE,
    label: ODM_VALUE,
  },
  {
    value: STANDARDIZE_METADATA_VALUE,
    label: STANDARDIZE_METADATA_VALUE,
  },
];

const TABULAR_BASIC_PIPELINE_DATA_TYPE = [
  {
    value: TABULAR_VALUE,
    label: TABULAR_VALUE,
  },
  {
    value: BASIC_DATA_CONNECTOR_VALUE,
    label: BASIC_DATA_CONNECTOR_VALUE,
  },
  {
    value: STANDARDIZE_METADATA_VALUE,
    label: STANDARDIZE_METADATA_VALUE,
  },
];

const TABULAR_PIPELINE_DATA_TYPE = [
  {
    value: TABULAR_VALUE,
    label: TABULAR_VALUE,
  },
  {
    value: STANDARDIZE_METADATA_VALUE,
    label: STANDARDIZE_METADATA_VALUE,
  },
];

// set Pipeline Types
const getPipelineDataTypes = (
  showODM = false,
  showBasicDataConnector = false,
  showStandardizeMetadata = false,
  setPipelineTypes
) => {
  // case 1: ALL
  if (showODM && showBasicDataConnector && showStandardizeMetadata) {
    setPipelineTypes(DEFAULT_PIPELINE_DATA_TYPE);
  }
  // case 2: ODM
  else if (showODM) {
    setPipelineTypes(TABULAR_ODM_PIPELINE_DATA_TYPE);
  }
  // case 3: BasicDataConnector
  else if (showBasicDataConnector) {
    setPipelineTypes(TABULAR_BASIC_PIPELINE_DATA_TYPE);
  }
  // TABULAR
  else setPipelineTypes(TABULAR_PIPELINE_DATA_TYPE);
};

const DEFAULT_KP_CARDS = {
  datasetPipelines: true,
  dataRefreshAlerts: true,
  datasetsWithIssues: true,
  staleDatasets: true,
  quarantinedFiles: true,
  filesExceed: true,
  inQueue: true,
  cards: 7,
};

const DATASET_PIPELINE = "Dataset Pipeline";
const DATASET_PIPELINES = "Dataset Pipelines";

// set KP Cards visibility based on pipeline type
const setKPCardsVisibility = (pipelineType, setShowCards) => {
  // case 1: tabular pipeline type
  if (pipelineType === TABULAR_VALUE) {
    setShowCards(DEFAULT_KP_CARDS);
  }
  // case 2: ODM pipeline type
  if (pipelineType === ODM_VALUE) {
    setShowCards({
      datasetPipelines: true,
      dataRefreshAlerts: true,
      datasetsWithIssues: false,
      dataStructure: false,
      staleDatasets: false,
      quarantinedFiles: false,
      filesExceed: false,
      inQueue: false,
      cards: 2,
    });
  }
  // case 3: Basic Data Connector pipeline type
  if (pipelineType === BASIC_DATA_CONNECTOR_VALUE) {
    setShowCards({
      datasetPipelines: true,
      dataRefreshAlerts: true,
      datasetsWithIssues: true,
      dataStructure: false,
      staleDatasets: false,
      quarantinedFiles: false,
      filesExceed: false,
      inQueue: true,
      cards: 4,
    });
  }
  // case 4: Standardize Metadata pipeline type
  if (pipelineType === STANDARDIZE_METADATA_VALUE) {
    setShowCards({
      datasetPipelines: false,
      dataRefreshAlerts: false,
      datasetsWithIssues: false,
      dataStructure: false,
      staleDatasets: false,
      quarantinedFiles: false,
      filesExceed: false,
      inQueue: false,
      cards: 0,
    });
  }
};

const DEFAULT_KP_CARD_TITLES = {
  datasetPipelines: DATASET_PIPELINES,
};

const DEFAULT_KP_CARD_SUBTITLES = {
  datasetPipelines:
    "Count of the Active Datasets, i.e. All the Datasets associated with the user’s studies.",
  dataRefreshAlerts: "Count of all datasets whose latest job status is Failed.",
};

// set KP Cards title and subtitles based on pipeline type
const setKPCardsTitles = (pipelineType, setCardTitles, setCardSubTitles) => {
  // case 1: tabular pipeline type
  if (pipelineType === TABULAR_VALUE) {
    setCardTitles(DEFAULT_KP_CARD_TITLES);
    setCardSubTitles(DEFAULT_KP_CARD_SUBTITLES);
  }
  // case 2: ODM pipeline type
  if (pipelineType === ODM_VALUE) {
    setCardTitles({
      datasetPipelines: "Pipelines",
    });
    setCardSubTitles({
      datasetPipelines:
        "Count of the Active Pipelines, i.e. All the pipelines associated with this study.",
      dataRefreshAlerts:
        "Count of all pipelines whose latest job status is Failed.",
    });
  }
};

// table title based on pipeline
const getTableTitle = (pipeline = TABULAR_VALUE) => {
  if (pipeline === TABULAR_VALUE) return DATASET_PIPELINES;
  if (pipeline === ODM_VALUE) return "Pipeline";
  if (pipeline === STANDARDIZE_METADATA_VALUE) return "";
  return DATASET_PIPELINES;
};

// table title for view all based on pipeline
const DEFAULT_TABLE_TITLE_VIEW_ALL = DATASET_PIPELINE;
const getTableTitleViewAll = (pipeline = TABULAR_VALUE) => {
  if (pipeline === TABULAR_VALUE) return DATASET_PIPELINE;
  if (pipeline === ODM_VALUE) return "Pipeline";
  // if (pipeline === STANDARDIZE_METADATA_VALUE) return "Standardize Metadata";
  return DATASET_PIPELINE;
};

// table toggle title based of pipeline
const DEFAULT_TABLE_TOGGLE_TITLE = "View only active datasets";
const getTableToggleTitle = (pipeline = TABULAR_VALUE) => {
  if (pipeline === TABULAR_VALUE) return DEFAULT_TABLE_TOGGLE_TITLE;
  if (pipeline === ODM_VALUE) return "View only active pipelines";
  return DEFAULT_TABLE_TOGGLE_TITLE;
};

const Tabular = () => (
  <>
    <p>Tabular includes:</p>
    <ul>
      <li>Tabular</li>
      <li>Tabular Rave SOD</li>
      <li>Tabular Veeva</li>
    </ul>
  </>
);

const BDC = () => (
  <>
    <p>Basic Data Connector includes:</p>
    <ul>
      <li>Basic Data Connector</li>
    </ul>
  </>
);

const ODM = () => (
  <>
    <p>ODM includes:</p>
    <ul>
      <li>ClinicalOne ODM</li>
      <li>Rave ODM</li>
      <li>Inform ODM</li>
    </ul>
  </>
);

const StandardizeMetadata = () => (
  <>
    <p>Standardize Metadata includes:</p>
    <ul>
      <li>Standardize Metadata</li>
    </ul>
  </>
);

// content for pipeline type dropdown
const pipelinePeekTitles = () => {
  return (
    <div className="pipeline-info">
      <p>Pipeline Type is determined by the adapter of the Data Flow.</p>
      <Tabular />
      <ODM />
      <BDC />
      <StandardizeMetadata />
    </div>
  );
};

// content for pipeline type dropdown
const pipelinePeekTitlesTabular = () => {
  return (
    <div className="pipeline-info">
      <p>Pipeline Type is determined by the adapter of the Data Flow.</p>
      <Tabular />
      <StandardizeMetadata />
    </div>
  );
};

const pipelinePeekTitlesBasic = () => {
  return (
    <div className="pipeline-info">
      <p>Pipeline Type is determined by the adapter of the Data Flow.</p>
      <Tabular />
      <BDC />
      <StandardizeMetadata />
    </div>
  );
};

// content for pipeline type dropdown
const pipelinePeekTitlesWithODM = () => {
  return (
    <div className="pipeline-info">
      <p>Pipeline Type is determined by the adapter of the Data Flow.</p>
      <Tabular />
      <ODM />
      <StandardizeMetadata />
    </div>
  );
};

const PipelineContent = withStyles()(pipelinePeekTitles);
const PipelineContentTabular = withStyles()(pipelinePeekTitlesTabular);
const PipelinePeekTitlesBasic = withStyles()(pipelinePeekTitlesBasic);
const PipelineContentWithODM = withStyles()(pipelinePeekTitlesWithODM);

// check if dataset needs to be redirected to transfer log page directly
const shouldRedirectToTransferLog = (dataset) => {
  if (checkForODMAdapter(dataset?.datastructure)) {
    return true;
  }
  return false;
};

const MonitorTabCards = ({
  showCards,
  summary,
  cardTitles,
  cardSubTitles,
  totalCount,
  handlePeekOpen,
  setOpen,
  handleViewButton,
  open,
  curRow,
}) => {
  return (
    <>
      <Hero>
        <div className="topContainer" style={{ position: "relative" }}>
          <Typography
            variant="title1"
            style={{
              lineHeight: "32px",
              fontWeight: 600,
              display: "inline-flex",
              position: "absolute",
              left: "0",
            }}
            darkMode
          >
            Study Monitor Summary
          </Typography>
        </div>
        <div
          className={`dashboardSummary dashboardSummary-cards-${
            showCards.cards
          } ${summary?.flg_critical ? "dashboardSummary-cards-critical" : ""}`}
        >
          {/* Dataset Pipelines */}
          {showCards.datasetPipelines && (
            <InfoCard
              title={cardTitles?.datasetPipelines}
              subtitle={cardSubTitles?.datasetPipelines}
              value={totalCount}
              placement="right"
              icon={() => {
                return (
                  <DatasetsIcon
                    style={{ fill: "#00C221" }}
                    className="newMonitor-icon"
                  />
                );
              }}
              handlePeekOpen={handlePeekOpen}
              closePeek={() => setOpen(false)}
              handleViewClick={() => {
                handleViewButton("", cardTitles?.datasetPipelines);
              }}
            />
          )}

          {/* Data Refresh Alerts */}
          {showCards.dataRefreshAlerts && (
            <InfoCard
              title="Data Refresh Alerts"
              subtitle={cardSubTitles?.dataRefreshAlerts}
              value={summary?.data_refresh_alerts}
              icon={() => {
                return (
                  <FailureIcon
                    className="newMonitor-icon"
                    style={{ color: red }}
                  />
                );
              }}
              handlePeekOpen={handlePeekOpen}
              closePeek={() => setOpen(false)}
              handleViewClick={() => {
                handleViewButton(
                  queryParamsFull.JOB_STATUS_FAILED,
                  "Data Refresh Alerts"
                );
              }}
            />
          )}

          {/* Datasets with Issues */}
          {showCards.datasetsWithIssues && (
            <InfoCard
              title="Datasets with Issues"
              subtitle={`Files which were processed successfully but in which dataset configuration or VLC compliance issues were identified (will be listed as "PROCESSED WITH ERRORS" in data flow monitor)`} // NOSONAR
              value={summary?.fileswith_issues}
              icon={() => {
                return (
                  <StatusExclamationIcon
                    style={{ color: orange }}
                    className="newMonitor-icon"
                  />
                );
              }}
              color="orange"
              showBorder={summary?.flg_critical || false}
              handlePeekOpen={handlePeekOpen}
              closePeek={() => setOpen(false)}
              handleViewClick={() => {
                handleViewButton(
                  queryParamsFull.DATASET_WITH_ISSUES,
                  "Datasets with Issues"
                );
              }}
            />
          )}

          {/* Stale Datasets */}
          {showCards.staleDatasets && (
            <InfoCard
              title="Stale Datasets"
              subtitle="Datasets for which a file has not been received within the specified number of days."
              value={summary?.stale_datasets}
              icon={() => {
                return (
                  <StaleIcon
                    style={{ fill: "#F2F2F2", color: "#FF2733" }}
                    className="newMonitor-icon"
                  />
                );
              }}
              color="orange"
              handlePeekOpen={handlePeekOpen}
              closePeek={() => setOpen(false)}
              handleViewClick={() => {
                handleViewButton(queryParamsFull.STALE, "Stale Datasets");
              }}
            />
          )}

          {/* Quarantined Files */}
          {showCards.quarantinedFiles && (
            <InfoCard
              title="Quarantined Files"
              subtitle="Files being processed for value level conformance rules assigned the action of 'reject'."
              value={summary?.quarantined_files}
              icon={() => {
                return (
                  <QuarantineIcon
                    style={{
                      fill: "#FF9300",
                      color: "#FF9300",
                    }}
                    className="newMonitor-icon"
                  />
                );
              }}
              handlePeekOpen={handlePeekOpen}
              closePeek={() => setOpen(false)}
              handleViewClick={() => {
                handleViewButton(
                  queryParamsFull.QUARANTINE,
                  "Quarantined Files"
                );
              }}
            />
          )}

          {/* Files exceed */}
          {showCards.filesExceed && (
            <InfoCard
              title="Files exceed % of change"
              subtitle="Files in which the change in the number of records received exceeded the configured % row count decrease allowed."
              value={summary?.files_exceeding}
              icon={() => {
                return (
                  <SwapVertIcon
                    style={{ fill: "#595959" }}
                    className="conter-icon"
                  />
                );
              }}
              color="orange"
              handlePeekOpen={handlePeekOpen}
              closePeek={() => setOpen(false)}
              handleViewClick={() => {
                handleViewButton(
                  queryParamsFull.EXCEEDS_PER_CHANGE,
                  "Files exceed % of change"
                );
              }}
            />
          )}

          {/* In Queue */}
          {showCards.inQueue && (
            <InfoCard
              title="In Queue"
              subtitle="Count of all datasets whose latest job status is Queued"
              value={summary?.in_queue}
              icon={StatusDotOutlineIcon}
              color="blueDark"
              placement="left"
              handlePeekOpen={handlePeekOpen}
              closePeek={() => setOpen(false)}
              handleViewClick={() => {
                handleViewButton(
                  queryParamsFull.JOB_STATUS_IN_QUEUE,
                  "In Queue"
                );
              }}
            />
          )}
        </div>

        {/* Peek when hover over KP card */}
        <Peek
          open={open}
          followCursor
          placement={curRow?.placement ?? "top"}
          content={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div style={{ maxWidth: 400 }}>
              <Typography
                variant="title2"
                gutterBottom
                style={{ fontWeight: 600 }}
              >
                {curRow.name}
              </Typography>
              <Typography variant="body2" style={{ color: neutral8 }}>
                {curRow.description}
              </Typography>
            </div>
          }
        />
      </Hero>
    </>
  );
};

const getViewAllData = ({ dashboard, parsedQuery }) => {
  const rowData = dashboard.ingestionData?.datasets || [];
  const params = Object.keys(parsedQuery);
  const availableValues = Object.values(queryParams);
  const validParams = params.filter((q) => availableValues.includes(q));
  if (validParams.length) {
    const filteredRowData = rowData.filter((r) => {
      if (params.includes(queryParams.JOB_STATUS_IN_QUEUE)) {
        return r.jobstatus?.toLowerCase().trim() === "queued";
      }
      if (params.includes(queryParams.JOB_STATUS_FAILED)) {
        return r.jobstatus?.toLowerCase().trim() === "failed";
      }
      if (params.includes(queryParams.LATENCY_WARNING)) {
        return !!r.data_latency_warnings;
      }
      if (params.includes(queryParams.REFRESH_ALERTS)) {
        return !!r.data_refresh_alerts;
      }
      if (params.includes(queryParams.EXCEEDS_PER_CHANGE)) {
        return !!r.exceeds_pct_cng;
      }
      if (params.includes(queryParams.STALE)) {
        return r.jobstatus?.toLowerCase().trim() === "stale";
      }
      if (params.includes(queryParams.QUARANTINE)) {
        return !!r.quarantined_files;
      }
      return true;
    });
    return filteredRowData;
  }
  return rowData;
};

export {
  DEFAULT_KP_CARD_SUBTITLES,
  DEFAULT_KP_CARD_TITLES,
  DEFAULT_KP_CARDS,
  DEFAULT_PIPELINE_DATA_TYPE,
  DEFAULT_TABLE_TITLE_VIEW_ALL,
  DEFAULT_TABLE_TOGGLE_TITLE,
  getPipelineDataTypes,
  getTableTitle,
  getTableTitleViewAll,
  getTableToggleTitle,
  ODM_VALUE,
  PipelineContent,
  PipelineContentTabular,
  PipelinePeekTitlesBasic,
  PipelineContentWithODM,
  pipelinePeekTitles,
  queryParams,
  queryParamsFull,
  setKPCardsTitles,
  setKPCardsVisibility,
  shouldRedirectToTransferLog,
  TABULAR_VALUE,
  TABULAR_PIPELINE_DATA_TYPE,
  MonitorTabCards,
  getViewAllData,
  BASIC_DATA_CONNECTOR_VALUE,
  STANDARDIZE_METADATA,
  STANDARDIZE_METADATA_VALUE,
  BASIC_DATA_CONNECTOR,
  TABULAR_VEEVA,
};
