import {
  SELECT_DATA_PACKAGE,
  ADD_PACKAGE_BTN,
  ADD_PACKAGE_SUCCESS,
  GET_PASSWORD_PACKAGE,
  PACKAGES_LIST,
  REDIRECT_TO_DATASET,
  UPDATE_DATA_PACKAGE,
  UPDATE_LEFT_PANEL,
  RESET_DATA_PACKAGE,
} from "../../constants";

// eslint-disable-next-line import/prefer-default-export
export const getPackagesList = (
  dfId,
  searchQuery = "",
  isDraft = false,
  isRedirectionLoading = false
) => {
  return {
    type: PACKAGES_LIST,
    dfId,
    searchQuery,
    isDraft,
    isRedirectionLoading,
  };
};
// eslint-disable-next-line import/prefer-default-export
export const addPackageBtnAction = () => {
  return {
    type: ADD_PACKAGE_BTN,
    openAddPackage: true,
  };
};

export const addDataPackage = (clearPackage = true) => {
  return {
    type: ADD_PACKAGE_SUCCESS,
    refreshData: true,
    clearPackage,
  };
};
export const selectDataPackage = (packageResult) => {
  return {
    type: SELECT_DATA_PACKAGE,
    refreshData: true,
    packageResult,
  };
};

export const getPackagePassword = (val, id) => {
  return {
    type: GET_PASSWORD_PACKAGE,
    val,
    id,
  };
};

export const updateStatus = (payload) => {
  return {
    type: UPDATE_DATA_PACKAGE,
    payload,
  };
};
export const deletePackage = (payload) => {
  return {
    type: UPDATE_DATA_PACKAGE,
    update_action: "DELETE",
    payload: { ...payload, delete_package: true },
  };
};

export const redirectToDataSet = (
  dfId,
  dfName,
  dpId,
  dpName,
  dsId,
  dsName,
  fromWhere = null,
  path = null
) => {
  return {
    type: REDIRECT_TO_DATASET,
    dfId,
    dfName,
    dpId,
    dpName,
    dsId,
    dsName,
    fromWhere,
    path,
  };
};

export const updatePanel = () => {
  return {
    type: UPDATE_LEFT_PANEL,
  };
};

export const resetDataPackageStore = () => {
  return {
    type: RESET_DATA_PACKAGE,
  };
};
