// libraries
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// apollo components
import ApolloProgress from "apollo-react/components/ApolloProgress";
import Box from "apollo-react/components/Box";
import Button from "apollo-react/components/Button";
import Divider from "apollo-react/components/Divider";
import EllipsisVertical from "apollo-react-icons/EllipsisVertical";
import IconMenuButton from "apollo-react/components/IconMenuButton";
import PlusIcon from "apollo-react-icons/Plus";
import Search from "apollo-react/components/Search";
import Tag from "apollo-react/components/Tag";
import Tooltip from "apollo-react/components/Tooltip";
import Typography from "apollo-react/components/Typography";
import { BASIC_DATA_CONNECTOR_DA } from "../../../pages/DataPackages/DataPackagesHelper";
// components
import { ReactComponent as DataFlowIcon } from "../../Icons/dataflow.svg";
import PackagesList from "../../../pages/DataPackages/PackagesTable";
// helpers
import {
  Categories,
  Features,
  useStudyPermission,
} from "../../Common/usePermission";
import {
  addPackageBtnAction,
  getPackagesList,
} from "../../../store/actions/DataPackageAction";
import {
  debounceFunction,
  getUserInfo,
  leftPanelUseStyles,
} from "../../../utils";
// styles
import "./LeftPanel.scss";

// messages
const dataPackagePath = "/dashboard/data-packages";

const LeftPanel = ({ resetForm }) => {
  const classes = leftPanelUseStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getUserInfo();
  const location = useLocation();

  // local state
  const [searchTxt, setSearchTxt] = useState("");
  const [showAddBtn, setShowAddBtn] = useState(false);

  const packageData = useSelector((state) => state.dataPackage);
  const dataFlow = useSelector((state) => state.dataFlow);
  const dashboard = useSelector((state) => state.dashboard);

  const { dataFlowdetail, isPublishedFlow } = dataFlow;
  const { description, dataflowid, vendorname, name, testflag } =
    dataFlowdetail;
  const { loading, packagesList, refreshData } = packageData;
  const { prot_id: protId } = dashboard?.selectedCard;

  const { canUpdate: canUpdateDataFlow } = useStudyPermission(
    Categories.CONFIGURATION,
    Features.DATA_FLOW_CONFIGURATION,
    protId
  );

  const { canEnabled: canDeleteTest } = useStudyPermission(
    Categories.MENU,
    Features.DATA_FLOW_HARD_DELETE_TEST,
    protId
  );

  const { canEnabled: canDeleteProd } = useStudyPermission(
    Categories.MENU,
    Features.DATA_FLOW_HARD_DELETE_PROD,
    protId
  );

  const viewAuditLog = () => {
    history.push(`/dashboard/audit-logs/${dataflowid}`);
  };

  const getPackages = (query = "") => {
    if (dataflowid) {
      dispatch(getPackagesList(dataflowid, query, !isPublishedFlow));
    }
  };

  const searchTrigger = (e) => {
    const newValue = e.target.value;
    setSearchTxt(newValue);
    if (!newValue) {
      getPackages();
    } else {
      debounceFunction(async () => {
        await getPackages(newValue);
      }, 1000);
    }
  };

  const redirectDataPackage = () => {
    resetForm?.();
    if (location.pathname === dataPackagePath) {
      dispatch(addPackageBtnAction());
    } else {
      history.push(dataPackagePath);
    }
  };

  const redirectDataflow = () => {
    resetForm?.();
    if (
      location.pathname.startsWith("/dashboard/dataset") ||
      location.pathname.startsWith(dataPackagePath)
    ) {
      history.push(`/dashboard/dataflow-management/${dataflowid}`);
    }
  };

  const menuItems = [
    {
      text: "View audit log",
      disabled: !canUpdateDataFlow,
      onClick: viewAuditLog,
    },
    {
      text: "Hard delete data flow",
      disabled: testflag ? !canDeleteTest : !canDeleteProd,
    },
  ];

  useEffect(() => {
    getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataflowid, refreshData]);

  useEffect(() => {
    // logic to display add package button
    if (
      !["TabularRaveSOD", "TabularVeeva"].includes(dataFlowdetail?.type) &&
      !isPublishedFlow
    ) {
      setShowAddBtn(true);
    } else if (
      ["TabularRaveSOD", "TabularVeeva"].includes(dataFlowdetail?.type) &&
      +dataFlowdetail?.dataPackageCount === 0 &&
      !isPublishedFlow
    ) {
      setShowAddBtn(true);
    } else {
      setShowAddBtn(false);
    }
  }, [dataFlowdetail, isPublishedFlow]);

  const ContextMenu = () => {
    return (
      <>
        <Tooltip title="Actions" disableFocusListener>
          <IconMenuButton id="actions" menuItems={menuItems} size="small">
            <EllipsisVertical />
          </IconMenuButton>
        </Tooltip>
      </>
    );
  };
  return (
    <div className="leftPanel">
      <div className={classes.drawerHeader}>
        <div className={classes.leftPanel}>
          <DataFlowIcon className={classes.dataflowLeft} />
          <Typography style={{ marginLeft: 7, color: "#595959" }}>
            Data Flow
          </Typography>
        </div>
        {!!isPublishedFlow && (
          <div className="right-panel top-status-checkbox">
            <ContextMenu />
          </div>
        )}
      </div>
      <Divider />
      <Box className="sidebar-content">
        <Tag
          label={testflag === 1 ? "Test" : "Production"}
          variant="grey"
          style={{ textTransform: "capitalize", marginBottom: 20 }}
        />
        <Typography className={classes.LeftTitle}>{name}</Typography>
        <Typography className={classes.LeftSubTitle}>{vendorname}</Typography>
        <Typography className={classes.description}>
          {/* <ArrowRight className={classes.icon} /> */}
          {description}
        </Typography>
        <Button
          variant="primary"
          style={{ marginTop: 17 }}
          fullWidth
          size="small"
          onClick={redirectDataflow}
        >
          View settings
        </Button>
      </Box>

      <Divider />
      {/* Simple */}
      {dataFlowdetail?.type === BASIC_DATA_CONNECTOR_DA && (
        <div className="packages-list-header">
          <div className="flex flex-center justify-between">
            <Typography className="b-font">Input Configuration</Typography>
            {!!showAddBtn && (
              <Button
                variant="secondary"
                icon={<PlusIcon />}
                size="small"
                onClick={redirectDataPackage}
                disabled={!canUpdateDataFlow}
              >
                Add Input config
              </Button>
            )}
          </div>

          <div style={{ maxWidth: 400 }}>
            <Search
              placeholder="Search"
              size="small"
              value={searchTxt}
              onChange={searchTrigger}
              fullWidth
            />
          </div>
        </div>
      )}

      {dataFlowdetail?.type !== BASIC_DATA_CONNECTOR_DA && (
        <div className="packages-list-header">
          <div className="flex flex-center justify-between">
            <Typography className="b-font">Data Packages & Datasets</Typography>
            {!!showAddBtn && (
              <Button
                variant="secondary"
                icon={<PlusIcon />}
                size="small"
                onClick={redirectDataPackage}
                disabled={!canUpdateDataFlow}
              >
                Add data package
              </Button>
            )}
          </div>

          <div style={{ maxWidth: 400 }}>
            <Search
              placeholder="Search"
              size="small"
              value={searchTxt}
              onChange={searchTrigger}
              fullWidth
            />
          </div>
        </div>
      )}
      <div className="packages-list customScrollbar">
        {packageData ? (
          <div className="list-container">
            {loading && (
              <Box display="flex" className="loader-container">
                <ApolloProgress />
              </Box>
            )}
            {!loading && (
              <>
                <Typography variant="body2" style={{ marginLeft: 10 }}>
                  {packagesList.length}
                  {packagesList.length > 1 ? " Data Packages" : " Data Package"}
                </Typography>
                <PackagesList userInfo={userInfo} data={packageData} />
              </>
            )}
          </div>
        ) : (
          <div className="flex no-result">
            <img src="/assets/svg/datapackage.svg" alt="datapackage" />
            <Typography>No Data Package or Datasets Added</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftPanel;
