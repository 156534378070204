import { extractHostname, generatedBName, isSftp } from "../../utils";
import { encrypt } from "../../utils/encryptionHelper";

// constants for location - data types
const TABULAR_DT = "Tabular";
const TABULAR_RAVE_DT_VALUE = "TabularRaveSOD";
const CLINICAL_ODM_DT_VALUE = "ClinicalOneODM";
const INFORM_ODM_DT_VALUE = "InformODM";
const RAVE_ODM_DT_VALUE = "RaveODM";

// check if data structure is ODM
export const checkForODMAdapter = (adapter) => {
  if (
    adapter === CLINICAL_ODM_DT_VALUE ||
    adapter === INFORM_ODM_DT_VALUE ||
    adapter === RAVE_ODM_DT_VALUE
  )
    return true;
  return false;
};

// check if data structure is Rave ODM
export const checkForRaveAdapter = (adapter) => {
  if (adapter === RAVE_ODM_DT_VALUE) return true;
  return false;
};

// get object key from lov object of data structure passed
export const getLocationConfigKey = (dataStructureProp, locationLOV) => {
  let lovObjectKey = null;
  // loop through config and get adapter
  Object.keys(locationLOV).forEach((key) => {
    if (
      locationLOV[key]?.value?.toString?.()?.toLowerCase?.() ===
      dataStructureProp?.toString?.()?.toLowerCase?.()
    ) {
      lovObjectKey = key;
    }
  });
  return lovObjectKey;
};

// get ODM version from LOV
export const getODMVersion = (dataStructureProp, locationLOV) => {
  try {
    const odmVersion = [];
    const lovKey = getLocationConfigKey(dataStructureProp, locationLOV);
    locationLOV?.[lovKey]?.adapterversion?.forEach(
      (item) => item && odmVersion.push({ label: item, value: item })
    );
    return odmVersion;
  } catch (e) {
    return [];
  }
};

// get ODM Auth type from LOV
export const getODMAuthenticationType = (dataStructureProp, locationLOV) => {
  try {
    const odmAuthType = [];
    const lovKey = getLocationConfigKey(dataStructureProp, locationLOV);
    locationLOV?.[lovKey]?.authenticationtype?.forEach(
      (item) => item && odmAuthType.push({ label: item, value: item })
    );
    return odmAuthType;
  } catch (e) {
    return [];
  }
};

// check if version fields needs to be validated
export const shouldValidateODMVersion = (key) => {
  if (key === CLINICAL_ODM_DT_VALUE) return false;
  return true;
};

// check if auth type is basic
export const checkBasicAuthType = (authType) => {
  if (["basic auth"]?.includes(authType?.toString?.()?.toLowerCase?.()))
    return true;
  return false;
};

export const authTypeField = (authType) => {
  if (authType) {
    if (checkBasicAuthType(authType)) return true;
    return false;
  }
  return true;
};

export const DEFAULT_LOCATION_FIELDS = {
  locationName: true,
  active: true,
  externalSystemName: true,
  dataStructure: true,
  locationType: true,
  ownerGroup: true,
  sharedGroup: true,
  encryptionType: false,
  ipServer: true,
  url: false,
  odmVersion: false,
  odmAuthType: false,
  port: false,
  dbName: false,
  whse: false,
  schem: false,
  userName: true,
  password: true,
  connURL: true,
  testConnectionBtn: true,
};

export const setLocationFields = (
  showFields,
  setShowFields,
  dataStructure,
  locType,
  isJDBC = false,
  authType
) => {
  // case 1: tabular locations
  if (dataStructure === TABULAR_DT || dataStructure === TABULAR_RAVE_DT_VALUE) {
    // SFTP, FTPS, S3 location types
    if (!isJDBC) {
      setShowFields({
        locationName: true,
        active: true,
        externalSystemName: true,
        dataStructure: true,
        locationType: true,
        ownerGroup: true,
        sharedGroup: true,
        encryptionType: locType === "FTPS" ? true : false,
        ipServer: true,
        url: false,
        odmVersion: false,
        odmAuthType: false,
        port: false,
        dbName: false,
        whse: false,
        schem: false,
        userName: true,
        password: true,
        connURL: true,
        testConnectionBtn: true,
      });
    }
    // JDBC Flows
    if (isJDBC) {
      setShowFields({
        locationName: true,
        active: true,
        externalSystemName: true,
        dataStructure: true,
        locationType: true,
        ownerGroup: true,
        sharedGroup: true,
        encryptionType: false,
        ipServer: true,
        url: false,
        odmVersion: false,
        odmAuthType: false,
        port: true,
        dbName: true,
        whse: locType === "Azure – Snowflake" ? true : false,
        schem: locType === "Azure – Snowflake" ? true : false,
        userName: true,
        password: true,
        connURL: true,
        testConnectionBtn: true,
      });
    }
  }

  // case 2: ODM Adapter
  else if (
    dataStructure === CLINICAL_ODM_DT_VALUE ||
    dataStructure === INFORM_ODM_DT_VALUE ||
    dataStructure === RAVE_ODM_DT_VALUE
  ) {
    setShowFields({
      locationName: true,
      active: true,
      externalSystemName: true,
      dataStructure: true,
      locationType: false,
      ownerGroup: true,
      sharedGroup: true,
      encryptionType: false,
      ipServer: false,
      url: true,
      odmVersion: dataStructure === CLINICAL_ODM_DT_VALUE ? false : true,
      odmAuthType: true,
      port: false,
      dbName: false,
      whse: false,
      schem: false,
      userName: authTypeField(authType),
      password: authTypeField(authType),
      connURL: false,
      testConnectionBtn: false,
    });
  }
};

// clear redux form fields on data structure changes
export const clearLocationFields = (
  dispatch,
  change,
  dataStructureProp,
  showFields
) => {
  const isODMAdapter = checkForODMAdapter(dataStructureProp);

  // if none odm adapter, clear odm fields
  if (!isODMAdapter) {
    dispatch(change("AddLocationForm", "odmVersion", ""));
    dispatch(change("AddLocationForm", "odmAuthType", ""));
    dispatch(change("AddLocationForm", "url", ""));
  }

  // clear odm version when clinical one odm is changed
  if (!shouldValidateODMVersion(dataStructureProp)) {
    dispatch(change("AddLocationForm", "odmVersion", ""));
  }
  // clear user name & password field when auth type is changed
  if (isODMAdapter && !showFields?.userName && !showFields?.password) {
    dispatch(change("AddLocationForm", "userName", ""));
    dispatch(change("AddLocationForm", "password", ""));
  }
};

// generate body used in location
export const generateLocationBody = ({ values, sharedGroupName }) => {
  // encrypt password before sending
  let encryptPassword = "";
  if (values.password) {
    encryptPassword = encrypt(values.password);
  }

  const numberActive = values.active === 0 ? false : true;
  const active =
    typeof values.active === "boolean" ? values.active : numberActive;
  const encryptionTypeReq =
    values?.locationType === "FTPS" ? values?.encryptionType : null;

  let reqBody = {
    ...values,
    sharedGroupName,
    password: encryptPassword,
    encryptionType: encryptionTypeReq,
    active,
    systemName: "CDI",
  };

  // ODM Request Body
  if (checkForODMAdapter(values?.dataStructure)) {
    reqBody = {
      locationID: values?.locationID || null,
      locationName: values?.locationName || null,
      externalSystemName: values?.externalSystemName || null,
      dataStructure: values?.dataStructure || null,
      odmVersion: values?.odmVersion || null,
      ipServer: values?.url || null,
      authType: values?.odmAuthType || null,
      userName: values?.userName || null,
      password: encryptPassword,
      encryptionType: encryptionTypeReq,
      active,
      systemName: "CDI",
      ownerGroupName: values.ownerGroupName,
      sharedGroupName,
    };
  }

  return reqBody;
};

// generate body used in test connection
export const generateTestConnectionBody = ({
  dbName,
  ipServer,
  locationPassword,
  locationPPK,
  locationType,
  password,
  port,
  userName,
  whse,
  connectionURL = "",
}) => {
  let reqBody = {
    username: userName || "",
    password:
      password === "Yes" || typeof password === "object"
        ? locationPassword
        : password || "",
    endPoint: "/checkconnection/sftp",
  };

  if (isSftp(locationType) && (password === "No" || !password) && locationPPK) {
    reqBody = {
      username: userName || "",
      ppk: locationPPK,
      endPoint: "/checkconnection/sftp",
    };
  }

  if (locationType) {
    if (!isSftp(locationType)) {
      // let dbPort;
      // if (locationType.includes("Dynamic Port")) {
      //   dbPort = 1433;
      // } else {
      //   dbPort = port ? port : "";
      // }
      /* Don't remove this commented code is using for portless story CDAS-11093 */

      reqBody = {
        ...reqBody,
        endPoint: "/checkconnection/jdbc",
        host: ipServer || "",
        databaseName: dbName || "",
        userId: "",
        database: generatedBName(locationType),
        port: port ? port : "",
        connectionURL,
      };
    } else if (locationType === "Amazon S3") {
      reqBody = {
        endPoint: "AmazonS3",
        accessKeyId: userName || "",
        secretAccessKey: password || "",
        bucket: ipServer || "",
        region: whse || "",
      };
    } else {
      reqBody = {
        ...reqBody,
        connectionURL,
        host: extractHostname(ipServer) || "",
      };
    }
  }

  return reqBody;
};
