// libraries
import React from "react";
import { makeStyles } from "@mui/styles";
// apollo components
import * as colors from "apollo-react/colors";
import Button from "apollo-react/components/Button";
import InfoIcon from "apollo-react-icons/Info";
import Paper from "apollo-react/components/Paper";
import Typography from "apollo-react/components/Typography";
// styles
import "./InfoCard.scss";

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: "240px",
    width: "100%",
    height: "175px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "inherit",
  },
  mr4: {
    marginRight: "4px",
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignSelf: "normal",
    minHeight: "48px",
  },
  mt2: {
    marginTop: "2px",
  },
  details: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "8px",
  },
  bold: {
    fontWeight: 600,
  },
}));

const InfoCard = ({
  title,
  subtitle,
  value,
  icon: Icon,
  color,
  handlePeekOpen,
  closePeek,
  handleViewClick,
  placement = "top",
  showBorder = false,
}) => {
  const classes = useStyles();

  const ViewInfoContent = () => (
    <>
      <div className={classes.details}>
        <Icon fontSize="large" style={{ color: colors[color] }} />
        <Typography variant="h2">{value || 0}</Typography>
      </div>
      <Button onClick={handleViewClick} size="small">
        View
      </Button>
    </>
  );

  const TitleContent = () => (
    <>
      <Typography className={`${classes.bold} ${classes.mr4}`}>
        {title}
      </Typography>
    </>
  );

  if (showBorder) {
    return (
      <div className="critical-box">
        <div className="critical-text">
          <p>Critical</p>
        </div>
        <div className="critical-paper">
          <Paper className={`${classes.card} critical-card`}>
            <div className={classes.label}>
              <TitleContent />
              <InfoIcon
                fontSize="small"
                style={{ color: colors.neutral7 }}
                onMouseOver={() => handlePeekOpen(title, subtitle, placement)}
                onMouseOut={closePeek}
              />
            </div>

            <ViewInfoContent />
          </Paper>
        </div>
      </div>
    );
  }

  return (
    <Paper className={classes.card}>
      <div className={classes.label}>
        <TitleContent />
        <InfoIcon
          fontSize="small"
          style={{ color: colors.neutral7 }}
          onMouseOver={() => handlePeekOpen(title, subtitle, placement)}
          onMouseOut={closePeek}
        />
      </div>

      <ViewInfoContent />
    </Paper>
  );
};

export default InfoCard;
