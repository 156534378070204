export const Success = "success";
export const Warning = "warning";
export const Info = "info";
export const Err = "error";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SET_EXPANDED = "SET_EXPANDED";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

export const SHOW_ALERT_TRUE = "SHOW_ALERT_TRUE";
export const SHOW_ALERT_FALSE = "SHOW_ALERT_FALSE";
export const FORM_COMPONENT_ACTIVE = "FORM_COMPONENT_ACTIVE";
export const FORM_COMPONENT_IN_ACTIVE = "FORM_COMPONENT_IN_ACTIVE";
export const SHOW_APP_SWITCHER = "SHOW_APP_SWITCHER";
export const HIDE_APP_SWITCHER = "HIDE_APP_SWITCHER";

export const SELECTED_DATAFLOW = "SELECTED_DATAFLOW";
export const CLEAR_SELECTED_DATAFLOW = "CLEAR_SELECTED_DATAFLOW";
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const DASHBOARD_FETCH_SUCCESS = "DASHBOARD_FETCH_SUCCESS";
export const DASHBOARD_FETCH_FAILUR = "DASHBOARD_FETCH_FAILUR";

export const DASHBOARD_STUDIES = "DASHBOARD_STUDIES";
export const FETCH_DASHBOARD_STUDY_SUCCESS = "FETCH_DASHBOARD_STUDY_SUCCESS";
export const FETCH_DASHBOARD_STUDY_FAILUR = "FETCH_DASHBOARD_STUDY_FAILUR";

export const PAGEHEADER_UPDATE = "PAGEHEADER_UPDATE";
export const UPDATE_DS = "UPDATE_DS";
export const RESET_FTP_FORM = "RESET_FTP_FORM";
export const RESET_JDBC_FORM = "RESET_JDBC_FORM";

export const GET_DATA_FLOW_LIST = "GET_DATA_FLOW_LIST";
export const GET_DATA_FLOW_LIST_SUCCESS = "GET_DATA_FLOW_LIST_SUCCESS";
export const GET_DATA_FLOW_LIST_FAILURE = "GET_DATA_FLOW_LIST_FAILURE";

export const GET_DATA_FLOW_LIST_INTERVAL = "GET_DATA_FLOW_LIST_INTERVAL";
export const GET_DATA_FLOW_LIST_INTERVAL_SUCCESS =
  "GET_DATA_FLOW_LIST_INTERVAL_SUCCESS";
export const GET_DATA_FLOW_LIST_INTERVAL_FAILURE =
  "GET_DATA_FLOW_LIST_INTERVAL_FAILURE";

export const GET_STUDIES_LIST = "GET_STUDIES_LIST";
export const GET_STUDIES_LIST_SUCCESS = "GET_STUDIES_LIST_SUCCESS";
export const GET_STUDIES_LIST_FAILURE = "GET_STUDIES_LIST_FAILURE";

export const GET_PINNED_LIST = "GET_PINNED_LIST";
export const GET_PINNED_LIST_SUCCESS = "GET_PINNED_LIST_SUCCESS";
export const GET_PINNED_LIST_FAILURE = "GET_PINNED_LIST_FAILURE";

export const ADD_DATAFLOW = "ADD_DATAFLOW";
export const ADD_DATAFLOW_SUCCESS = "ADD_DATAFLOW_SUCCESS";
export const ADD_DATAFLOW_FAILURE = "ADD_DATAFLOW_FAILURE";

export const DATAFLOWS_LIST_FAILURE = "DATAFLOWS_LIST_FAILURE";
export const UPDATE_HEADER_COUNT = "UPDATE_HEADER_COUNT";
export const SET_MONITOR_TITLE = "SET_MONITOR_TITLE";
export const SET_ALL_MONITOR_TITLE = "SET_ALL_MONITOR_TITLE";
export const SET_MONITOR_SHOW_SUMMARY = "SET_MONITOR_SHOW_SUMMARY";
export const UPDATE_DF_STATUS = "UPDATE_DF_STATUS";
export const FETCH_DATAFLOW_CONFIG = "FETCH_DATAFLOW_CONFIG";
export const FETCH_DATAFLOW_CONFIG_SUCCESS = "FETCH_DATAFLOW_CONFIG_SUCCESS";
export const TRIGGER_DATAFLOW_DETAILS_PULL = "TRIGGER_DATAFLOW_DETAILS_PULL";

export const UPDATE_PREVIOUS_STATE_ACTIVE_ONLY_BTN =
  "UPDATE_PREVIOUS_STATE_ACTIVE_ONLY_BTN";
export const UPDATE_PREVIOUS_SEGMENT_CONTROL =
  "UPDATE_PREVIOUS_SEGMENT_CONTROL";
export const UPDATE_PREVIOUS_PIPELINE_TYPE = "UPDATE_PREVIOUS_PIPELINE_TYPE";

// constants for pipeline - data types
export const TABULAR_PIPELINE = "Tabular";

export const PACKAGES_LIST = "PACKAGES_LIST";
export const ADD_PACKAGE_BTN = "ADD_PACKAGE_BTN";
export const PACKAGES_LIST_SUCCESS = "PACKAGES_LIST_SUCCESS";
export const PACKAGES_LIST_FAILURE = "PACKAGES_LIST_FAILURE";

export const ADD_DATA_PACKAGE = "ADD_DATA_PACKAGE";
export const SELECT_DATA_PACKAGE = "SELECT_DATA_PACKAGE";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAILURE = "ADD_PACKAGE_FAILURE";
export const EDIT_DATA_PACKAGE = "EDIT_DATA_PACKAGE";
export const RESET_DATA_PACKAGE = "RESET_DATA_PACKAGE";

export const UPDATE_DATA_PACKAGE = "UPDATE_DATA_PACKAGE";
export const UPDATE_DATA_PACKAGE_SUCCESS = "UPDATE_DATA_PACKAGE_SUCCESS";
export const UPDATE_DATA_PACKAGE_FAILURE = "UPDATE_DATA_PACKAGE_FAILURE";

export const GET_LOCATIONS_DATA = "GET_LOCATIONS_DATA";
export const UPDATE_LEFT_PANEL = "UPDATE_LEFT_PANEL";
export const UPDATE_DS_STATUS = "UPDATE_DS_STATUS";

export const GET_DATA_KIND = "GET_DATA_KIND";
export const FETCH_DATAKIND_SUCCESS = "FETCH_DATAKIND_SUCCESS";
export const FETCH_DATAKIND_FAILURE = "FETCH_DATAKIND_FAILURE";

export const GET_LOCATIONS_ADMIN = "GET_LOCATIONS_ADMIN";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_FAILURE = "FETCH_LOCATION_FAILURE";

export const GET_LOCATION_DETAIL = "GET_LOCATION_DETAIL";
export const FETCH_LOCATION_DETAIL_SUCCESS = "FETCH_LOCATION_DETAIL_SUCCESS";
export const FETCH_LOCATION_DETAIL_FAILURE = "FETCH_LOCATION_DETAIL_FAILURE";

export const GET_VENDORS_DATA = "GET_VENDORS_DATA";
export const FETCH_VENDOR_SUCCESS = "FETCH_VENDOR_SUCCESS";
export const FETCH_VENDOR_FAILURE = "FETCH_VENDOR_FAILURE";

export const UPDATE_SELECTED_LOCATION = "UPDATE_SELECTED_LOCATION";
export const UPDATE_FORM_FIELDS = "UPDATE_FORM_FIELDS";
export const SAVE_LOCATION_DATA = "SAVE_LOCATION_DATA";
export const UPDATE_LOCATION_DATA = "UPDATE_LOCATION_DATA";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILURE = "UPDATE_LOCATION_FAILURE";

export const GET_SERVICE_OWNERS = "GET_SERVICE_OWNERS";
export const FETCH_SERVICE_OWNERS_SUCCESS = "FETCH_SERVICE_OWNERS_SUCCESS";
export const FETCH_SERVICE_OWNERS_FAILURE = "FETCH_SERVICE_OWNERS_FAILURE";

export const GET_PASSWORD_LOCATION = "GET_PASSWORD_LOCATION";
export const RESET_PASSWORD_LOCATION = "RESET_PASSWORD_LOCATION";
export const FETCH_LOCATION_PASSWORD_SUCCESS =
  "FETCH_LOCATION_PASSWORD_SUCCESS";
export const FETCH_LOCATION_PASSWORD_FAILURE =
  "FETCH_LOCATION_PASSWORD_FAILURE";

export const GET_PASSWORD_PACKAGE = "GET_PASSWORD_PACKAGE";
export const FETCH_PACKAGE_PASSWORD_SUCCESS = "FETCH_PACKAGE_PASSWORD_SUCCESS";
export const FETCH_PACKAGE_PASSWORD_FAILURE = "FETCH_PACKAGE_PASSWORD_FAILURE";

export const GET_DATAFLOW_DETAIL = "GET_DATAFLOW_DETAIL";
export const RESET_DF_FORMDATA = "RESET_DF_FORMDATA";
export const FETCH_DATAFLOW_DETAIL_SUCCESS = "FETCH_DATAFLOW_DETAIL_SUCCESS";
export const FETCH_DATAFLOW_DETAIL_FAILURE = "FETCH_DATAFLOW_DETAIL_FAILURE";
export const FETCH_DATAFLOW_LOCAL_DETAIL = "FETCH_DATAFLOW_LOCAL_DETAIL";
export const SAVE_DATAFLOW_LOCAL_DETAIL = "SAVE_DATAFLOW_LOCAL_DETAIL";
export const SET_VERSION_FREEZED = "SET_VERSION_FREEZED";
export const RESET_DATAFLOW_DETAILS = "RESET_DATAFLOW_DETAILS";
export const SET_PUBLISH_FLOW = "SET_PUBLISH_FLOW";
export const SET_DRAFT_EDIT_MODE = "SET_DRAFT_EDIT_MODE";
export const SET_FROM_CREATE_DRAFT = "SET_FROM_CREATE_DRAFT";

export const GET_DATASET_DETAIL = "GET_DATASET_DETAIL";
export const FETCH_DATASET_DETAIL_SUCCESS = "FETCH_DATASET_DETAIL_SUCCESS";
export const FETCH_DATASET_DETAIL_FAILURE = "FETCH_DATASET_DETAIL_FAILURE";

export const GET_LOCATION_BY_TYPE = "FETCH_SERVICE_OWNERS_FAILURE";
export const STORE_LOCATION_SUCCESS = "STORE_LOCATION_SUCCESS";
export const STORE_LOCATION_FAILURE = "STORE_LOCATION_FAILURE";

export const HIDE_ERROR_MSG = "HIDE_ERROR_MSG";

export const AUDIT_LOGS = "AUDIT_LOGS";
export const AUDIT_LOGS_SUCCESS = "AUDIT_LOGS_SUCCESS";
export const AUDIT_LOGS_FAILURE = "AUDIT_LOGS_FAILURE";

export const REDIRECT_TO_DATASET = "REDIRECT_TO_DATASET";
export const SAVE_DATASET_DATA = "SAVE_DATASET_DATA";
export const UPDATE_DATASET_DATA = "UPDATE_DATASET_DATA";
export const STORE_DATASET_SUCCESS = "STORE_DATASET_SUCCESS";
export const STORE_DATASET_FAILURE = "STORE_DATASET_FAILURE";

export const SAVE_DATASET_COLUMNS = "SAVE_DATASET_COLUMNS";
export const STORE_DATASET_COLUMNS_SUCCESS = "STORE_DATASET_COLUMNS_SUCCESS";
export const STORE_DATASET_COLUMNS_FAILURE = "STORE_DATASET_COLUMNS_FAILURE";

export const UPDATE_DATASET_SUCCESS = "UPDATE_DATASET_SUCCESS";
export const UPDATE_DATASET_FAILURE = "UPDATE_DATASET_FAILURE";

export const SAVE_DATASET_COLUMNS_COUNT = "SAVE_DATASET_COLUMNS_COUNT";
export const TOGGLE_DATASET_PREVIWED_SQL = "TOGGLE_DATASET_PREVIWED_SQL";

export const UPDATE_COLUMNS_DATA = "UPDATE_COLUMNS_DATA";
export const UPDATE_COLUMNS_SUCCESS = "UPDATE_COLUMNS_SUCCESS";
export const UPDATE_COLUMNS_FAILURE = "UPDATE_COLUMNS_FAILURE";
export const UPDATE_COLUMNS_DATA_LOADING = "UPDATE_COLUMNS_DATA_LOADING";

export const GET_DATASET_COLUMNS = "GET_DATASET_COLUMNS";
export const FETCH_DATASET_COLUMNS_SUCCESS = "FETCH_DATASET_COLUMNS_SUCCESS";
export const FETCH_DATASET_COLUMNS_FAILURE = "FETCH_DATASET_COLUMNS_FAILURE";

export const GET_VLC_RULES = "GET_VLC_RULES";
export const FETCH_VLC_RULES_SUCCESS = "FETCH_VLC_RULES_SUCCESS";
export const FETCH_VLC_RULES_FAILURE = "FETCH_VLC_RULES_FAILURE";

export const GET_CDT_LIST = "GET_CDT_LIST";
export const FETCH_CDT_LIST_SUCCESS = "FETCH_CDT_LIST_SUCCESS";
export const FETCH_CDT_LIST_FAILURE = "FETCH_CDT_LIST_FAILURE";

export const GET_SQL_TABLES = "GET_SQL_TABLES";
export const FETCH_SQL_TABLES_SUCCESS = "FETCH_SQL_TABLES_SUCCESS";
export const FETCH_SQL_TABLES_FAILURE = "FETCH_SQL_TABLES_FAILURE";

export const GET_SQL_COLUMNS = "GET_SQL_COLUMNS";
export const FETCH_SQL_COLUMNS_SUCCESS = "FETCH_SQL_COLUMNS_SUCCESS";
export const FETCH_SQL_COLUMNS_FAILURE = "FETCH_SQL_COLUMNS_FAILURE";

export const GET_PREVIEW_SQL = "GET_PREVIEW_SQL";
export const FETCH_PREVIEW_SQL_SUCCESS = "FETCH_PREVIEW_SQL_SUCCESS";
export const FETCH_PREVIEW_SQL_FAILURE = "FETCH_PREVIEW_SQL_FAILURE";

export const GET_ALL_INGESTION_SUMMARY = "GET_ALL_INGESTION_SUMMARY";
export const GET_ALL_INGESTION_SUMMARY_SUCCESS =
  "GET_ALL_INGESTION_SUMMARY_SUCCESS";
export const GET_ALL_INGESTION_SUMMARY_FAILURE =
  "GET_ALL_INGESTION_SUMMARY_FAILURE";

export const GET_SPECIFIC_INGESTION_DATASETS =
  "GET_SPECIFIC_INGESTION_DATASETS";
export const GET_SPECIFIC_INGESTION_DATASETS_SUCCESS =
  "GET_SPECIFIC_INGESTION_DATASETS_SUCCESS";
export const GET_SPECIFIC_INGESTION_DATASETS_FAILURE =
  "GET_SPECIFIC_INGESTION_DATASETS_FAILURE";

export const GET_DATASET_INGESTION_SUMMARY = "GET_DATASET_INGESTION_SUMMARY";
export const GET_DATASET_INGESTION_SUMMARY_SUCCESS =
  "GET_DATASET_INGESTION_SUMMARY_SUCCESS";
export const GET_DATASET_INGESTION_SUMMARY_FAILURE =
  "GET_DATASET_INGESTION_SUMMARY_FAILURE";

export const FETCH_SETTINGS_DATA = "FETCH_SETTINGS_DATA";
export const CREARE_SETTINGS_DATA = "CREARE_SETTINGS_DATA";
export const UPDATE_SETTINGS_DATA = "UPDATE_SETTINGS_DATA";
export const SEARCH_SETTINGS_DATA = "SEARCH_SETTINGS_DATA";

export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const CREARE_SETTINGS_SUCCESS = "CREARE_SETTINGS_SUCCESS";
export const CREARE_SETTINGS_FAILURE = "CREARE_SETTINGS_FAILURE";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";

export const GET_TRANSFER_LOG = "GET_TRANSFER_LOG";
export const FETCH_TRANSFER_LOG_SUCCESS = "FETCH_TRANSFER_LOG_SUCCESS";
export const FETCH_TRANSFER_LOG_FAILURE = "FETCH_TRANSFER_LOG_FAILURE";

export const GET_DATASET_PROPERTIES = "GET_DATASET_PROPERTIES";
export const FETCH_DATASET_PROPERTIES_SUCCESS =
  "FETCH_DATASET_PROPERTIES_SUCCESS";
export const FETCH_DATASET_PROPERTIES_FAILURE =
  "FETCH_DATASET_PROPERTIES_FAILURE";

export const GET_DATASET_INGESTION_ISSUE_TYPES =
  "GET_DATASET_INGESTION_ISSUE_TYPES";
export const FETCH_DATASET_INGESTION_ISSUE_TYPES_SUCCESS =
  "FETCH_DATASET_INGESTION_ISSUE_TYPES_SUCCESS";
export const FETCH_DATASET_INGESTION_ISSUE_TYPES_FAILURE =
  "FETCH_DATASET_INGESTION_ISSUE_TYPES_FAILURE";

export const GET_DATASET_INGESTION_FILE_HISTORY =
  "GET_DATASET_INGESTION_FILE_HISTORY";
export const FETCH_DATASET_INGESTION_FILE_HISTORY_SUCCESS =
  "FETCH_DATASET_INGESTION_FILE_HISTORY_SUCCESS";
export const FETCH_DATASET_INGESTION_FILE_HISTORY_FAILURE =
  "FETCH_DATASET_INGESTION_FILE_HISTORY_FAILURE";

export const GET_DATASET_INGESTION_TRANSFER_LOG =
  "GET_DATASET_INGESTION_TRANSFER_LOG";
export const FETCH_DATASET_INGESTION_TRANSFER_LOG_SUCCESS =
  "FETCH_DATASET_INGESTION_TRANSFER_LOG_SUCCESS";
export const RESET_DATASET_INGESTION_TRANSFER_LOG_HISTORY =
  "RESET_DATASET_INGESTION_TRANSFER_LOG_HISTORY";
export const FETCH_DATASET_INGESTION_TRANSFER_LOG_FAILURE =
  "FETCH_DATASET_INGESTION_TRANSFER_LOG_FAILURE";
export const SET_TRANSFER_LOG_VIEW_DETAILS = "SET_TRANSFER_LOG_VIEW_DETAILS";
export const CLEAR_TRANSFER_LOG_VIEW_DETAILS =
  "CLEAR_TRANSFER_LOG_VIEW_DETAILS";

// Manage VLC
export const RESET_MANAGE_VLC_STORE = "RESET_MANAGE_VLC_STORE";
export const SET_MANAGE_VLC_CURRENT_STEP = "SET_MANAGE_VLC_CURRENT_STEP";
export const GET_REJECT_VLC_DATA = "GET_REJECT_VLC_DATA";
export const GET_REJECT_VLC_DATA_SUCCESS = "GET_REJECT_VLC_DATA_SUCCESS";
export const GET_REJECT_VLC_DATA_FAILURE = "GET_REJECT_VLC_DATA_FAILURE";
export const UPDATE_REJECT_VLC_MODAL_STATUS = "UPDATE_REJECT_VLC_MODAL_STATUS";
export const SET_SELECTED_DATASET_IDS = "SET_SELECTED_DATASET_IDS";
export const SET_SELECTED_RULE_IDS = "SET_SELECTED_RULE_IDS";
export const SET_IS_RULES_CONFIRMED = "SET_IS_RULES_CONFIRMED";
export const SET_REFRESH_CONTENT = "SET_REFRESH_CONTENT";

// API URLS

export const GETSTUDYDF = "v1/api/savedataflow/studyDataflowList";
export const GET_STUDY_PERMISSION = "v1/api/dataflow/check-permission";
export const STUDYSEARCH = "v1/api/study/search-study";
export const STUDYAPI = "v1/api/study";
export const CDIHOMEAPI = "v1/api/cdihome";

export const HARDDELETE = "v1/api/dataflow/hard-delete";
export const SYNCNOW = "v1/api/dataflow/syncNow";
export const ACTIVATEDF = "v1/api/dataflow/activate";
export const INACTIVATE = "v1/api/dataflow/inActivate";
export const ACTIVATEDF_SDF = "v1/api/savedataflow/activate";
export const INACTIVATE_SDF = "v1/api/savedataflow/inActivate";
export const DATAFLOW_SAVE = "v1/api/dataflow/create";
export const DRAFTDATAFLOW_SAVE = "v1/api/savedataflow/saveDraftDataFlow";
export const ODMDRAFTDATAFLOW_SAVE = "v1/api/odm/saveDraftDataFlow";
export const DATAFLOW_UPDATE_API = "v1/api/dataflow/update-config";
export const DATAFLOW_DRAFT_UPDATE_API = "v1/api/savedataflow/update-config";
export const DATAFLOWSEARCH = "v1/api/dataflow/search-dataflow";
export const DATAFLOW_SOURCE = "v1/api/dataflow";
export const DATAFLOW_DETAILS = "v1/api/dataflow/details";
export const CREATE_DATAFLOW_DRAFT =
  "v1/api/savedataflow/create-dataflow-draft";
export const RESET_DRAFT_DATAFLOW = "v1/api/savedataflow/reset-draft-dataflow";
export const DELETE_DRAFT_DATAFLOW =
  "v1/api/savedataflow/delete-draft-dataflow";
export const CANCEL_DATAFLOW_PUBLISH =
  "v1/api/savedataflow/cancel-dataflow-publish";
export const TOGGLE_DF_BTN = "TOGGLE_DF_BTN";

export const PACKAGES_SEARCH = "v1/api/data-package/search";
export const PACKAGES_SEARCH_DRAFT = "v1/api/savedataflow/search";
export const ADD_PACKAGE = "v1/api/data-package/add";
export const ADD_PACKAGE_DRAFT = "v1/api/savedataflow/addDataPackage";
export const DATAPACKAGEPASSWORD = "v1/api/data-package/getpassword";
export const ADD_BDC_PACKAGE_DRAFT = "v1/api/bdc/savePackage";

export const DELETE_PACKAGE = "v1/api/data-package/delete";
export const DELETE_PACKAGE_DRAFT = "v1/api/savedataflow/deleteDataPackage";
export const UPDATE_PACKAGE = "v1/api/data-package/update-status";
export const UPDATE_PACKAGE_DRAFT = "v1/api/savedataflow/updateStatus";
export const NOT_ONBOARDED_FETCH = "v1/api/study/notonboarded-studies-stat";
export const STUDYLIST = "v1/api/study/listbyUser";
export const PINNEDSTUDY = "v1/api/study/pinnedStudies";
export const UNPINSTUDY = "v1/api/study/unPinStudy";
export const PINSTUDY = "v1/api/study/pinStudy";
export const AUDIT_LOGS_FETCH = "v1/api/audit-logs/get";

export const LOCATIONAPI = "v1/api/location";
export const LOCATIONPASSWORDAPI = "v1/api/location/get_password";
export const LISTTYPES = "v1/api/location/list-types";
export const AMAZON_REGIONS = "v1/api/location/amazon-regions";
export const LOCATION_LOV = "v1/api/location/get-list-of-values";
export const GET_LOCATION_GROUP_LIST = "v1/api/location/get-group-list";

export const VENDORAPI = "v1/api/vendor";
export const DATAKINDAPI = "v1/api/datakind";
export const DATASETAPI = "v1/api/dataset";
export const DRAFTDATASETAPI = "v1/api/dataset/draft";
export const COLUMNSAPI = "v1/api/columnset";
export const DRAFTCOLUMNSAPI = "v1/api/columnset/draft";
export const DATAFLOWAPI = "v1/api/dataflow";
export const DATAFLOW_DRAFT_API = "v1/api/savedataflow/draft";

export const VLCDATAAPI = "v1/api/dataset/getVLCData";
export const DRAFTVLCDATAAPI = "v1/api/dataset/draft/getVLCData";
export const SQLTABLESAPI = "v1/api/dataset/getTables";
export const SQLCOLUMNSAPI = "v1/api/dataset/getColumns";
export const PREVIEWSQLAPI = "v1/api/dataset/previewSQL";
export const SETTINGAPI = "v1/api/system-settings";
export const INGESTIONREPORTAPI = "v1/api/dataset/ingestion-report";
export const INGESTION_ISSUE_URL = "v1/api/dataset/ingestion-issues";
export const INGESTION_ISSUE_COL_URL =
  "v1/api/dataset/ingestion-issues-columns";
export const INGESTION_ISSUE_COL_DOWNLOAD_URL =
  "v1/api/dataset/ingestion-issues-columns-download";
export const SUMMARY_REPORT_URL = "v1/api/dataset/summary-report";
export const DATASETS_STATUS_TOGGLE =
  "v1/api/data-package/change-datasets-status";
export const DATASETS_STATUS_TOGGLE_DRAFT =
  "v1/api/savedataflow/changeDatasetsStatus";
export const FETCH_DATAFLOW_CONFIG_API = "v1/api/odm/config/datastructure";
export const INGESTION_ISSUE_DOWNLOADFILENAME_URL =
  "v1/api/dataset/getdownloadfileName";
export const baseURL = process.env.REACT_APP_API_URL;
export const API_URL = `${baseURL}/v1/api`;

export const allowedTypes = [
  "xlsx",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "csv",
];

export const hive2CDP = "com.cloudera.hive.jdbc41.HS2Driver";
export const hive2CDH = "com.cloudera.hive.jdbc41.HS2Driver";
export const oracle = "oracle.jdbc.driver.OracleDriver";
export const SQLServer = "com.microsoft.sqlserver.jdbc.SQLServerDriver";
export const impala = "com.cloudera.impala.jdbc41.Driver";
export const PostgreSQL = "org.postgresql.Driver";
export const locationExistInDFMsg =
  "IP Server or DB Hostname cannot be inactivated until removed from all data flows using this location.";

export const RESET_PUBLISH_STORE = "RESET_PUBLISH_STORE";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const GET_PUBLISH_DATAFLOW_DETAILS = "GET_PUBLISH_DATAFLOW_DETAILS";
export const GET_PUBLISH_DATAFLOW_DETAILS_SUCCESS =
  "GET_PUBLISH_DATAFLOW_DETAILS_SUCCESS";
export const GET_PUBLISH_DATAFLOW_DETAILS_FAIL =
  "GET_PUBLISH_DATAFLOW_DETAILS_FAIL";
export const UPDATE_VERSION_NUMBER = "UPDATE_VERSION_NUMBER";
export const UPDATE_VERSION_DESCRIPTION = "UPDATE_VERSION_DESCRIPTION";
export const UPDATE_VERSION_HISTORY_PANEL = "UPDATE_VERSION_HISTORY_PANEL";
export const PUBLISH_DRAFT_DATAFLOW = "PUBLISH_DRAFT_DATAFLOW";
export const PUBLISH_DRAFT_DATAFLOW_SUCCESS = "PUBLISH_DRAFT_DATAFLOW_SUCCESS";
export const PUBLISH_DRAFT_DATAFLOW_FAIL = "PUBLISH_DRAFT_DATAFLOW_FAIL";
export const DF_VERSION = "DF_VERSION";
export const DF_VERSION_SUCCESS = "DF_VERSION_SUCCESS";
export const DF_VERSION_FAIL = "DF_VERSION_FAIL";

export const GET_PACKAGE_DETAILS = "GET_PACKAGE_DETAILS";
export const GET_LOCATION_DETAILS = "GET_LOCATION_DETAILS";
export const GET_LOCATION_DETAILS_SUCCESS = "GET_LOCATION_DETAILS_SUCCESS";
export const GET_LOCATION_DETAILS_FAIL = "GET_LOCATION_DETAILS_FAIL";

export const GET_PUBLISH_DATAFLOW_DETAILS_FETCH = "v1/api/savedataflow/detail";
export const PUBLISH_DRAFT_DATAFLOW_FETCH =
  "v1/api/savedataflow/publish-dataflow";
export const DF_VERSION_FETCH = "v1/api/savedataflow/versiondetails";

export const UPDATE_DASHBOARD_MODAL_STATUS = "UPDATE_DASHBOARD_MODAL_STATUS";
export const UPDATE_DASHBOARD_MODAL_DATAFLOW =
  "UPDATE_DASHBOARD_MODAL_DATAFLOW";
export const UPDATE_DASHBOARD_DATA_TOGGLE = "UPDATE_DASHBOARD_DATA_TOGGLE";

export const SAVEDATAFLOW_CLONE_DATAFLOW = "v1/api/clone-dataflow/clone-df";

export const RESET_DATAFLOW_PACKAGE =
  "v1/api/savedataflow/change-location-type";

export const CHANGE_DATAFLOW_VERSION_STATUS =
  "v1/api/savedataflow/update-version-status";

export const DOWNLOAD_TRANSFER_LOG_FILE = "v1/api/RFD/get?";
export const RESET_SQL_COLUMNS = "reset_sql_columns";
export const COLUMNS_TO_ADD = "COLUMNS_TO_ADD";
// Manage Reject VLC
export const GET_REJECT_RULES_FETCH = "v1/api/study/get-reject-vlc";
export const POST_REJECT_RULES_STATUS = "v1/api/study/set-reject-vlc";

export const ClinicalOneODMIdentifier = "ClinicalOneODM";
export const InformODMIdentifier = "InformODM";
export const RaveODMIdentifier = "RaveODM";

export const ClinicalOneDefaultPageSize = 20;
export const InformDefaultPageSize = 500;
export const RaveDefaultPageSize = 25000;

export const ODMValues = {
  [ClinicalOneODMIdentifier]: "ClinicalOne - ODM",
  [InformODMIdentifier]: "Inform - ODM",
  [RaveODMIdentifier]: "Rave - ODM",
};
