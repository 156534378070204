/* eslint-disable import/prefer-default-export */
import { put, call } from "redux-saga/effects";
import axios from "axios";
import {
  baseURL,
  DF_VERSION_FAIL,
  DF_VERSION_FETCH,
  DF_VERSION_SUCCESS,
  GET_LOCATION_DETAILS_FAIL,
  GET_LOCATION_DETAILS_SUCCESS,
  GET_PUBLISH_DATAFLOW_DETAILS_FAIL,
  GET_PUBLISH_DATAFLOW_DETAILS_FETCH,
  GET_PUBLISH_DATAFLOW_DETAILS_SUCCESS,
  LOCATIONAPI,
  PUBLISH_DRAFT_DATAFLOW_FAIL,
  PUBLISH_DRAFT_DATAFLOW_FETCH,
  PUBLISH_DRAFT_DATAFLOW_SUCCESS,
} from "../../constants";

export function* fetchPublishDFDetails(params) {
  try {
    const fetchData = yield call(
      axios.get,
      `${baseURL}/${GET_PUBLISH_DATAFLOW_DETAILS_FETCH}/${params.dataflowId}`,
      {}
    );
    const filterData = fetchData.data?.data?.dataFlowDetails || [];
    const validationDetails =
      fetchData.data?.data?.validationDetails?.data || [];

    const data = {
      dataFlowName: filterData?.dataFlowName,
      vendor: filterData?.vendorName,
      type: filterData?.dataFlowType,
      structure: filterData?.dataStructure,
      dataPackageCount: filterData?.dataPackageCount,
      datasetCount: filterData?.dataSetCount,
      locationType: filterData?.locationType,
      locationId: filterData?.locationId,
      connectionURL: filterData?.connectionURL,
      ipServer: filterData?.IPServer,
    };

    yield put({
      type: GET_PUBLISH_DATAFLOW_DETAILS_SUCCESS,
      details: data,
      versionNumber: filterData?.version || 1,
      versionPrefix: filterData?.versionPrefix || "V",
      validationDetails,
    });
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put({
        type: GET_PUBLISH_DATAFLOW_DETAILS_FAIL,
        message: e?.response?.data?.message,
      });
    } else {
      yield put({
        type: GET_PUBLISH_DATAFLOW_DETAILS_FAIL,
        message: e.message,
      });
    }
  }
}

export function* fetchPublishDFVersions(params) {
  try {
    const fetchData = yield call(
      axios.get,
      `${baseURL}/${DF_VERSION_FETCH}/${params.dataflowId}`,
      {}
    );
    const filterData = fetchData.data?.data || [];

    yield put({
      type: DF_VERSION_SUCCESS,
      versionDetails: filterData,
    });
  } catch (e) {
    yield put({ type: DF_VERSION_FAIL, message: e.message });
  }
}

export function* getLocationDetailsPublish(action) {
  try {
    const getData = yield call(
      axios.get,
      `${baseURL}/${LOCATIONAPI}/detail/${action.locationId}`
    );
    yield put({
      type: GET_LOCATION_DETAILS_SUCCESS,
      locationDetail: getData.data.data,
    });
  } catch (e) {
    const errText = e.response?.data?.message
      ? e.response.data.message
      : e.message;
    yield put({
      type: GET_LOCATION_DETAILS_FAIL,
      message: errText,
    });
  }
}

export function* publishDataflowFromDraft(action) {
  try {
    yield call(axios.post, `${baseURL}/${PUBLISH_DRAFT_DATAFLOW_FETCH}`, {
      ...action.data,
    });
    yield put({
      type: PUBLISH_DRAFT_DATAFLOW_SUCCESS,
    });
  } catch (e) {
    const errText = e.response?.data?.message
      ? e.response.data.message
      : e.message;
    yield put({
      type: PUBLISH_DRAFT_DATAFLOW_FAIL,
      message: errText,
    });
  }
}
