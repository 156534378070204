import {
  GET_VENDORS_DATA,
  GET_LOCATIONS_DATA,
  UPDATE_FORM_FIELDS,
  GET_SERVICE_OWNERS,
  GET_DATA_KIND,
  HIDE_ERROR_MSG,
  UPDATE_SELECTED_LOCATION,
  GET_DATAFLOW_DETAIL,
  ADD_DATAFLOW,
  SAVE_DATAFLOW_LOCAL_DETAIL,
  UPDATE_DS,
  RESET_DF_FORMDATA,
  TOGGLE_DF_BTN,
  SET_VERSION_FREEZED,
  RESET_DATAFLOW_DETAILS,
  SET_PUBLISH_FLOW,
  SET_DRAFT_EDIT_MODE,
  SET_FROM_CREATE_DRAFT,
  FETCH_DATAFLOW_CONFIG,
  TRIGGER_DATAFLOW_DETAILS_PULL,
  GET_PREVIEW_SCHEDULES,
  FETCH_PREVIEW_SCHEDULES_SUCCESS,
  SET_PREVIEW_SCHEDULES,
} from "../../constants";

// eslint-disable-next-line import/prefer-default-export
export const getVendorsData = () => {
  return {
    type: GET_VENDORS_DATA,
  };
};

export const updateSelectedLocation = (location) => {
  return {
    type: UPDATE_SELECTED_LOCATION,
    location,
  };
};

export const addDataFlow = (dataflow) => {
  return {
    type: ADD_DATAFLOW,
    dataflow,
  };
};

export const changeFormFieldData = (e, field) => {
  return {
    type: UPDATE_FORM_FIELDS,
    field,
    value: e,
  };
};

export const setPreviewSchedules = (schedule) => {
  return {
    type: SET_PREVIEW_SCHEDULES,
    schedule,
  };
};

export const getPreviewSchedules = (payload) => {
  return {
    type: GET_PREVIEW_SCHEDULES,
    payload,
  };
};

export const getLocationsData = () => {
  return {
    type: GET_LOCATIONS_DATA,
  };
};

export const getLocationByType = (value) => {
  return {
    type: GET_LOCATIONS_DATA,
    value,
  };
};

export const getServiceOwnersData = () => {
  return {
    type: GET_SERVICE_OWNERS,
  };
};

export const getDataFlowDetail = (
  dataflowId,
  isPublishedFlow,
  fromCreate = false
) => {
  return {
    type: GET_DATAFLOW_DETAIL,
    dataflowId,
    isPublishedFlow,
    fromCreate,
  };
};

export const hideErrorMessage = () => {
  return {
    type: HIDE_ERROR_MSG,
  };
};

export const getDataKindData = () => {
  return {
    type: GET_DATA_KIND,
  };
};

export const toggleSaveDFBtn = (disabled = false) => {
  return {
    type: TOGGLE_DF_BTN,
    disabled,
  };
};

export const setDataflowLocal = (details) => {
  return {
    type: SAVE_DATAFLOW_LOCAL_DETAIL,
    details,
  };
};

export const updateDSState = (status) => {
  return {
    type: UPDATE_DS,
    status,
  };
};

export const resetDfFormData = () => {
  return {
    type: RESET_DF_FORMDATA,
  };
};

export const freezeDfVersion = (flag = true) => {
  return {
    type: SET_VERSION_FREEZED,
    freezed: flag,
  };
};

export const resetDataflowStore = () => {
  return {
    type: RESET_DATAFLOW_DETAILS,
  };
};

export const setIsPublishFlow = (state) => {
  return {
    type: SET_PUBLISH_FLOW,
    state,
  };
};

export const setFromCreateDraft = (state) => {
  return {
    type: SET_FROM_CREATE_DRAFT,
    state,
  };
};

export const setDraftEditMode = (state) => {
  return {
    type: SET_DRAFT_EDIT_MODE,
    state,
  };
};

export const fetchDataflowConfig = () => {
  return {
    type: FETCH_DATAFLOW_CONFIG,
  };
};

export const triggerDataFlowDetailsPull = () => {
  return {
    type: TRIGGER_DATAFLOW_DETAILS_PULL,
  };
};
