// constants for data adapter
const TABULAR_DA = "Tabular";
const TABULAR_RAVE_DA = "TabularRaveSOD";
const CLINICAL_ODM_DA = "ClinicalOneODM";
const INFORM_ODM_DA = "InformODM";
const RAVE_ODM_DA = "RaveODM";
const BASIC_DATA_CONNECTOR_DA = "BDC";

const CONTAIN_HEADER_ROW_OPTIONS = [
  { text: "Yes", value: "Y" },
  { text: "No", value: "N" },
];

const PROCESSING_OPTIONS = [
  { text: "Replace", value: "Replace" },
  { text: "Append", value: "Append" },
];

export {
  TABULAR_DA,
  TABULAR_RAVE_DA,
  CLINICAL_ODM_DA,
  INFORM_ODM_DA,
  RAVE_ODM_DA,
  BASIC_DATA_CONNECTOR_DA,
  CONTAIN_HEADER_ROW_OPTIONS,
  PROCESSING_OPTIONS,
};
