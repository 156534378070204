/* eslint-disable react/no-danger */
// library
import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { withRouter } from "react-router";
// apollo components
import Blade from "apollo-react/components/Blade";
// helpers
import { getCommonAppSwitcher } from "../../../services/ApiServices";
// styles
import "./NavigationPanel.scss";

const NavigationPanel = ({
  // history,
  location: { pathname },
  open,
  onClose,
  forceOpen = false,
}) => {
  const [openPanel, setOpenPanel] = useState(open);
  const [htmlContent, setHtml] = useState("");
  const [isAppSwitcherDown, setIsAppSwitcherDown] = useState(false);

  const closePanel = () => {
    setOpenPanel(false);
    onClose();
  };

  useEffect(() => {
    setOpenPanel(open);
  }, [open]);

  useEffect(
    () => {
      setOpenPanel(false);
      onClose();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  /** Used to set the sanitize html and prevent xss attack clicks */
  const getNavigationPanelContent = async () => {
    const htmlTemp = await getCommonAppSwitcher();
    if (htmlTemp?.error) {
      setHtml("");
      setIsAppSwitcherDown(true);
    } else {
      setIsAppSwitcherDown(false);
      const dirtyHtml = htmlTemp;
      const allowedDomains = "iqvia.com";
      DOMPurify.addHook("afterSanitizeAttributes", (node) => {
        if ("onclick" in node) {
          const urlClick = node?.getAttribute("onclick");
          if (urlClick) {
            const splitArray = urlClick?.split("=");
            if (!splitArray[1]?.includes(allowedDomains)) {
              node.removeAttribute("onclick");
            }
          }
        }
      });

      const sanitizedOptions = {
        ADD_ATTR: ["onclick"],
        FORCE_BODY: true,
      };
      const cleanHtml = DOMPurify.sanitize(dirtyHtml, sanitizedOptions);
      setHtml(cleanHtml);
    }
  };

  /** Used to set the app switcher close button with react component code */
  const domContentUpdate = (element) => {
    const documentElement = element;
    if (documentElement) {
      const appSwitcherButton = documentElement.querySelector(".switcher-icon");
      appSwitcherButton?.addEventListener("click", closePanel.bind(this));
    }
  };

  useEffect(() => {
    getNavigationPanelContent();
  }, []);

  return (
    <>
      <Blade
        className="navigation-panel"
        width={331}
        hasBackdrop
        BackdropProps={{
          onClick: closePanel,
          style: { background: "transparent" },
        }}
        open={openPanel || forceOpen}
        onClose={closePanel}
        title=""
      >
        {/* Show app switcher */}
        {!isAppSwitcherDown && (
          <div
            ref={(el) => {
              domContentUpdate(el);
            }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        )}

        {/* Error message when app switcher is down */}
        {isAppSwitcherDown && (
          <div className="app-service-down">
            <p>App Switcher is currently down. Use Launchpad for navigation.</p>
          </div>
        )}
      </Blade>
    </>
  );
};

export default withRouter(NavigationPanel);
