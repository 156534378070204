// web\src\utils\encryptionHelper.js
import CryptoJS from "crypto-js";

export const decrypt = (key, encryptionKey = null, iv = null) => {
  if (!encryptionKey) encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  if (!encryptionKey || !key) return "";

  const value = iv
    ? CryptoJS.AES.decrypt(key, CryptoJS.enc.Utf8.parse(encryptionKey), {
        iv: CryptoJS.enc.Utf8.parse(iv),
      }).toString(CryptoJS.enc.Utf8)
    : CryptoJS.AES.decrypt(key, encryptionKey).toString(CryptoJS.enc.Utf8);

  return value || "";
};

export const encrypt = (key, encryptionKey = null, iv = null) => {
  if (!encryptionKey) encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  if (!key || !encryptionKey) return "";

  const value = iv
    ? CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(key),
        CryptoJS.enc.Utf8.parse(encryptionKey),
        { iv: CryptoJS.enc.Utf8.parse(iv) }
      ).toString()
    : CryptoJS.AES.encrypt(key, encryptionKey).toString();

  return value || "";
};
