/* eslint-disable consistent-return */
import { produce } from "immer";

import {
  GET_PUBLISH_DATAFLOW_DETAILS_FAIL,
  GET_PUBLISH_DATAFLOW_DETAILS_SUCCESS,
  GET_PUBLISH_DATAFLOW_DETAILS,
  RESET_PUBLISH_STORE,
  SET_CURRENT_STEP,
  GET_LOCATION_DETAILS,
  GET_LOCATION_DETAILS_SUCCESS,
  GET_LOCATION_DETAILS_FAIL,
  UPDATE_VERSION_DESCRIPTION,
  UPDATE_VERSION_NUMBER,
  UPDATE_VERSION_HISTORY_PANEL,
  PUBLISH_DRAFT_DATAFLOW,
  PUBLISH_DRAFT_DATAFLOW_SUCCESS,
  PUBLISH_DRAFT_DATAFLOW_FAIL,
  DF_VERSION,
  DF_VERSION_SUCCESS,
  DF_VERSION_FAIL,
  GET_PACKAGE_DETAILS,
} from "../../constants";

export const initialState = {
  currentStep: 1,
  dataflowDetails: {},
  packageDetails: {},
  loading: false,
  btnLoading: false,
  locationDetails: {},
  pageLoading: true,
  versionDetails: [],
  versionNumber: 1,
  versionDescription: "",
  versionPanelOpen: false,
  versionPrefix: "V",
  validationDetails: [],
  error: "",
};

const PublishDFReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case SET_CURRENT_STEP:
        newState.currentStep = action.step;
        break;

      case GET_PUBLISH_DATAFLOW_DETAILS:
        newState.pageLoading = true;
        newState.error = "";
        break;

      case GET_PUBLISH_DATAFLOW_DETAILS_SUCCESS:
        newState.pageLoading = false;
        newState.dataflowDetails = action.details;
        newState.versionNumber = action.versionNumber;
        newState.versionPrefix = action.versionPrefix;
        newState.validationDetails = action.validationDetails || [];
        break;

      case GET_PACKAGE_DETAILS:
        newState.packageDetails = action.packageData;
        break;

      case GET_PUBLISH_DATAFLOW_DETAILS_FAIL:
        newState.pageLoading = false;
        newState.error = action.message;
        break;

      case DF_VERSION:
        newState.loading = true;
        newState.error = "";
        break;

      case DF_VERSION_SUCCESS:
        newState.loading = false;
        newState.versionDetails = action.versionDetails;
        break;

      case DF_VERSION_FAIL:
        newState.loading = false;
        newState.error = action.message;
        break;

      case PUBLISH_DRAFT_DATAFLOW:
        newState.btnLoading = true;
        newState.error = "";
        break;

      case PUBLISH_DRAFT_DATAFLOW_SUCCESS:
        newState.btnLoading = false;
        newState.currentStep = 4;
        break;

      case PUBLISH_DRAFT_DATAFLOW_FAIL:
        newState.btnLoading = false;
        newState.error = action.message;
        break;

      case GET_LOCATION_DETAILS:
        newState.loading = true;
        newState.error = "";
        break;

      case GET_LOCATION_DETAILS_SUCCESS:
        newState.loading = false;
        newState.locationDetails = action.locationDetail;
        break;

      case GET_LOCATION_DETAILS_FAIL:
        newState.loading = false;
        newState.error = action.message;
        break;

      case UPDATE_VERSION_NUMBER:
        newState.versionNumber = action.versionNumber;
        break;

      case UPDATE_VERSION_DESCRIPTION:
        newState.versionDescription = action.versionDescription;
        break;

      case UPDATE_VERSION_HISTORY_PANEL:
        newState.versionPanelOpen = action.state;
        break;

      case RESET_PUBLISH_STORE:
        return initialState;

      default:
        break;
    }
  });

export default PublishDFReducer;
