// libraries
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// apollo components
import ApolloThemeProvider from "apollo-react/utils/ApolloThemeProvider";
// components
import App from "./App";
// helpers
import store from "./store";
import reportWebVitals from "./reportWebVitals";
// styles
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <ApolloThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
