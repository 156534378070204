import axios from "axios";
import CryptoJS from "crypto-js";
import { getCookie, getUserId } from "../utils";

const userId = getUserId();
const token = getCookie("user.token");

const encrypt = (key) => {
  if (!key || !process.env.REACT_APP_ENCRYPTION_KEY)
    return "Encryption key not found";

  return CryptoJS.AES.encrypt(
    key,
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
};

axios.defaults.headers.common["api-key"] = encrypt(
  process.env.REACT_APP_API_KEY
);
axios.defaults.headers.common["sys-name"] = process.env.REACT_APP_SYS_NAME;
axios.defaults.headers.common["token-type"] = "user";
axios.defaults.headers.common["access-token"] = encrypt(userId);
axios.defaults.headers.common.Authorization = `Bearer ${token}`;

export default axios;
