// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audit-logs-wrapper .top-content {
  padding: 15px 35px; }
  .audit-logs-wrapper .top-content ol {
    padding: 0;
    margin-left: -8px;
    margin-bottom: 20px;
    margin-top: 6px; }
  .audit-logs-wrapper .top-content .title {
    margin-bottom: 12px; }
    .audit-logs-wrapper .top-content .title svg {
      margin-right: 10px; }

.audit-logs-wrapper tr {
  height: 0 !important; }

.audit-logs-wrapper tr[class*=" MuiTableRow-hover-"] {
  height: 48px !important; }

.audit-logs-wrapper .MuiToolbar-root h3 {
  font-size: 24px !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/AuditLog/AuditLog.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB,EAAA;EAFtB;IAIM,UAAU;IACV,iBAAiB;IACjB,mBAAmB;IACnB,eAAe,EAAA;EAPrB;IAUM,mBAAmB,EAAA;IAVzB;MAYQ,kBAAkB,EAAA;;AAZ1B;EAiBI,oBAAoB,EAAA;;AAjBxB;EAoBI,uBAAuB,EAAA;;AApB3B;EAwBM,0BAA0B,EAAA","sourcesContent":[".audit-logs-wrapper {\n  .top-content {\n    padding: 15px 35px;\n    ol {\n      padding: 0;\n      margin-left: -8px;\n      margin-bottom: 20px;\n      margin-top: 6px;\n    }\n    .title {\n      margin-bottom: 12px;\n      svg {\n        margin-right: 10px;\n      }\n    }\n  }\n  tr {\n    height: 0 !important;\n  }\n  tr[class*=\" MuiTableRow-hover-\"] {\n    height: 48px !important;\n  }\n  .MuiToolbar-root {\n    h3 {\n      font-size: 24px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
