import { PACKAGES_SEARCH_DRAFT } from "../constants";
import { apiPost } from "./commonService";

const getDataPackageDetailsById = async (
  dataflowID,
  dataPackageId,
  searchById = true
) => {
  const result = await apiPost(`${PACKAGES_SEARCH_DRAFT}/${dataflowID}/`, {
    query: "",
    dataPackageId,
    searchById,
  });
  return result;
};

const dataPackageService = {
  getDataPackageDetailsById,
};

export default dataPackageService;
