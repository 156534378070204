import withStyles from "@mui/styles/withStyles";
import React from "react";
import { Field } from "redux-form";

import Autocomplete from "apollo-react/components/Autocomplete";
import Button from "apollo-react/components/Button";
import Checkbox from "apollo-react/components/Checkbox";
import DatePicker from "apollo-react/components/DatePicker";
import DateRangePicker from "apollo-react/components/DateRangePicker";
import FileInput from "apollo-react/components/FileInput";
import FormHelperText from "apollo-react/components/FormHelperText";
import PasswordComplexity from "apollo-react/components/PasswordComplexity";
import PasswordInput from "apollo-react/components/PasswordInput";
import PhoneNumberInput from "apollo-react/components/PhoneNumberInput";
import RadioGroup from "apollo-react/components/RadioGroup";
import Select from "apollo-react/components/Select";
import Slider from "apollo-react/components/Slider";
import Switch from "apollo-react/components/Switch";
import TextField from "apollo-react/components/TextField";
import Typography from "apollo-react/components/Typography";
import Tooltip from "apollo-react/components/Tooltip";
import InfoIcon from "apollo-react-icons/Info";
import * as colors from "apollo-react/colors";

import "./FormComponents.scss";

export const styles = {
  topSpacer: {
    marginTop: 16,
  },
  radioTopSpacer: {
    marginTop: 24,
  },
  phoneNumber: {
    margin: "16px 0",
  },
  passwordWrapper: {
    display: "flex",
    alignItems: "start",
  },
  passwordComplexity: {
    marginTop: 54,
  },
  thumb: {
    width: 200,
    height: 200,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
};

function reduxFormify(Component) {
  return function reduxFormifyComponent(props) {
    return <Field component={Component} {...props} />;
  };
}

const renderTooltip = (tooltip = false, label) => {
  if (tooltip) {
    return (
      <div className="tooltip-icon">
        {label}
        <Tooltip placement="top" title={tooltip} disableFocusListener>
          <InfoIcon
            fontSize="small"
            className="info-icon"
            style={{ color: colors.neutral7, paddingLeft: "5px" }}
          />
        </Tooltip>
      </div>
    );
  }
  return label;
};

const RenderAutocomplete = ({
  input,
  helperText,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <>
      <Autocomplete
        helperText={(touched && error) || helperText}
        error={touched && !!error}
        {...input}
        value={input.value === "" ? [] : input.value}
        {...rest}
      />
    </>
  );
};

export const ReduxFormAutocomplete = reduxFormify(RenderAutocomplete);

const RenderAutocompleteV2 = ({
  input: { onChange, value, ...input },
  helperText,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <>
      <Autocomplete
        helperText={(touched && error) || helperText}
        error={touched && !!error}
        {...input}
        value={value === "" ? [] : value}
        onChange={(event, v) => onChange(v)}
        {...rest}
      />
    </>
  );
};

export const ReduxFormAutocompleteV2 = reduxFormify(RenderAutocompleteV2);

const RenderAutocompleteV2WithTooltip = ({
  input: { onChange, value, ...input },
  helperText,
  tooltip = false,
  label,
  ...rest
}) => {
  return (
    <>
      <Autocomplete
        onChange={(event, v) => onChange(v)}
        label={tooltip ? renderTooltip(tooltip, label) : label}
        {...input}
        value={value === "" ? [] : value}
        helperText={helperText}
        {...rest}
      />
    </>
  );
};

export const ReduxFormAutocompleteV2WithTooltip = reduxFormify(
  RenderAutocompleteV2WithTooltip
);

export const RenderCheckbox = ({
  input,
  highlightError = false,
  showErrorMessage = true,
  meta: { error, touched },
  ...rest
}) => {
  const isChecked = typeof input.value !== "boolean" ? false : input.value;
  return (
    <>
      <Checkbox
        checked={isChecked}
        error={touched && error && highlightError ? true : false}
        {...input}
        {...rest}
      />
      {touched && error && showErrorMessage && (
        <FormHelperText error>{error}</FormHelperText>
      )}
    </>
  );
};

export const ReduxFormCheckbox = reduxFormify(RenderCheckbox);

const RenderDatePicker = ({
  input,
  helperText,
  meta: { touched, error },
  ...rest
}) => (
  <DatePicker
    helperText={(touched && error) || helperText}
    error={touched && !!error}
    {...input}
    {...rest}
  />
);

export const ReduxFormDatePicker = reduxFormify(RenderDatePicker);

const RenderDatePickerV2 = ({
  input: { onBlur, ...input },
  helperText,
  meta: { touched, error },
  ...rest
}) => (
  <DatePicker
    helperText={(touched && error) || helperText}
    error={touched && !!error}
    {...input}
    {...rest}
  />
);

export const ReduxFormDatePickerV2 = reduxFormify(RenderDatePickerV2);

const RenderDateRangePicker = ({
  input: { onChange, ...input },
  label,
  classes,
  fromDateProps = {},
  toDateProps = {},
  meta: { touched, error },
  ...rest
}) => (
  <div className={classes.topSpacer}>
    <Typography variant="body2">{label}</Typography>
    <DateRangePicker
      onChange={(event) => onChange(event.target.value)}
      fromDateProps={{
        ...fromDateProps,
        helperText: (touched && error) || fromDateProps.helperText,
        error: touched && error,
      }}
      toDateProps={{
        ...toDateProps,
        helperText: (touched && error) || toDateProps.helperText,
        error: touched && error,
      }}
      {...input}
      {...rest}
    />
  </div>
);

export const ReduxFormDateRangePickerBase = reduxFormify(RenderDateRangePicker);

export const ReduxFormDateRangePicker = withStyles(styles)(
  ReduxFormDateRangePickerBase
);

const RenderPasswordInput = ({
  showComplexity,
  fullWidth,
  classes,
  input,
  meta: { touched, error },
  ...rest
}) => (
  <div className={classes.passwordWrapper}>
    <PasswordInput
      helperText={touched && error}
      fullWidth={fullWidth}
      error={touched && !!error}
      {...input}
      {...rest}
    />
    {showComplexity && (
      <PasswordComplexity
        className={classes.passwordComplexity}
        fullWidth={fullWidth}
        value={input.value}
      />
    )}
  </div>
);

export const ReduxFormPasswordInput = withStyles(styles)(
  reduxFormify(RenderPasswordInput)
);

const RenderPhoneInput = ({
  input,
  helperText,
  classes,
  meta: { touched, error },
  ...rest
}) => (
  <PhoneNumberInput
    helperText={(touched && error) || helperText}
    defaultCountry="us"
    className={classes.phoneNumber}
    error={touched && !!error}
    {...input}
    {...rest}
  />
);

export const ReduxFormPhoneInput = withStyles(styles)(
  reduxFormify(RenderPhoneInput)
);

const RenderPictureInput = ({
  input,
  helperText,
  classes,
  meta: { touched, error },
  ...rest
}) => (
  <>
    <FileInput
      className={classes.input}
      {...input}
      value={input?.value?.target?.file?.name ?? ""}
      onChange={(file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          input.onChange({
            target: { file, thumb: reader.result },
          });
        };
        reader.readAsDataURL(file);
      }}
      helperText={(touched && error) || helperText}
      error={touched && !!error}
      {...rest}
    />
    {input.value && (
      <div className={classes.thumb}>
        <img alt="Uploaded" src={input?.value?.target?.thumb ?? ""} />
      </div>
    )}
  </>
);

export const ReduxFormPictureInput = withStyles(styles)(
  reduxFormify(RenderPictureInput)
);

const RenderRadioGroup = ({
  input,
  classes,
  helperText,
  meta: { touched, error },
  ...rest
}) => (
  <div className={classes.radioTopSpacer}>
    <RadioGroup
      {...input}
      {...rest}
      error={touched && !!error}
      helperText={(touched && error) || helperText}
    />
  </div>
);

export const ReduxFormRadioGroup = withStyles(styles)(
  reduxFormify(RenderRadioGroup)
);

const RenderSelect = ({
  input,
  helperText,
  meta: { touched, error },
  ...rest
}) => (
  <Select
    helperText={(touched && error) || helperText}
    error={touched && !!error}
    {...input}
    {...rest}
  />
);

export const ReduxFormSelect = reduxFormify(RenderSelect);

const RenderSelectWithTooltip = ({
  input,
  helperText,
  meta: { touched, error },
  tooltip = false,
  label,
  ...rest
}) => (
  <Select
    helperText={(touched && error) || helperText}
    error={touched && !!error}
    label={tooltip ? renderTooltip(tooltip, label) : label}
    {...input}
    {...rest}
  />
);

export const ReduxFormSelectWithTooltip = reduxFormify(RenderSelectWithTooltip);

const RenderMultiSelect = ({
  input,
  helperText,
  meta: { touched, error },
  ...rest
}) => (
  <Select
    helperText={(touched && error) || helperText}
    error={touched && !!error}
    {...input}
    {...rest}
    // multiple
  />
);

export const ReduxFormMultiSelect = reduxFormify(RenderMultiSelect);

const RenderSlider = ({
  label,
  classes,
  unit,
  input,
  meta: { touched, error },
  ...rest
}) => (
  <>
    <Typography variant="body2" className={classes.topSpacer}>
      {`${label} (${input.value}${unit})`}
    </Typography>
    <Slider
      {...input}
      value={+input.value}
      onChange={(event, value) => input.onChange(value)}
      {...rest}
    />
    {touched && error && <FormHelperText error>{error}</FormHelperText>}
  </>
);

export const ReduxFormSlider = withStyles(styles)(reduxFormify(RenderSlider));

const RenderSwitch = ({ input, name, ...rest }) => {
  const isChecked = typeof input.value !== "boolean" ? false : input.value;
  return <Switch checked={isChecked} {...input} value={name} {...rest} />;
};

export const ReduxFormSwitch = reduxFormify(RenderSwitch);

const RenderTextField = ({ input, meta: { touched, error }, ...rest }) => (
  <TextField
    error={touched && !!error}
    helperText={touched && error}
    {...input}
    {...rest}
  />
);

export const ReduxFormTextField = reduxFormify(RenderTextField);

const RenderTextPassword = ({
  input,
  label,
  type,
  disabled,
  meta: { touched, error, warning },
}) => (
  <div
    className={`custom-password-input-container ${
      disabled ? "cus-disabled" : ""
    }`}
  >
    <div className="custom-label">{label}</div>
    <div
      className={`custom-password-input ${touched && warning ? "warning" : ""}`}
    >
      <input {...input} placeholder="" type={type} disabled={disabled} />
    </div>
    {touched &&
      ((error && <p className="error">{error}</p>) ||
        (warning && <p className="warnings">{warning}</p>))}
  </div>
);

export const ReduxFormPassword = reduxFormify(RenderTextPassword);

const RenderButtonToggle = ({
  input: { onChange, value },
  trueLabel,
  falseLabel,
  ...rest
}) => (
  <Button onClick={() => onChange(!value)} {...rest}>
    {value ? falseLabel : trueLabel}
  </Button>
);

export const ReduxFormButtonToggle = reduxFormify(RenderButtonToggle);
