// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publishGroup {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .publishGroup .publish-title {
    color: #000000;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px; }
`, "",{"version":3,"sources":["webpack://./src/components/DataFlow/Header.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;EAHrB;IAMI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB,EAAA","sourcesContent":[".publishGroup {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .publish-title {\n    color: #000000;\n    font-size: 24px;\n    font-weight: 600;\n    letter-spacing: 0;\n    line-height: 26px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
