export const initialState = {
  // local state
  clinicalDataTypes: null,

  tables: null, // list of tables for dropdown
  isTablesFetching: false, // is table fetching
  isTablesError: null,

  columns: null, // list of filtered columns for offset columns dropdown
  offsetColumns: null, // list of filtered columns for offset columns dropdown
  isColumnsFetching: null,
  isColumnsError: false,

  csqlResult: null, // csql result
  csqlFetched: true, // is csql fetched
  isCsqlFetching: false, // is csql is fetching
  csqlError: null,

  isLoading: false,
  location: null,
  isSavedClickedOnce: false,
  // db fields
  dataflowid: null,
  datapackageid: null,
  datasetid: null,
  // form fields
  fileType: null,
  delimiter: null,
  mnemonicPrefix: null,
  clinicalDataType: null,
  containsHeaderRow: null,
  fileNamingConvention: null,
  filePassword: null,
  sftpPath: null,
  subFolderName: null,
  processing: null,
  createCopy: null,
  active: true,
  datasetMnemonic: null,
  customsqlYesNo: null,
  customsql: null,
  table: null,
  filter: null,
  offsetColumn: null,
  offsetColumnDatatype: null,
  // errors
  errors: {
    fileType: null,
    delimiter: null,
    mnemonicPrefix: null,
    clinicalDataType: null,
    containsHeaderRow: null,
    fileNamingConvention: null,
    filePassword: null,
    sftpPath: null,
    subFolderName: null,
    processing: null,
    createCopy: null,
    active: null,
    datasetMnemonic: null,
    customsql_yn: null,
    customsql: null,
    table: null,
    filter: null,
  },
};

export default initialState;
