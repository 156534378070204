// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../components/Icons/bg-image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), linear-gradient(#595959, #999999);
  width: 100vw;
  background-position: center;
  background-size: cover;
  color: #000000;
  height: 100vh; }

#errorLogout h3 button {
  display: none !important; }

#errorLogout .MuiDialog-paperWidthSm {
  width: 443px !important; }

#errorLogout button {
  padding: 0 33px; }
`, "",{"version":3,"sources":["webpack://./src/pages/Logout/Logout.scss"],"names":[],"mappings":"AAAA;EACE,sFACmC;EACnC,YAAY;EACZ,2BAA2B;EAC3B,sBAAsB;EACtB,cAAc;EACd,aAAa,EAAA;;AAEf;EAEE,wBAAwB,EAAA;;AAF1B;EAKI,uBAAuB,EAAA;;AAL3B;EAQI,eAAe,EAAA","sourcesContent":[".wrapper {\n  background: url(\"../../components/Icons/bg-image.png\"),\n    linear-gradient(#595959, #999999);\n  width: 100vw;\n  background-position: center;\n  background-size: cover;\n  color: #000000;\n  height: 100vh;\n}\n#errorLogout {\n  h3 button {\n  display: none !important;\n  }\n  .MuiDialog-paperWidthSm {\n    width: 443px !important;\n  }\n  button {\n    padding: 0 33px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
