// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#previewSqlColumns .MuiDialog-paper {
  width: 80%;
  max-width: 80%; }

#previewSqlColumns div[class^="MuiPaper-root"] div[class^="Table-root"] div[class^="Table-tableWrapper-"] table thead tr {
  border-bottom: 1px solid #a5a5a5; }

#previewSqlColumns div[class^="MuiPaper-root"] div[class^="Table-root"] div[class^="Table-tableWrapper-"] table thead th {
  border-right: 1px solid #a5a5a5; }

#previewSqlColumns div[class^="MuiPaper-root"] div[class^="Table-root"] div[class^="Table-tableWrapper-"] table thead th:last-child {
  border-right: none; }

#previewSqlColumns div[class^="MuiPaper-root"] div[class^="Table-root"] div[class^="Table-tableWrapper-"] table tbody tr {
  border-bottom: 1px solid #a5a5a5; }
  #previewSqlColumns div[class^="MuiPaper-root"] div[class^="Table-root"] div[class^="Table-tableWrapper-"] table tbody tr td {
    border-right: 1px solid #a5a5a5; }
  #previewSqlColumns div[class^="MuiPaper-root"] div[class^="Table-root"] div[class^="Table-tableWrapper-"] table tbody tr td:last-child {
    border-right: none; }
`, "",{"version":3,"sources":["webpack://./src/components/Dataset/PreviewColumns/index.scss"],"names":[],"mappings":"AAAA;EAEI,UAAU;EACV,cAAc,EAAA;;AAHlB;EAWc,gCAA2C,EAAA;;AAXzD;EAcc,+BAA0C,EAAA;;AAdxD;EAiBc,kBAAkB,EAAA;;AAjBhC;EAsBc,gCAA2C,EAAA;EAtBzD;IAwBgB,+BAA0C,EAAA;EAxB1D;IA2BgB,kBAAkB,EAAA","sourcesContent":["#previewSqlColumns {\n  .MuiDialog-paper {\n    width: 80%;\n    max-width: 80%;\n  }\n  div[class^=\"MuiPaper-root\"] {\n    div[class^=\"Table-root\"] {\n      div[class^=\"Table-tableWrapper-\"] {\n        table {\n          thead {\n            tr {\n              border-bottom: 1px solid rgb(165, 165, 165);\n            }\n            th {\n              border-right: 1px solid rgb(165, 165, 165);\n            }\n            th:last-child {\n              border-right: none;\n            }\n          }\n          tbody {\n            tr {\n              border-bottom: 1px solid rgb(165, 165, 165);\n              td {\n                border-right: 1px solid rgb(165, 165, 165);\n              }\n              td:last-child {\n                border-right: none;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
