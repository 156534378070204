/* eslint-disable no-case-declarations */
import { produce } from "immer";

import {
  PAGEHEADER_UPDATE,
  GET_DATA_FLOW_LIST,
  GET_DATA_FLOW_LIST_SUCCESS,
  GET_DATA_FLOW_LIST_FAILURE,
  GET_DATASET_INGESTION_SUMMARY,
  GET_DATASET_INGESTION_SUMMARY_SUCCESS,
  GET_DATASET_INGESTION_SUMMARY_FAILURE,
  GET_ALL_INGESTION_SUMMARY,
  GET_ALL_INGESTION_SUMMARY_SUCCESS,
  GET_ALL_INGESTION_SUMMARY_FAILURE,
  GET_SPECIFIC_INGESTION_DATASETS,
  GET_SPECIFIC_INGESTION_DATASETS_SUCCESS,
  GET_SPECIFIC_INGESTION_DATASETS_FAILURE,
  GET_STUDIES_LIST,
  GET_STUDIES_LIST_SUCCESS,
  GET_STUDIES_LIST_FAILURE,
  GET_PINNED_LIST,
  GET_PINNED_LIST_SUCCESS,
  GET_PINNED_LIST_FAILURE,
  SELECTED_DATAFLOW,
  UPDATE_HEADER_COUNT,
  SET_MONITOR_TITLE,
  SET_ALL_MONITOR_TITLE,
  SET_MONITOR_SHOW_SUMMARY,
  UPDATE_DF_STATUS,
  UPDATE_PREVIOUS_STATE_ACTIVE_ONLY_BTN,
  UPDATE_PREVIOUS_SEGMENT_CONTROL,
  UPDATE_DASHBOARD_MODAL_STATUS,
  UPDATE_DASHBOARD_MODAL_DATAFLOW,
  UPDATE_DASHBOARD_DATA_TOGGLE,
  GET_DATA_FLOW_LIST_INTERVAL_SUCCESS,
  GET_DATA_FLOW_LIST_INTERVAL_FAILURE,
  GET_DATA_FLOW_LIST_INTERVAL,
  FETCH_DATAFLOW_CONFIG_SUCCESS,
  UPDATE_PREVIOUS_PIPELINE_TYPE,
  TABULAR_PIPELINE,
  CLEAR_SELECTED_DATAFLOW,
  SET_EXPANDED,
  UPDATE_PREVIOUS_SELECTED_STUDY,
  UPDATE_PREVIOUS_TRANSFER_LOG_TABLE_COLUMNS,
  RESET_PREVIOUS_TRANSFER_LOG_TABLE_COLUMNS,
  UPDATE_PREVIOUS_TABLE_FILTERS,
  RESET_PREVIOUS_TABLE_FILTERS,
} from "../../constants";

export const initialState = {
  loading: false,
  config: {},
  compressionType: {},
  fileTypes: {},
  dataflowSchedules: {},
  showODM: false,
  showBasicDataConnector: false,
  showStandardizeMetadata: false,
  summaryLoading: false,
  exportStudy: null,
  ingestionData: {},
  ingestnData: {},
  flowData: [],
  monitorTitle: "Dataset Pipelines",
  allMonitorTitle: "Dataset Pipelines",
  showTableSummary: false,
  pkglistExpanded: [],
  selectedCard: {
    prot_id: "",
    protocolnumber: "",
    protocolnumberstandard: "",
    sponsorname: "",
    phase: "",
    protocolstatus: "",
    projectcode: "",
    ingestionCount: "",
    priorityCount: "",
    staleFilesCount: "",
    ActiveDfCount: "",
    InActiveDfCount: "",
    vCount: "",
    dpCount: "",
    ActiveDsCount: "",
    InActiveDsCount: "",
    dfCount: "",
    dsCount: "",
    target_db: "",
    tenant_mnemonic: "",
    prot_mnemonic: "",
  },
  dfId: "",
  userStudies: [],
  userStudiesLoading: false,
  selectedDataFlow: "",
  userPinnedStudies: [],
  previousState: {
    activeOnlyBtn: true, // dashboard/monitor -> page -> Show active datasets btn
    segmentControl: "0", // dashboard -> monitor tab -> (all ->"all", prod -> "0", test -> "1") tab control
    pipelineType: TABULAR_PIPELINE, // dashboard -> monitor tab -> pipeline type
    previousSelectedStudy: "",
    filterCache: {
      monitorTable: {
        filters: {},
        sortedColumn: "",
        sortOrder: "",
        columnSettings: [],
      },
      dataflowTable: {
        filters: {},
        sortedColumn: "",
        sortOrder: "",
        columnSettings: [],
      },
      monitorViewAllTable: {
        filters: {},
        sortedColumn: "",
        sortOrder: "",
        columnSettings: [],
      },
      cdiHomeMonitorTable: {
        filters: {},
        sortedColumn: "",
        sortOrder: "",
        columnSettings: [],
      },
      cdiHomeViewAllTable: {
        filters: {},
        sortedColumn: "",
        sortOrder: "",
        columnSettings: [],
      },
    },
    transferLogTableColumns: [],
  },
  modal: {
    cancelPublishModal: false,
    hardDeleteModal: false,
    resetDraftModal: false,
    retryPublishModal: false,
    deleteDraftModal: false,
    selectedDataflow: null,
  },
  updateToggle: false,
};

const DashboardReducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case SET_EXPANDED:
        newState.pkglistExpanded = action.expanded;
        break;

      case FETCH_DATAFLOW_CONFIG_SUCCESS:
        const {
          data,
          dataPackageCommpressionType,
          dataSetFileType,
          dataflowSchedule,
        } = action?.config?.data;
        newState.config = data;
        newState.compressionType = dataPackageCommpressionType;
        newState.fileTypes = dataSetFileType;
        newState.dataflowSchedules = dataflowSchedule;
        newState.showODM = action?.showODM || false;
        newState.showBasicDataConnector =
          action?.showBasicDataConnector || false;
        newState.showStandardizeMetadata =
          action?.showStandardizeMetadata || false;
        break;

      case PAGEHEADER_UPDATE:
        newState.selectedCard = action.study;
        newState.pkglistExpanded = [];
        break;

      case GET_DATA_FLOW_LIST:
        newState.loading = true;
        break;

      case GET_DATA_FLOW_LIST_SUCCESS:
        newState.loading = false;
        newState.flowData = action.flowData;
        newState.readPermission = action.readPermission;
        break;

      case GET_DATA_FLOW_LIST_FAILURE:
        newState.loading = false;
        break;

      case GET_DATA_FLOW_LIST_INTERVAL:
        newState.dataLoading = true;
        break;

      case GET_DATA_FLOW_LIST_INTERVAL_SUCCESS:
        newState.loading = false;
        newState.dataLoading = false;
        newState.flowData = action.flowData;
        break;

      case GET_DATA_FLOW_LIST_INTERVAL_FAILURE:
        newState.loading = false;
        newState.dataLoading = false;
        break;

      case GET_STUDIES_LIST:
        newState.userStudiesLoading = true;
        newState.loading = true;
        break;

      case GET_STUDIES_LIST_SUCCESS:
        newState.userStudiesLoading = false;
        newState.loading = false;
        newState.userStudies = action.userStudies;
        break;

      case GET_STUDIES_LIST_FAILURE:
        newState.userStudiesLoading = false;
        newState.loading = false;
        break;

      case GET_PINNED_LIST:
        newState.loading = true;
        break;

      case GET_PINNED_LIST_SUCCESS:
        newState.loading = false;
        newState.userPinnedStudies = action.userPinnedStudies;
        break;

      case GET_PINNED_LIST_FAILURE:
        newState.loading = false;
        break;

      case UPDATE_DF_STATUS:
        const updateDF = state.flowData.map((df) => {
          if (df.dataFlowId === action.dfId) {
            return {
              ...df,
              status: action.newStatus,
            };
          }
          return df;
        });
        newState.selectedDataFlow = {
          ...state.selectedDataFlow,
          status: action.newStatus,
        };
        newState.flowData = updateDF;
        break;

      case GET_DATASET_INGESTION_SUMMARY:
        newState.summaryLoading = true;
        break;

      case GET_DATASET_INGESTION_SUMMARY_SUCCESS:
        newState.summaryLoading = false;
        newState.ingestionData = action.ingestionData;
        break;

      case GET_DATASET_INGESTION_SUMMARY_FAILURE:
        newState.summaryLoading = false;
        break;

      case GET_ALL_INGESTION_SUMMARY:
        newState.summaryLoading = true;
        break;

      case GET_ALL_INGESTION_SUMMARY_SUCCESS:
        newState.summaryLoading = false;
        newState.ingestnData = action.ingestnData;
        break;

      case GET_ALL_INGESTION_SUMMARY_FAILURE:
        newState.summaryLoading = false;
        break;

      case GET_SPECIFIC_INGESTION_DATASETS:
        newState.summaryLoading = true;
        break;

      case GET_SPECIFIC_INGESTION_DATASETS_SUCCESS:
        newState.summaryLoading = false;
        newState.ingestionData = action.ingestionData;
        break;

      case GET_SPECIFIC_INGESTION_DATASETS_FAILURE:
        newState.summaryLoading = false;
        break;

      case UPDATE_HEADER_COUNT:
        newState.selectedCard = {
          ...state?.selectedCard,
          dfCount: action.dfCount,
          dsCount: action.dsCount,
          ActiveDfCount: action.ActiveDfCount,
          InActiveDfCount: action.InActiveDfCount,
          ActiveDsCount: action.ActiveDsCount,
          InActiveDsCount: action.InActiveDsCount,
        };
        newState.userStudies = state?.userStudies?.map((e) => {
          let newObj = {};
          if (e.prot_id === state?.selectedCard?.prot_id) {
            newObj = {
              ...e,
              dfCount: action.dfCount,
              dsCount: action.dsCount,
              ActiveDfCount: action.ActiveDfCount,
              InActiveDfCount: action.InActiveDfCount,
              ActiveDsCount: action.ActiveDsCount,
              InActiveDsCount: action.InActiveDsCount,
            };
          } else {
            newObj = {
              ...e,
            };
          }
          return newObj;
        });
        break;
      case SET_MONITOR_TITLE:
        newState.monitorTitle = action.title;
        break;

      case SET_ALL_MONITOR_TITLE:
        newState.allMonitorTitle = action.title;
        break;

      case SET_MONITOR_SHOW_SUMMARY:
        newState.showTableSummary = action.showSummary;
        break;

      case SELECTED_DATAFLOW:
        newState.selectedDataFlow = action.dataflow;
        newState.pkglistExpanded = [];
        break;

      case CLEAR_SELECTED_DATAFLOW:
        newState.selectedDataFlow = "";
        newState.pkglistExpanded = [];
        break;

      case UPDATE_PREVIOUS_STATE_ACTIVE_ONLY_BTN:
        newState.previousState.activeOnlyBtn = action.newStatus;
        break;

      case UPDATE_PREVIOUS_SEGMENT_CONTROL:
        newState.previousState.segmentControl = action.newStatus;
        break;

      case UPDATE_PREVIOUS_PIPELINE_TYPE:
        newState.previousState.pipelineType = action.newStatus;
        break;

      case UPDATE_PREVIOUS_TRANSFER_LOG_TABLE_COLUMNS:
        newState.previousState.transferLogTableColumns = action.columns;
        break;
      case RESET_PREVIOUS_TRANSFER_LOG_TABLE_COLUMNS:
        newState.previousState.transferLogTableColumns = [];
        break;

      case UPDATE_PREVIOUS_TABLE_FILTERS:
        if (action.updateColumnSettings) {
          newState.previousState.filterCache[action.filterTableName] = {
            ...newState.previousState.filterCache[action.filterTableName],
            columnSettings: action.filterConfig,
          };
        } else {
          newState.previousState.filterCache[action.filterTableName] = {
            ...newState.previousState.filterCache[action.filterTableName],
            ...action.filterConfig,
          };
        }
        break;
      case RESET_PREVIOUS_TABLE_FILTERS:
        newState.previousState.filterCache[action.filterTableName] = {
          ...initialState.previousState.filterCache[action.filterTableName],
        };
        break;

      case UPDATE_PREVIOUS_SELECTED_STUDY:
        newState.previousState.previousSelectedStudy = action.study;
        break;

      case UPDATE_DASHBOARD_MODAL_STATUS:
        if (action.modal) {
          newState.modal = {
            ...state?.modal,
            [action.modal]: action?.state || false,
          };
        }
        break;
      case UPDATE_DASHBOARD_MODAL_DATAFLOW:
        newState.modal = {
          ...state?.modal,
          selectedDataflow: action.details || null,
        };
        break;
      case UPDATE_DASHBOARD_DATA_TOGGLE:
        newState.updateToggle = !state.updateToggle;
        break;

      default:
        break;
    }
  });

export default DashboardReducer;
