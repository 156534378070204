// library
import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
// apollo components
import Table from "apollo-react/components/Table/Table";
import Modal from "apollo-react/components/Modal/Modal";
import { isArrayEmptyObjects } from "../../../utils";

// styles
import "./index.scss";

const PreviewColumns = ({ previewSQL, onClose }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [tableRows, setTableRows] = useState([]);

  const checkPreviewSQL = (previewSQLProp) => {
    if (previewSQLProp?.length === 1 && isEmpty(previewSQLProp?.[0])) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (previewSQL?.length) {
      setTimeout(() => {
        setModalOpen(true);
        if (checkPreviewSQL(previewSQL)) {
          try {
            const rows = [...previewSQL].splice(0, 10).map((x) =>
              Object.entries(x).reduce(
                (ret, cur) => ({
                  ...ret,
                  [cur[0]]:
                    typeof cur[1] === "object"
                      ? JSON.stringify(cur[1])
                      : cur[1],
                }),
                {}
              )
            );
            const checkEmptyArray = isArrayEmptyObjects(rows);
            setTableRows(checkEmptyArray ? [] : rows);
          } catch (error) {
            console.log(">>> ", error);
          }
        } else setTableRows([]);
      }, 500);
    }
  }, [previewSQL]);

  if (!tableRows.length) {
    return false;
  }

  const handleClose = () => {
    setModalOpen(false);
    onClose?.();
  };

  return (
    <Modal
      open={modalOpen}
      title="Sql Columns"
      onClose={handleClose}
      message="No file with unblinded or unmasked data should be configured"
      buttonProps={[
        {
          label: "OK",
          variant: "primary",
          onClick: () => handleClose(),
        },
      ]}
      id="previewSqlColumns"
    >
      <div className="preview-table">
        <Table
          col
          columns={
            tableRows.length &&
            Object.keys(tableRows[0]).map((e) => ({
              header: e,
              accessor: e,
            }))
          }
          rows={tableRows}
          hasScroll={true}
          columnSettings={{
            enabled: true,
            frozenColumnsEnabled: true,
          }}
          maxHeight="calc(100vh - 293px)"
          hidePagination
        />
      </div>
    </Modal>
  );
};

export default PreviewColumns;
