import { Route, Switch, Redirect, useRouteMatch } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import { lazy, Suspense, useState, useEffect } from "react";
import Loader from "apollo-react/components/Loader";
import { useDispatch } from "react-redux";
import { getUserId, setIdleLogout } from "./utils";
import TopNavbar from "./components/AppHeader/TopNavbar/TopNavbar";
import AppFooter from "./components/AppFooter/AppFooter";
import Logout from "./pages/Logout/Logout";
import DataPackages from "./pages/DataPackages/DataPackages";
import AuditLog from "./pages/AuditLog/AuditLog";
import PageHeader from "./components/Common/PageHeader";
import { userLogOut } from "./services/ApiServices";
import { fetchDataflowConfig } from "./store/actions/DataFlowAction";
import DPSelector from "./pages/DataPackages/DPSelector";

const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Monitor = lazy(() => import("./pages/Dashboard/MonitorTab/ViewAll"));
const AllViewMonitorPage = lazy(() =>
  import("./pages/Dashboard/AllMonitorTab/AllMonitorViewAll")
);
const CDIhome = lazy(() =>
  import("./pages/Dashboard/AllMonitorTab/AllMonitor")
);
const DataFlow = lazy(() => import("./pages/DataFlow/ViewEdit"));
const DataFlowClone = lazy(() => import("./pages/CloneDataFlow/index"));
const Dataset = lazy(() => import("./pages/Dataset/Dataset"));
const CDIAdmin = lazy(() => import("./pages/Admin/CDIAdmin"));
const DatasetIngestionReport = lazy(() =>
  import("./pages/DatasetIngestionReport")
);
const IngestionIssues = lazy(() =>
  import("./pages/DatasetIngestionReport/IngestionIssues")
);
// Publish DF page
const PublishDFPage = lazy(() => import("./pages/Publish/PublishDF"));
// Manage Reject VLC Rules
const ManageRejectVLCRule = lazy(() =>
  import("./pages/ManageRejectVLC/ManageRejectVLC")
);

const Empty = () => <></>;

const WithPageHeader = () => {
  const match = useRouteMatch();
  return (
    <>
      <PageHeader height={64} />
      <Switch>
        <Route path={`${match.path}`} exact render={() => <Dashboard />} />
        <Route
          path={`${match.path}/monitor`}
          exact
          render={() => <Monitor />}
        />
        <Route
          path={`${match.path}/publish/:dataflowId`}
          exact
          render={() => <PublishDFPage />}
        />
        <Route
          path={`${match.path}/manage-vlc/:studyId`}
          exact
          render={() => <ManageRejectVLCRule />}
        />
        <Route
          path={`${match.path}/audit-logs/:dataflowId`}
          exact
          render={() => <AuditLog />}
        />
        <Route
          path={`${match.path}/data-packages`}
          exact
          render={() => <DPSelector />}
        />
        <Route
          path={`${match.path}/dataflow-management/:dataflowId`}
          exact
          render={() => <DataFlow />}
        />
        <Route
          path={`${match.path}/dataflow/create`}
          exact
          render={() => <DataFlow />}
        />
        <Route
          path={`${match.path}/dataflow/clone`}
          exact
          render={() => <DataFlowClone />}
        />
        <Route
          path={`${match.path}/dataset/:datasetId`}
          exact
          render={() => <Dataset />}
        />
        <Route
          path={`${match.path}/ingestion-report/:datasetId`}
          exact
          render={() => <DatasetIngestionReport />}
        />
        <Route
          path={`${match.path}/ingestion-issues/:datasetId`}
          exact
          render={() => <IngestionIssues />}
        />
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </>
  );
};

const WithOutPageHeader = () => {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.path}/cdi`} exact render={() => <CDIAdmin />} />
        <Route path="/cdihome" exact render={() => <CDIhome />} />
        <Route
          path="/cdihome/ingestion-report/:datasetId"
          exact
          render={() => <DatasetIngestionReport />}
        />
        <Route
          path="/cdihome/ingestion-issues/:datasetId"
          exact
          render={() => <IngestionIssues />}
        />
        <Route
          path={`${match.path}/monitor`}
          exact
          render={() => <AllViewMonitorPage />}
        />
      </Switch>
      <AppFooter width="100%" />
    </>
  );
};

const CDIWrapper = () => {
  const [loggedIn, setLoggedIn] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = getUserId(true);
    if (userId) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [history]);

  useEffect(() => {
    const userId = getUserId(true);
    if (userId) {
      history.push(location.pathname);
      if (location.pathname === "/") {
        history.push("/dashboard");
      }
    } else {
      window.location.href = `${process.env.REACT_APP_CORE_URL}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const logoutOnIdle = async () => {
    const isLogout = await userLogOut();
    if (isLogout) {
      setLoggedIn(false);
      history.push("/logout");
    }
  };

  useEffect(() => {
    setIdleLogout(() => {
      logoutOnIdle();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchDataflowConfig());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <Suspense fallback={<Loader isInner />}>
      {loggedIn ? (
        <div className="page-wrapper">
          <TopNavbar setLoggedIn={setLoggedIn} />
          <Switch>
            <Route path="/dashboard" render={() => <WithPageHeader />} />
            <Route path="/cdihome" render={() => <WithOutPageHeader />} />
            <Route path="/admin" render={() => <WithOutPageHeader />} />
            <Route path="*" render={() => <WithPageHeader />} />
          </Switch>
        </div>
      ) : (
        <Switch>
          <Route path="/checkAuthentication" exact render={() => <Empty />} />
          <Route path="/logout" render={() => <Logout />} />
          <Redirect from="/" to="/checkAuthentication" />
        </Switch>
      )}
    </Suspense>
  );
};

export default CDIWrapper;
