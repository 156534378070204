import React from "react";
import { useSelector } from "react-redux";
import { BASIC_DATA_CONNECTOR_DA } from "./DataPackagesHelper";
import SimpleFileAdapter from "./SimpleFileAdapter";
import TabularAdapter from "./DataPackages";
// styles
import "./DataPackages.scss";

const DPSelector = () => {
  const { dataFlowdetail } = useSelector((data) => data.dataFlow);

  return dataFlowdetail?.type === BASIC_DATA_CONNECTOR_DA ? (
    <SimpleFileAdapter />
  ) : (
    <TabularAdapter />
  );
};
export default DPSelector;
