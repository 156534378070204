/* eslint-disable no-lonely-if */
// libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
// apollo components
import BreadcrumbsUI from "apollo-react/components/Breadcrumbs";
import ButtonGroup from "apollo-react/components/ButtonGroup";
import Modal from "apollo-react/components/Modal/Modal";
import Tab from "apollo-react/components/Tab";
import Tabs from "apollo-react/components/Tabs";
import Typography from "apollo-react/components/Typography";
// components
import SaveChangesModal from "./SaveChangesModal";
import DraftButton from "./DraftButton/DraftButton";
// helpers
import {
  Categories,
  Features,
  useStudyPermission,
} from "../Common/usePermission";
// styles
import "./Header.scss";
import { BASIC_DATA_CONNECTOR_DA } from "../../pages/DataPackages/DataPackagesHelper";

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    marginBottom: 16,
    paddingLeft: 0,
  },
  contentIcon: {
    color: "#595959",
  },
  contentSubTitle: {
    color: "#000000",
    fontSize: "14px",
    paddingLeft: 11,
    letterSpacing: 0,
    lineHeight: "24px",
  },
}));

const Breadcrumbs = (props) => {
  const items = props.breadcrumbItems ?? [];
  return (
    <BreadcrumbsUI
      className={props.className}
      id="dataflow-breadcrumb"
      items={items}
    />
  );
};

const Header = (props) => {
  const {
    headerTitle,
    shouldDisplaySaveChangesModal = true,
    shouldTriggerOnRedirect = true,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [publishBtn, setPublishBtn] = useState(false);

  const dashboard = useSelector((state) => state.dashboard);
  const dataFlowData = useSelector((state) => state.dataFlow);
  const { prot_id: protId } = dashboard?.selectedCard;
  const { dataFlowdetail, isPublishedFlow } = dataFlowData;

  const { canUpdate: canUpdateDataFlow } = useStudyPermission(
    Categories.CONFIGURATION,
    Features.DATA_FLOW_CONFIGURATION,
    protId
  );

  useEffect(() => {
    if (
      ["TabularRaveSOD", BASIC_DATA_CONNECTOR_DA, "TabularVeeva"].includes(
        dataFlowdetail?.type
      )
    ) {
      setPublishBtn(+dataFlowdetail?.dataPackageCount > 0);
    } else {
      setPublishBtn(+dataFlowdetail?.dataSetCount > 0);
    }
  }, [dataFlowdetail, dataFlowData]);

  return (
    <>
      <Breadcrumbs
        className={classes.breadcrumbs}
        breadcrumbItems={props.breadcrumbItems}
      />
      <div className="publishGroup">
        {/* Data Flow Title */}
        <Typography className="publish-title">{headerTitle}</Typography>

        {/* Publish Button - DRAFT State */}
        {(!props.tabValue || props.tabValue === 0) &&
          !isPublishedFlow &&
          publishBtn && (
            <ButtonGroup
              alignItems="right"
              buttonProps={[
                {
                  label: "Publish",
                  disabled: !canUpdateDataFlow,
                  variant: "primary",
                  onClick: () => {
                    if (dataFlowdetail?.dataflowid) {
                      history.push(
                        `/dashboard/publish/${dataFlowdetail?.dataflowid}`
                      );
                    }
                  },
                },
              ]}
            />
          )}

        {/* Draft Button - PUBLISH State */}
        {isPublishedFlow && <DraftButton />}
      </div>
      {props.tabs && (
        <Tabs
          value={props.tabValue}
          onChange={props.handleChangeTab}
          size="small"
          style={{ marginBottom: "-19px" }}
          truncate
        >
          {props.tabs.map((tab) => (
            <Tab
              label={tab}
              disabled={
                Object.keys(props.selectedDataset).length <= 0 &&
                tab === "Dataset Columns"
              }
            />
          ))}
        </Tabs>
      )}

      <Modal
        open={openModal}
        variant="warning"
        onClose={() => setOpenModal(false)}
        title="Lose your work?"
        message="All unsaved changes will be lost."
        buttonProps={[
          {
            label: "Keep editing",
            onClick: () => setOpenModal(false),
          },
          {
            variant: "primary",
            label: "Leave without saving",
            onClick: () => {
              props.close();
              setOpenModal(false);
            },
          },
        ]}
        id="success"
      />

      {/* Save Modal */}
      {shouldDisplaySaveChangesModal && (
        <SaveChangesModal shouldTriggerOnRedirect={shouldTriggerOnRedirect} />
      )}
    </>
  );
};

export default Header;
