// libraries
import React from "react";
import { CircularProgress } from "@mui/material";

const ButtonLoader = ({ loading = false, classNameP = "", sizeP = "10px" }) => {
  if (!loading) return null;

  return (
    <>
      {loading && (
        <>
          <CircularProgress className={classNameP} size={sizeP} />
          &nbsp;
        </>
      )}
    </>
  );
};

export default ButtonLoader;
