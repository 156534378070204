/* eslint-disable no-lonely-if */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
// libraries
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// apollo components
import Box from "apollo-react/components/Box";
import Panel from "apollo-react/components/Panel";
import Paper from "apollo-react/components/Paper";
// components
import Header from "../../../components/DataFlow/Header";
import LeftPanel from "../../../components/Dataset/LeftPanel/LeftPanel";
import SimpleFileAdapter from "./SimpleFileAdapter";
import { ReactComponent as DataPackageIcon } from "../../../components/Icons/datapackage.svg";
// helpers
import { BASIC_DATA_CONNECTOR_DA } from "../DataPackagesHelper";
import { getDataFlowBreadCrumb } from "../../../utils";
// styles
import "../DataPackages.scss";

const useStyles = makeStyles(() => ({
  rightPanel: {
    maxWidth: "calc(100vw - 466px)",
    width: "calc(100vw - 464px)",
  },
  rightPanelExtended: {
    maxWidth: "calc(100vw - 42px)",
    width: "calc(100vw - 40px)",
  },
}));

const DataPackages = React.memo(() => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [isPanelOpen, setIsPanelOpen] = useState(true);

  const packageData = useSelector((data) => data.dataPackage);
  const { dataFlowdetail, isDraftEditMode } = useSelector(
    (data) => data.dataFlow
  );
  const { dataflowid: dfId, type: dataAdapter } = dataFlowdetail;

  const goToDataflow = () => {
    if (dfId) {
      history.push(`/dashboard/dataflow-management/${dfId}`);
    } else if (dataFlowdetail?.dataflowid) {
      history.push(
        `/dashboard/dataflow-management/${dataFlowdetail?.dataflowid}`
      );
    }
  };

  const breadCrumbItems = getDataFlowBreadCrumb({
    history,
    location: "DATAPACKAGE",
    dataflowId: null,
    dataFlowName: dataFlowdetail?.name,
    dataPackageName:
      packageData?.selectedPackage?.name ||
      location?.state?.SelectedDataSetProperties?.name,
    goToDataflow,
  });

  const handleClose = () => setIsPanelOpen(false);
  const handleOpen = () => setIsPanelOpen(true);
  const resetForm = () => handleOpen();

  return (
    <div className="data-packages-wrapper">
      <div
        className={
          isDraftEditMode ? "edit-wrapper fade-wrapper" : "edit-wrapper"
        }
      >
        <Panel
          onClose={handleClose}
          onOpen={handleOpen}
          open={isPanelOpen}
          width={446}
        >
          <LeftPanel dataflowSource={dataFlowdetail} resetForm={resetForm} />
        </Panel>
      </div>
      <Panel
        className={
          isPanelOpen ? classes.rightPanel : classes.rightPanelExtended
        }
        width="100%"
        hideButton
      >
        <main className="right-content">
          {/* Header */}
          <Paper className="no-shadow">
            <Box className="top-content">
              <div
                className={
                  isDraftEditMode
                    ? "contentHeader fade-wrapper"
                    : "contentHeader"
                }
              >
                <Header
                  close={() => history.push("/dashboard")}
                  breadcrumbItems={breadCrumbItems}
                  headerTitle={
                    packageData.selectedPackage?.name ||
                    location?.state?.SelectedDataSetProperties?.name ||
                    ((packageData.selectedPackage?.nopackageconfig ||
                      location?.state?.SelectedDataSetProperties
                        ?.nopackageconfig) &&
                      "Data Package") ||
                    "Creating New Package"
                  }
                  icon={<DataPackageIcon className={classes.contentIcon} />}
                  shouldDisplaySaveChangesModal={false}
                />
              </div>
            </Box>
          </Paper>

          <SimpleFileAdapter />
        </main>
      </Panel>
    </div>
  );
});

export default DataPackages;
