// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[class^="MuiTooltip-popper"] {
  z-index: 9999; }

.app-service-down {
  margin: 15px 8px 8px;
  color: #ffffff;
  margin-bottom: 0.35em;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.04; }
`, "",{"version":3,"sources":["webpack://./src/components/AppHeader/NavigationPanel/NavigationPanel.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;;AAGf;EACE,oBAAoB;EACpB,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,iBAAiB,EAAA","sourcesContent":["div[class^=\"MuiTooltip-popper\"] {\n  z-index: 9999;\n}\n\n.app-service-down {\n  margin: 15px 8px 8px;\n  color: #ffffff;\n  margin-bottom: 0.35em;\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 1.04;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
