import React, { createContext, useReducer, useState } from "react";
import { Success, Warning, Info, Err } from "../../constants";

export const MessageContext = createContext();

const MessageProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState({
    variant: Err,
    messages: "",
    show: false,
  });
  const [dataflowObj, setdataflowObj] = useState({
    datasetSubmit: false,
  });
  const [createDfConfig, setCreateDfConfig] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      currentStep: 0,
    }
  );

  const bannerCloseHandle = () => {
    setErrorMessage({ show: false });
  };

  const showErrorMessage = (error) => {
    if (error && error.data) {
      const { message } = error.data;
      setErrorMessage({ variant: Err, messages: message, show: true });
    } else if (typeof error === "string") {
      setErrorMessage({ variant: Err, messages: error, show: true });
    } else {
      setErrorMessage({
        variant: Err,
        messages: "An unknown error occured",
        show: true,
      });
    }
    setTimeout(() => {
      setErrorMessage({ show: false });
    }, 7500);
  };

  const showSuccessMessage = (message) => {
    setErrorMessage({ variant: Success, messages: message, show: true });
    setTimeout(() => {
      setErrorMessage({ show: false });
    }, 5000);
  };

  const showInfoMessage = (message) => {
    setErrorMessage({ variant: Info, messages: message, show: true });
    setTimeout(() => {
      setErrorMessage({ show: false });
    }, 5000);
  };

  const showWarningMessage = (message) => {
    setErrorMessage({ variant: Warning, messages: message, show: true });
    setTimeout(() => {
      setErrorMessage({ show: false });
    }, 5000);
  };
  const setDataflow = (obj) => {
    setdataflowObj({ ...dataflowObj, ...obj });
  };
  const resetDataflow = () => {
    setdataflowObj({ datasetSubmit: false });
  };
  return (
    <MessageContext.Provider
      value={{
        errorMessage,
        bannerCloseHandle,
        showErrorMessage,
        showInfoMessage,
        showWarningMessage,
        showSuccessMessage,
        setDataflow,
        dataflowObj,
        resetDataflow,
        createDfConfig,
        setCreateDfConfig,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;
