/* eslint-disable no-debugger */
/* eslint-disable dot-notation */
import axios from "axios";
import CryptoJS from "crypto-js";

import { getCookie, getUserId } from "../utils/index";

import { baseURL } from "../constants";

const userId = getUserId();
const token = getCookie("user.token");
const encrypt = (key) => {
  if (!key || !process.env.REACT_APP_ENCRYPTION_KEY)
    return "Encryption key not found";
  return CryptoJS.AES.encrypt(
    key,
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
};

axios.defaults.headers.common["api-key"] = encrypt(
  process.env.REACT_APP_API_KEY
);
axios.defaults.headers.common["sys-name"] = process.env.REACT_APP_SYS_NAME;
axios.defaults.headers.common["token-type"] = "user";
axios.defaults.headers.common["access-token"] = encrypt(userId);
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const apiGet = async (url, body = {}) => {
  try {
    const res = await axios.get(`${baseURL}/${url}`, body);
    return res?.data?.data;
  } catch (err) {
    console.log(`>>>> Error fetching ${url}: fetchVendors`, err.response?.data);
    return {
      data: [],
      error: err.response?.data?.message || "Something went wrong",
    };
  }
};

const apiPost = async (url, body = {}, returnRes = false) => {
  try {
    const res = await axios.post(`${baseURL}/${url}`, body);
    if (returnRes) return res;
    return res?.data?.data;
  } catch (err) {
    console.log(`>>>> Error posting ${url} `, err.response?.data);
    return {
      data: [],
      error: err.response?.data?.message || "Something went wrong",
    };
  }
};

export { axios, apiGet, apiPost };
