import { put, call } from "redux-saga/effects";
import axios from "axios";

import {
  baseURL,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  FETCH_VENDOR_SUCCESS,
  FETCH_VENDOR_FAILURE,
  FETCH_SERVICE_OWNERS_SUCCESS,
  FETCH_SERVICE_OWNERS_FAILURE,
  LOCATIONAPI,
  VENDORAPI,
  DATAFLOWAPI,
  FETCH_DATAFLOW_DETAIL_FAILURE,
  FETCH_DATAFLOW_DETAIL_SUCCESS,
  DATAFLOW_UPDATE_API,
  ADD_DATAFLOW_SUCCESS,
  ADD_DATAFLOW_FAILURE,
  DATAFLOW_DRAFT_API,
  SELECTED_DATAFLOW,
  FETCH_DATAFLOW_CONFIG_SUCCESS,
  FETCH_DATAFLOW_CONFIG_API,
  PREVIEW_SCHEDULE_API,
  FETCH_PREVIEW_SCHEDULES_SUCCESS,
  FETCH_PREVIEW_SCHEDULES_FAILURE,
} from "../../constants";
import {
  BASIC_DATA_CONNECTOR_VALUE,
  STANDARDIZE_METADATA_VALUE,
} from "../../pages/Dashboard/MonitorTab/helper";

export function* fetchDataflowConfig() {
  try {
    const data = yield call(
      axios.get,
      `${baseURL}/${FETCH_DATAFLOW_CONFIG_API}`,
      {}
    );

    // check if odm is there in config
    let showODM = false;
    let showBasicDataConnector = false;
    let showStandardizeMetadata = false;
    const dsData = data?.data?.data?.data;
    if (dsData)
      Object?.keys(dsData)?.forEach?.((key) => {
        if (key?.toString?.()?.toLowerCase?.()?.includes("odm")) {
          showODM = true;
        }
        if (key?.toString?.() === BASIC_DATA_CONNECTOR_VALUE) {
          showBasicDataConnector = true;
        }
        if (key?.toString?.() === STANDARDIZE_METADATA_VALUE) {
          showStandardizeMetadata = true;
        }
      });

    yield put({
      type: FETCH_DATAFLOW_CONFIG_SUCCESS,
      config: data.data,
      showODM,
      showBasicDataConnector,
      showStandardizeMetadata,
    });
  } catch (e) {
    yield put({ type: FETCH_VENDOR_FAILURE, message: e.message });
  }
}

export function* addDataFlow(params) {
  try {
    yield call(
      axios.post,
      `${baseURL}/${DATAFLOW_UPDATE_API}`,
      params.dataflow
    );

    yield put({
      type: ADD_DATAFLOW_SUCCESS,
      refreshData: true,
    });
  } catch (e) {
    yield put({ type: ADD_DATAFLOW_FAILURE, message: e.message });
  }
}

export function* fetchVendorsData() {
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${VENDORAPI}/list`,
      {}
    );

    yield put({
      type: FETCH_VENDOR_SUCCESS,
      vendors: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({ type: FETCH_VENDOR_FAILURE, message: e.message });
  }
}

export function* fetchPreviewSchedules(action) {
  try {
    const fetchScheduleData = yield call(
      axios.post,
      `${baseURL}/${PREVIEW_SCHEDULE_API}`,
      {
        ...action.payload,
      }
    );

    yield put({
      type: FETCH_PREVIEW_SCHEDULES_SUCCESS,
      fetchedSchedules: fetchScheduleData.data.data,
    });
  } catch (e) {
    yield put({
      type: FETCH_PREVIEW_SCHEDULES_FAILURE,
      message: e.response?.data?.message || e.message,
    });
  }
}

export function* fetchLocationsData(action = null) {
  let param = "";
  if (action.value) {
    param = `type=${action.value}`;
  }
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${LOCATIONAPI}/list-ui?${param}`,
      {}
    );

    yield put({
      type: FETCH_LOCATION_SUCCESS,
      locations: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({ type: FETCH_LOCATION_FAILURE, message: e.message });
  }
}

export function* fetchServiceOwnersData() {
  try {
    const fetchSBData = yield call(
      axios.get,
      `${baseURL}/${LOCATIONAPI}/service_owners`,
      {}
    );
    yield put({
      type: FETCH_SERVICE_OWNERS_SUCCESS,
      serviceOwners: fetchSBData.data.data,
    });
  } catch (e) {
    yield put({ type: FETCH_SERVICE_OWNERS_FAILURE, message: e.message });
  }
}

export function* fetchDataflowDetail(action) {
  try {
    let fetchURL = `${baseURL}/${DATAFLOWAPI}/detail/${action.dataflowId}`;
    if (!action.isPublishedFlow) {
      fetchURL = `${baseURL}/${DATAFLOW_DRAFT_API}/detail/${action.dataflowId}`;
    }
    const fetchSBData = yield call(axios.get, fetchURL, {});
    yield put({
      type: FETCH_DATAFLOW_DETAIL_SUCCESS,
      dataflowDetail: fetchSBData.data.data,
    });
    if (action.fromCreate)
      yield put({
        type: SELECTED_DATAFLOW,
        dataflow: fetchSBData.data.data,
      });
  } catch (e) {
    const errText = e.response?.data?.message
      ? e.response.data.message
      : e.message;
    yield put({ type: FETCH_DATAFLOW_DETAIL_FAILURE, message: errText });
  }
}
