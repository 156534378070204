/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import MenuItem from "apollo-react/components/MenuItem";
import Radio from "apollo-react/components/Radio";
import RadioGroup from "apollo-react/components/RadioGroup";
import Select from "apollo-react/components/Select";
import TextField from "apollo-react/components/TextField";
import Autocomplete from "apollo-react/components/Autocomplete";
import Button from "apollo-react/components/Button";
import Typography from "apollo-react/components/Typography";
import Checkbox from "apollo-react/components/Checkbox";
import PasswordInput from "apollo-react/components/PasswordInput";
import Switch from "apollo-react/components/Switch";
import Pencil from "apollo-react-icons/Pencil";
import { CircularProgress } from "@mui/material";

import { TooltipLabel } from "./CommonReduxComponents";
import ButtonLoader from "../Common/ButtonLoader";
import { stringToBoolean } from "../../utils";

export const CDITextBox = ({
  editMode,
  label,
  value,
  onChange,
  disabled = false,
  tooltip = "",
  name,
  maxLength = 255,
  required = false,
  visible = true,
  renderText = null,
  errors,
  footer,
  ...rest
}) => {
  try {
    if (!visible) return null;

    if (editMode)
      return (
        <>
          <TextField
            fullWidth
            error={!!errors[name]}
            helperText={errors[name]}
            maxLength={maxLength}
            name={name}
            inputProps={{ maxLength }}
            onChange={onChange}
            disabled={disabled}
            required={required}
            value={value}
            label={
              tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
            }
            {...rest}
          />
          {footer && (
            <span
              className="footer-message-common-component"
              // style={{ marginTop: "-18px" }}
            >
              {footer}
            </span>
          )}
        </>
      );

    return (
      <>
        <div className="df-form-group">
          <p className="df-form-label">{label}</p>
          <p className="df-form-value">
            {renderText ? renderText(value) : value}
          </p>
        </div>
      </>
    );
  } catch (error) {
    console.log(
      `Error occurred in TextBox name: ${name} value: ${value}`,
      error
    );
    return null;
  }
};

export const CDIDropDown = ({
  name,
  label,
  required = false,
  editMode,
  options,
  value,
  disabled,
  onChange,
  tooltip = "",
  visible = true,
  renderText = null,
  errors,
  isLoading = false,
  footer,
  ...rest
}) => {
  try {
    if (!visible) return null;

    const listitems = options && options.length ? options : [];

    if (editMode)
      return (
        <>
          <Select
            key={`${name}-${value}`}
            name={name}
            label={
              tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
            }
            error={!!errors[name]}
            helperText={errors[name]}
            fullWidth
            canDeselect={false}
            defaultValue={value || ""}
            value={value || ""}
            required={required}
            onChange={onChange}
            disabled={disabled || isLoading}
            {...rest}
          >
            {listitems.map(({ text, value: id }) => (
              <MenuItem
                key={id}
                value={id}
                selected={value === id ? true : false}
              >
                {text}
              </MenuItem>
            ))}
          </Select>
          {footer && (
            <span className="footer-message-common-component">{footer}</span>
          )}
          {isLoading && <CircularProgress size="10px" />}
        </>
      );

    const viewText = () => {
      if (renderText && typeof renderText === "function")
        return renderText(value);

      const item = listitems.find((x) => x.value === value);
      if (item && item.text) return item.text;

      return "";
    };
    return (
      <div className="df-form-group">
        <p className="df-form-label">{label}</p>
        <p className="df-form-value">{viewText()}</p>
      </div>
    );
  } catch (error) {
    console.log(
      `Error occured in DropDown name: ${name} value: ${value} options: ${options}`,
      error
    );
    return null;
  }
};

export const CDIAutoComplete = ({
  name,
  label,
  editMode,
  options,
  value,
  disabled,
  onChange,
  tooltip = "",
  visible = true,
  required = false,
  renderText,
  errors,
  footer,
  ...rest
}) => {
  const [item, setItem] = useState(null);
  const [source, setSource] = useState([]);

  useEffect(() => {
    if (Array.isArray(options))
      setSource(options?.map((x) => ({ label: x.text, value: x.value })));
    else setSource([]);
  }, [options]);

  useEffect(() => {
    const val =
      typeof value === "string"
        ? source?.find((x) => x.value === value) || []
        : value || [];
    setItem(val);
  }, [source, value]);

  if (!visible) return null;

  try {
    if (editMode)
      return (
        <>
          <Autocomplete
            value={item}
            onChange={(event, v) => onChange(v)}
            key={value}
            name={name}
            autoSelect
            error={!!errors[name]}
            helperText={errors[name]}
            required={required}
            blurOnSelect={true}
            clearOnBlur={true}
            onBlur={() => console.log(">>> onblur")}
            label={
              tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
            }
            source={source}
            id={label}
            matchFrom="any"
            disabled={disabled}
            enableVirtualization
            className="autocomplete_field"
            singleSelect
            disableClearable={true}
            variant="search"
            fullWidth
            viewMode={true}
            {...rest}
          />
          {footer && (
            <span
              className="footer-message-common-component"
              // style={{ marginTop: "-18px", whiteSpace: "pre-wrap" }}
            >
              {footer}
            </span>
          )}
        </>
      );

    return (
      <div className="df-form-group">
        <p className="df-form-label">{label}</p>
        <p className="df-form-value">
          {renderText ? renderText(value) : item?.label}
        </p>
      </div>
    );
  } catch (error) {
    console.log(
      `Error occured in AutoComplete name: ${name} value: ${value} options: ${options}`,
      error
    );
    return null;
  }
};

export const CDIRadioGroup = ({
  name,
  label,
  editMode,
  options,
  value,
  disabled,
  onChange,
  tooltip = "",
  visible = true,
  required = false,
  renderText = undefined,
  errors,
  footer,
  ...rest
}) => {
  try {
    if (!visible) return null;

    if (editMode)
      return (
        <>
          <RadioGroup
            name={name}
            error={!!errors[name]}
            helperText={errors[name]}
            label={
              tooltip ? (
                <TooltipLabel label={label} tooltip={tooltip} />
              ) : (
                <p className="radio-group-label">{label}</p>
              )
            }
            onChange={onChange}
            disabled={disabled}
            required={required}
            className="radio-group-options"
            value={value}
            // style={{ marginBottom: "24px" }}
            {...rest}
          >
            {(options || [])?.map((x) => (
              <Radio value={x.value} label={x.text} />
            ))}
          </RadioGroup>
          {footer && (
            <label className="footer-message-common-component">{footer}</label>
          )}
        </>
      );

    return (
      <div className="df-form-group">
        <p className="df-form-label">{label}</p>
        <p className="df-form-value">
          {renderText
            ? renderText(value)
            : (options || [])?.find((x) => x.value === value)?.text}
        </p>
      </div>
    );
  } catch (error) {
    console.log(
      `Error occured in RadioGroup name: ${name} value: ${value} options: ${options}`,
      error
    );
    return null;
  }
};

export const EditSaveCancelButtonGroup = ({
  isEditMode = true,
  isLoading,
  disabled = false,
  saveCaption = "Save",
  cancelCaption = "Cancel",
  editCaption = "Edit",
  onSave,
  onCancel,
  onEdit,
  visible = true,
}) => (
  <>
    {visible ? (
      <>
        <div className="save-btn">
          {isEditMode ? (
            <>
              <Button
                variant="text"
                disabled={disabled || isLoading}
                style={{ marginRight: 10 }}
                onClick={onCancel}
              >
                {cancelCaption}
              </Button>
              <Button
                variant="primary"
                style={{ marginRight: 10 }}
                disabled={disabled || isLoading}
                onClick={onSave}
              >
                <ButtonLoader loading={isLoading} classNameP="modal-loader" />
                {saveCaption}
              </Button>
            </>
          ) : (
            <Button
              variant="secondary"
              disabled={disabled || isLoading}
              icon={Pencil}
              style={{ marginRight: 10 }}
              onClick={onEdit}
            >
              {editCaption}
            </Button>
          )}
        </div>
      </>
    ) : null}
  </>
);

export const Header = ({ text }) => (
  <div className="data-setting-header">
    <Typography className="section-title">{text}</Typography>
  </div>
);

export const CDICheckBox = ({
  visible,
  editMode,
  label,
  tooltip,
  disabled,
  value,
  ...rest
}) =>
  visible ? (
    <Checkbox
      checked={value}
      style={{ alignItems: "baseline" }}
      disabled={!editMode || disabled}
      fullWidth
      label={tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label}
      {...rest}
    />
  ) : null;

export const CDIPassword = ({
  editMode,
  label,
  value,
  onChange,
  disabled = false,
  tooltip = "",
  name,
  maxLength = 255,
  required = false,
  visible = true,
  renderText = null,
  errors,
  ...rest
}) => {
  try {
    if (!visible) return null;
    const hiddenPassword = value ? "*".repeat(value?.length) : "";

    if (editMode)
      return (
        <PasswordInput
          fullWidth
          maxLength={maxLength}
          name={name}
          inputProps={{ maxLength }}
          onChange={onChange}
          error={!!errors[name]}
          helperText={errors[name]}
          disabled={disabled}
          required={required}
          value={value}
          label={
            tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label
          }
          {...rest}
        />
      );

    return (
      <>
        <div className="df-form-group">
          <p className="df-form-label">{label}</p>
          <p className="df-form-value">
            {renderText ? renderText(hiddenPassword) : hiddenPassword}
          </p>
        </div>
      </>
    );
  } catch (error) {
    console.log(
      `Error occured in TextBox name: ${name} value: ${value}`,
      error
    );
    return null;
  }
};

export const CDIButtonToggle = ({ trueLabel, falseLabel, value, ...rest }) => (
  <Button value={value} {...rest}>
    {value ? falseLabel : trueLabel}
  </Button>
);

export const CDISwitch = ({ visible = true, onChange, value, ...rest }) => {
  const isChecked = typeof value !== "boolean" ? stringToBoolean(value) : value;

  const handleOnChange = (e) => {
    onChange(
      !(typeof e.target.value !== "boolean"
        ? stringToBoolean(e.target.value)
        : e.target.value)
    );
  };

  return visible ? (
    <Switch
      checked={isChecked}
      value={value}
      onChange={handleOnChange}
      {...rest}
    />
  ) : null;
};

export default {
  CDITextBox,
  CDIRadioGroup,
  CDIAutoComplete,
  CDIDropDown,
  CDICheckBox,
  SaveCancelButtonGroup: EditSaveCancelButtonGroup,
  Header,
  CDIButtonToggle,
  CDISwitch,
};
