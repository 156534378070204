import { takeEvery, takeLatest } from "redux-saga/effects";
import {
  PACKAGES_LIST,
  UPDATE_DATA_PACKAGE,
  GET_VENDORS_DATA,
  SAVE_LOCATION_DATA,
  GET_DATA_KIND,
  GET_LOCATIONS_DATA,
  GET_SERVICE_OWNERS,
  AUDIT_LOGS,
  SAVE_DATASET_DATA,
  SAVE_DATASET_COLUMNS,
  GET_DATA_FLOW_LIST,
  GET_STUDIES_LIST,
  GET_PINNED_LIST,
  GET_DATAFLOW_DETAIL,
  GET_DATASET_DETAIL,
  UPDATE_DATASET_DATA,
  UPDATE_COLUMNS_DATA,
  GET_DATASET_COLUMNS,
  ADD_DATAFLOW,
  GET_VLC_RULES,
  GET_DATASET_INGESTION_SUMMARY,
  GET_ALL_INGESTION_SUMMARY,
  GET_SPECIFIC_INGESTION_DATASETS,
  GET_LOCATIONS_ADMIN,
  GET_CDT_LIST,
  UPDATE_LOCATION_DATA,
  GET_PREVIEW_SQL,
  GET_SQL_TABLES,
  GET_SQL_COLUMNS,
  CREARE_SETTINGS_DATA,
  UPDATE_SETTINGS_DATA,
  FETCH_SETTINGS_DATA,
  GET_TRANSFER_LOG,
  GET_DATASET_PROPERTIES,
  GET_DATASET_INGESTION_ISSUE_TYPES,
  GET_DATASET_INGESTION_FILE_HISTORY,
  GET_DATASET_INGESTION_TRANSFER_LOG,
  GET_LOCATION_DETAIL,
  GET_PASSWORD_LOCATION,
  GET_PASSWORD_PACKAGE,
  GET_PUBLISH_DATAFLOW_DETAILS,
  GET_LOCATION_DETAILS,
  PUBLISH_DRAFT_DATAFLOW,
  DF_VERSION,
  GET_DATA_FLOW_LIST_INTERVAL,
  FETCH_DATAFLOW_CONFIG,
  GET_REJECT_VLC_DATA,
} from "../../constants";

import {
  fetchPackagesData,
  updateDataPackage,
  fetchPackagepassword,
} from "./dataPackage.saga";

import {
  fetchFlowData,
  fetchDatasetIngestionSummaryData,
  fetchAllIngestionSummaryData,
  fetchSpecificIngestionDataSets,
  fetchUserStudiesData,
  fetchPinnedStudies,
  fetchFlowDataInterval,
} from "./dashboard.saga";
import {
  fetchDataKindData,
  saveDataset,
  saveDatasetColumns,
  fetchDatasetDetail,
  updateDataset,
  fetchDatasetColumns,
  fetchSQLTables,
  fetchSQLColumns,
  fetchPreviewSQL,
  fetchVLCData,
  updateDatasetColumns,
  getLocationDetails,
} from "./dataSets.saga";

import {
  fetchVendorsData,
  fetchLocationsData,
  fetchServiceOwnersData,
  fetchDataflowDetail,
  addDataFlow,
  fetchDataflowConfig,
} from "./dataFlow.saga";

import { fetchAuditLogs } from "./auditLogs.saga";

import {
  fetchCDTList,
  updateLocationData,
  saveLocationData,
  createSettingsData,
  updateSettingsData,
  fetchSettingsList,
  fetchLocationspassword,
} from "./cdiAdmin.saga";
import {
  fetchDatasetIngestionFileHistory,
  fetchDatasetIngestionTransferLog,
  fetchDatasetIngestionIssueTypes,
  fetchProperties,
  fetchTransferLog,
} from "./IngestionReport.saga";

import {
  fetchPublishDFDetails,
  fetchPublishDFVersions,
  getLocationDetailsPublish,
  publishDataflowFromDraft,
} from "./publishDF.saga";

import { fetchRejectRules } from "./manageVLC.saga";

function* cdasCoreSaga() {
  yield takeEvery(GET_DATA_FLOW_LIST, fetchFlowData);
  yield takeEvery(GET_DATA_FLOW_LIST_INTERVAL, fetchFlowDataInterval);
  yield takeEvery(GET_STUDIES_LIST, fetchUserStudiesData);
  yield takeEvery(GET_PINNED_LIST, fetchPinnedStudies);
  yield takeLatest(
    GET_DATASET_INGESTION_SUMMARY,
    fetchDatasetIngestionSummaryData
  );
  yield takeLatest(GET_ALL_INGESTION_SUMMARY, fetchAllIngestionSummaryData);
  yield takeLatest(
    GET_SPECIFIC_INGESTION_DATASETS,
    fetchSpecificIngestionDataSets
  );
  yield takeLatest(FETCH_DATAFLOW_CONFIG, fetchDataflowConfig);
  yield takeLatest(ADD_DATAFLOW, addDataFlow);
  yield takeLatest(PACKAGES_LIST, fetchPackagesData);
  yield takeLatest(UPDATE_DATA_PACKAGE, updateDataPackage);
  yield takeLatest(GET_PASSWORD_PACKAGE, fetchPackagepassword);
  yield takeLatest(GET_VENDORS_DATA, fetchVendorsData);
  yield takeLatest(GET_LOCATIONS_DATA, fetchLocationsData);
  yield takeLatest(SAVE_LOCATION_DATA, saveLocationData);
  yield takeLatest(GET_SERVICE_OWNERS, fetchServiceOwnersData);
  yield takeLatest(GET_PASSWORD_LOCATION, fetchLocationspassword);
  yield takeLatest(AUDIT_LOGS, fetchAuditLogs);
  yield takeLatest(GET_DATA_KIND, fetchDataKindData);
  yield takeLatest(SAVE_DATASET_DATA, saveDataset);
  yield takeLatest(SAVE_DATASET_COLUMNS, saveDatasetColumns);
  yield takeLatest(GET_DATAFLOW_DETAIL, fetchDataflowDetail);
  yield takeLatest(GET_DATASET_DETAIL, fetchDatasetDetail);
  yield takeLatest(UPDATE_DATASET_DATA, updateDataset);
  yield takeLatest(UPDATE_COLUMNS_DATA, updateDatasetColumns);
  yield takeLatest(GET_DATASET_COLUMNS, fetchDatasetColumns);
  yield takeLatest(GET_VLC_RULES, fetchVLCData);
  yield takeLatest(GET_LOCATIONS_ADMIN, fetchLocationsData);
  yield takeLatest(GET_CDT_LIST, fetchCDTList);
  yield takeLatest(UPDATE_LOCATION_DATA, updateLocationData);
  yield takeLatest(GET_PREVIEW_SQL, fetchPreviewSQL);
  yield takeLatest(GET_SQL_TABLES, fetchSQLTables);
  yield takeLatest(GET_SQL_COLUMNS, fetchSQLColumns);
  yield takeLatest(FETCH_SETTINGS_DATA, fetchSettingsList);
  yield takeLatest(CREARE_SETTINGS_DATA, createSettingsData);
  yield takeLatest(UPDATE_SETTINGS_DATA, updateSettingsData);
  yield takeLatest(GET_TRANSFER_LOG, fetchTransferLog);
  yield takeLatest(GET_DATASET_PROPERTIES, fetchProperties);
  yield takeLatest(GET_LOCATION_DETAIL, getLocationDetails);
  yield takeLatest(
    GET_DATASET_INGESTION_ISSUE_TYPES,
    fetchDatasetIngestionIssueTypes
  );
  yield takeLatest(
    GET_DATASET_INGESTION_FILE_HISTORY,
    fetchDatasetIngestionFileHistory
  );
  yield takeLatest(
    GET_DATASET_INGESTION_TRANSFER_LOG,
    fetchDatasetIngestionTransferLog
  );
  yield takeLatest(GET_PUBLISH_DATAFLOW_DETAILS, fetchPublishDFDetails);
  yield takeLatest(GET_LOCATION_DETAILS, getLocationDetailsPublish);
  yield takeLatest(PUBLISH_DRAFT_DATAFLOW, publishDataflowFromDraft);
  yield takeLatest(DF_VERSION, fetchPublishDFVersions);
  yield takeLatest(GET_REJECT_VLC_DATA, fetchRejectRules);
}

export default cdasCoreSaga;
