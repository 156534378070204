// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.critical-box {
  display: flex;
  flex-direction: column;
  padding: 1px;
  border-radius: 6px;
  border: 2px solid #e20000;
  background: #e20000;
  margin: 0;
  margin-bottom: -2px;
  max-width: 240px;
  width: 115%; }
  .critical-box .critical-text {
    width: 100%;
    margin-left: 12px; }
  .critical-box .critical-text p {
    margin: 0;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; }
  .critical-box .critical-card {
    background: rgba(226, 0, 0, 0.08); }
  .critical-box .critical-paper {
    background-color: white;
    border-radius: 6px;
    height: 175px;
    width: 100%; }
    .critical-box .critical-paper button {
      width: 56px;
      margin: 0 auto; }

.info-box {
  display: block; }
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/MonitorTab/InfoCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,gBAAgB;EAChB,WAAW,EAAA;EAVb;IAaI,WAAW;IACX,iBAAiB,EAAA;EAdrB;IAkBI,SAAS;IACT,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAA;EAtBrB;IA0BI,iCAAiC,EAAA;EA1BrC;IA8BI,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,WAAW,EAAA;IAjCf;MAoCM,WAAW;MACX,cAAc,EAAA;;AAKpB;EACE,cAAc,EAAA","sourcesContent":[".critical-box {\n  display: flex;\n  flex-direction: column;\n  padding: 1px;\n  border-radius: 6px;\n  border: 2px solid #e20000;\n  background: #e20000;\n  margin: 0;\n  margin-bottom: -2px;\n  max-width: 240px;\n  width: 115%;\n\n  .critical-text {\n    width: 100%;\n    margin-left: 12px;\n  }\n\n  .critical-text p {\n    margin: 0;\n    padding-bottom: 2px;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n  }\n\n  .critical-card {\n    background: rgba(226, 0, 0, 0.08);\n  }\n\n  .critical-paper {\n    background-color: white;\n    border-radius: 6px;\n    height: 175px;\n    width: 100%;\n\n    button {\n      width: 56px;\n      margin: 0 auto;\n    }\n  }\n}\n\n.info-box {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
